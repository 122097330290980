import React, { Component } from 'react'

import Select from 'react-select'
import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'

export default class StepAddedMusicianToConcert extends Component {
  renderMusicianList (mu, key, removeMusicianFromConcert) {
    return <div className="range" key={key}>
      <div className="cell-md-3">
        <span className="text-bold small">
          {mu.id}
        </span>
      </div>
      <div className="cell-md-7">
        <span className="text-bold small">
          {mu.name}
        </span>
      </div>
      <div className="cell-md-2">

        <a onClick={removeMusicianFromConcert} id={key} className="btn btn-info animateItem btn-xs" data-animation="fadeInUp" data-delay="0.2" data-duration="1">- участник</a>

      </div>
    </div>
  }

  render () {
    const style = {
      marginLeft: '10px'
    }
    const bg = {

      color: '#191919'
    }
    return (
      <ConstructorProviderConsumer>
        {({ musicianConcertList, onChangeMusician, m, addMusicianToConcert, selectedOption, removeMusicianFromConcert }) => (
          <div className="range">
            <div className="cell-md-12">
              <div className="range">
                <div className="cell-md-3">
                  <h6 className="post-event-time">
                                        Артист
                  </h6>
                </div>
                <div className="cell-md-7 text-bold small" style={bg}>
                  <Select
                    value={selectedOption}
                    onChange={onChangeMusician}
                    options={m}
                  />
                </div>
                <div className="cell-md-2">

                  <a onClick={addMusicianToConcert} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">+ участник</a>

                </div>
              </div>
            </div>
            {/* <!-- Список добавленых артистов --> */}
            {musicianConcertList &&
                            musicianConcertList.length > 0
              ? musicianConcertList.map((mu, key) =>
                this.renderMusicianList(mu, key, removeMusicianFromConcert)

              ) : ''}

          </div>
        )}
      </ConstructorProviderConsumer>
    )
  }
}
