import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import MainPage from './site/MainPage'
import Login from './common/Login'

import AdminMenu from './admin/controls/AdminMenu'
import Voting2 from './voting/Voting2'
import Dashboard from './dashboard/Dashboard'
import NoMatch from './nomatch/NoMatch'
import Gallery from './site/Gallery'
import NoDate from './site/muse/NoDate'
import GenVote from './voting/GenVote'
import IFrame from './outside/IFrame'
import Register from './common/Register'
import Concert from './admin/screens/Concert'
import Muse from './admin/screens/Muse'
import MuseDetail from './admin/screens/MuseDetail'
import AdminSettings from './admin/screens/AdminSettings'
import Roles from './admin/screens/Roles'
import RegmusicForm from './common/component/RegmusicForm'
import PersData from './common/component/PersData'
import ListVote from './admin/screens/ListVote'
import BBT from './bbt/BBT'
import StreamMainPanel from './stream/StreamMainPanel'
import YaDiscPlayer from './yandex/YaDiscPlayer'
import Converter from './converter/converter'
export default class Main extends Component {
  render () {
    return (

      <Switch>
        <Route exact path="/" render={props => (
          <MainPage header="site" />

        )} />
        <Route path="/stream" component={StreamMainPanel} />
        <Route path="/ydiskplayer" component={YaDiscPlayer} />
        <Route path="/bbt" component={BBT} />
        <Route path="/converter" component={Converter}/>
        <Route path="/adminmusicians" component={Muse} />
        <Route path="/adminconcert" component={Concert} />
        <Route path="/login" component={Login} />
        <Route path="/voting" render={(props) => <Voting2 {...props} />} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/genvote" component={GenVote} />
        <Route path="/admin" component={AdminMenu} />
        <Route path="/nodate" component={NoDate} />
        <Route path="/iframe" component={IFrame} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/mus" component={MuseDetail} />
        <Route exact path="/adminsettings" component={AdminSettings} />
        <Route exact path="/regmusic" component={RegmusicForm} />
        <Route exact path="/persdata" component={PersData} />
        <Route exact path="/listvote" component={ListVote} />
        <Route exact path="/roles" component={Roles} />
        <Route component={NoMatch} />
        {/* <Route path="/admin/concert" component={Concert} />  */}

        {/* <Route path="/routematrix" component={YMapMatrix} />
            <Route path="/userroutematrix" component={YMapMatrix} /> */}
        {/* <Route path="/usermatrix" component={UserMatrix} /> */}
        {/* <Route  exact path="/"  render={props => (
            <MainPanel store={this.props.store}/>

            )} />
            <Route path="/matrix" component={Matrix} /> */}

      </Switch>

    )
  }
}
