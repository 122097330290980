import React, { Component } from 'react'

import { RegMusician } from '../../state/RegMusician'
import DialogModal from '../modalform/DialogModal'

export default class RegmusicForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      media: '',
      aboutus: '',
      authorsdescription: '',

      dialogmodal: '',
      openDialogModal: false
    }
  }

  onNameGroupe (e) {
    this.setState({
      name: e.target.value
    })
  }

  onEmailGroup (e) {
    this.setState({
      email: e.target.value
    })
  }

  onMedia (e) {
    this.setState({
      media: e.target.value
    })
  }

  onAboutUs (e) {
    this.setState({
      aboutus: e.target.value
    })
  }

  onAboutGroup (e) {
    this.setState({
      authorsdescription: e.target.value
    })
  }

  onPhoneGroupe (e) {
    this.setState({
      phone: e.target.value
    })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps !== prevState) { return nextProps } else { return null }
  }

  onSendApplication (e) {
    e.preventDefault()
    var form = window.$('.rd-mailform1')
    form.addClass('form-in-process')
    // var form = window.$(rdMailForm[0]),
    var inputs = form.find('[data-constraints]')
    var output = window.$('#' + form.attr('data-form-output'))
    var captcha = form.find('.recaptcha')

    output.removeClass('active error success')

    if (window.isValidated(inputs, captcha)) {
      var self = this
      var post = RegMusician(this.state)
      post.done(function (resp) {
        if (output.hasClass('snackbars')) {
          form.clearForm()

          self.setState({
            // openDialog:false,
            name: '',
            email: '',
            phone: '',
            media: '',
            aboutus: '',
            authorsdescription: '',
            dialogmodal: resp.name +
              ', Вы зарегистрированы.' + '\n' + 'На адрес: ' + resp.email + ' выслано письмо.' + '\n' +
              'Проверьте, в том числе, папку Спам',
            openDialogModal: true
          })
          output.html('<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>' +
            resp.name +
            ', Поздравляем Вы зарегистрированы' + '</span></p>')

          output.addClass('active')
          setTimeout(function () {
            output.removeClass('active')
          }, 3500)
          form.removeClass('form-in-process')
          // self.props.closeDialog()
        }
      })
    } else {
      form.removeClass('form-in-process')
      if (output.hasClass('snackbars')) {
        output.html('<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>Ошибка!!!!</span></p>')
        output.addClass('active')
        setTimeout(function () {
          output.removeClass('active')
        }, 3500)
      }
      return false
    }
  }

  onCloseDialog (e) {
    this.setState({
      openDialogModal: false,
      openDialog: false
    })
  }

  onClose (e) {
    this.setState({ openDialog: false })
  }

  render () {
    var modaldialog = {
      width: '100%',
      color: 'black'
    }

    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">

        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <h4>Стать участником
                {/* <button className="btn btn-primary btn-xs" onClick={this.onSendApplication.bind(this)} >Отправить</button> */}
              </h4>
            </div>
            <div className="range range-xs-center">
              {/* form */}
              <div className="modal-dialog modal-dialog_custom" style={modaldialog}>

                <div className="modal-dialog__inner">
                  {/* <button className="close" type="button" data-dismiss="modal" onClick={this.onClose.bind(this)}></button> */}

                  <div className="modal-dialog__content" >
                    <form className="rd-mailform1 text-left"
                      data-form-output="form-output-global"
                      data-form-type="order"
                      onSubmit={this.onSendApplication.bind(this)} method="post">

                      <div className="range">
                        {/* <h4>Стать участником <button className="btn btn-primary btn-xs" >Отправить</button></h4> */}
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-namegroup">Название группы:</label>
                              <input className="form-control"
                                onChange={this.onNameGroupe.bind(this)} value={this.state.namegroup}
                                id="contact-us-namegroup" type="text" name="namegroup" data-constraints="@Required" />
                            </div>
                          </div>
                        </div>
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-emailgroup">E-Mail:</label>
                              <input className="form-control"
                                onChange={this.onEmailGroup.bind(this)} value={this.state.emailgroup}
                                id="contact-us-emailgroup" type="email" name="emailgroup" data-constraints="@Required @Email" />
                            </div>
                          </div>
                        </div>

                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12 ">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-phone">Телефон</label>
                              <input className="form-control"
                                onChange={this.onPhoneGroupe.bind(this)} value={this.state.phonegroupe}
                                id="contact-us-phone" type="PhoneNumber" name="phonegroupe" data-constraints="@Required @PhoneNumber" />
                            </div>
                          </div>
                        </div>
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-media">Ссылка на медиа материалы:</label>
                              <input className="form-control"
                                onChange={this.onMedia.bind(this)} value={this.state.media}
                                id="contact-us-media" type="text" name="media" data-constraints="@Required" />
                            </div>
                          </div>
                        </div>
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12 ">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-aboutus">Откуда вы о нас узнали:</label>
                              <input className="form-control"
                                onChange={this.onAboutUs.bind(this)} value={this.state.aboutus}
                                id="contact-us-aboutus" type="text" name="aboutus" data-constraints="@Required" />
                            </div>
                          </div>
                        </div>
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contact-us-aboutgroup">Расскажите о группе и ссылка на соцсети:</label>
                              <textarea className="form-control"
                                onChange={this.onAboutGroup.bind(this)} value={this.state.aboutgroup}
                                id="contact-us-aboutgroup" name="aboutgroup" data-constraints="@Required"></textarea>
                            </div>
                          </div>
                        </div>

                        {/* <div className="range">
                        <div className="cell-lg-12 cell-md-12 cell-xs-12 ">
                        <div className="form-group">
                        <div className="recaptcha" id="idcaptcha" data-sitekey="6LeKhXkUAAAAAEZZnZLVm3cy81u9YMqHYkHL2pm0"></div>
                        </div>
                        </div>
                    </div> */}
                        <div className="range">
                          <div className="cell-lg-12 cell-md-12 cell-xs-12 ">
                            <div className="form-group">
                              <h4 className="text-extra-small" >Нажимая кнопку «Отправить»,
                        вы даете <a href="/persdata" >Согласие на обработку своих персональных данных</a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="text-center button-container">
                          <button className="btn btn-primary btn-xs" >Отправить</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/*  */}
            </div>
          </div>
        </div>

        <DialogModal text={''}
          textresult={this.state.dialogmodal}
          openDialog={this.state.openDialogModal}
          onClose={this.onCloseDialog.bind(this)}
          icon="icon text-middle fa fa-envelope fa-spin icon-xxs"
        />
      </section>
    )
  }
}
