import React, { Component } from 'react'
import { withLogin } from '../../provider/LoginProvider'
import PropTypes from 'prop-types'
import Admin2 from './Admin2'
class Muse extends Component {
  render () {
    return (

      <main className="page-content">
        {this.props.user != null && this.props.user.role === 'admin'
          ? <section className="section parallax-container" data-parallax-img="images/city-1920-1276.png">
            <div className="parallax-content">
              <div className="section-85 section-lg-124 shell context-dark">
                {this.props.menuPanel()}
                <Admin2 />
              </div>
            </div>
          </section>
          : this.props.noAccessBlock()
        }</main>

    )
  }
}

export default withLogin(Muse)
Muse.propTypes = {
  noAccessBlock: PropTypes.func,
  user: PropTypes.any,
  menuPanel: PropTypes.any
}
