/* eslint-disable react/prop-types */
import React from 'react'

const langcodes = {
  russian: 'ru_RU',
  english: 'en_US'
}
const classes = {
  formControl: {
    margin: '1px',
    minWidth: 120,
    zIndex: 1000
  },
  selectEmpty: {
    marginTop: '2px'
  }
}

export const ITLanguageContext = React.createContext({
  language: 'russian',
  langcode: 'ru_RU',
  updateLanguage: () => {},
  classes: classes,
  anchorEl: null,
  handleClick: () => {}
})

export class ITLanguageProvider extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      language: 'russian',
      langcode: 'ru_RU',
      classes: classes,
      status: 'start',
      anchorEl: null

    }
  }

  handleClick = e => {
    console.log(e.currentTarget)
    this.setState({
      anchorEl: e.currentTarget
    })
  }

    updateLanguage = e => {
      console.log('updateLanguage')
      console.log(e)
      console.log('<<----updateLanguage')
      this.setState({
        language: e,
        langcode: langcodes[e],
        status: 'start',
        classes: classes,
        anchorEl: null
      })
    }

    render () {
      return (
        <ITLanguageContext.Provider
          value={{
            language: this.state.language,
            updateLanguage: this.updateLanguage,
            langcode: this.state.langcode,
            classes: this.state.classes,
            anchorEl: this.state.anchorEl,
            handleClick: this.handleClick
          }}
        >
          { this.props.children}
        </ITLanguageContext.Provider>
      )
    }
}

export const ITLanguageConsumer = ITLanguageContext.Consumer

export function withLanguage (Component) {
  return function LanguageComponent (props) {
    return (
      <ITLanguageContext.Consumer>
        {({ language, updateLanguage, langcode }) =>
          <Component {...props} language={language} updateLanguage={updateLanguage} langcode={langcode} />}
      </ITLanguageContext.Consumer>
    )
  }
}
