
import React, { Component } from 'react'

import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'
export default class StepAddedPointToConcert extends Component {
  render () {
    const style = {
      marginLeft: '10px'
    }
    const bg = {

      color: '#191919'
    }
    return (
      <ConstructorProviderConsumer>
        {({
          pointsConcert,
          point,
          removePointFromConcert,
          addPointToConcert,
          onChangePoint
        }) => (

          <div className="range">
            <div className="range">
              <div className="cell-md-12">
                <h6 className="post-event-time">
                                        План концерта
                </h6>
              </div>
            </div>
            <div className="range">
              <div className="cell-md-3">
                <h6 className="post-event-time">
                                        Пункт
                </h6>
              </div>
              <div className="cell-md-7 text-bold small" style={bg}>
                <input
                  value={point}
                  onChange={onChangePoint}

                />
              </div>
              <div className="cell-md-2">

                <a onClick={addPointToConcert} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">+ пункт</a>

              </div>
            </div>
            {/* <!-- Список добавленых пунктов --> */}
            {pointsConcert && pointsConcert.length > 0
              ? pointsConcert.map((pc, key) =>
                <div className="range" key={key}>
                  <div className="cell-md-3">
                    <h6 className="post-event-time">
                      {key}
                    </h6>
                  </div>
                  <div className="cell-md-7 text-bold small" style={bg}>
                    <h6 className="post-event-time">
                      {pc}
                    </h6>
                  </div>
                  <div className="cell-md-2">

                    <a onClick={removePointFromConcert} id={key} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">- убрать</a>

                  </div>
                </div>) : ''}
          </div>
        )}
      </ConstructorProviderConsumer>

    )
  }
}
