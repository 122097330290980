import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withLogin } from '../../provider/LoginProvider'
class AdminMenu extends Component {
  render () {
    return (

      <div>

        <section className="section parallax-container" data-parallax-img="/images/wall2.png">
          <div className="parallax-content">
            <div className="section-85 section-lg-124 shell context-dark">

              {this.props.user != null && this.props.user.role === 'admin'
                ? this.props.menuPanel()
                : <h4>Нет доступа</h4>
              }
              <hr /><br />

            </div>

          </div>

        </section>

      </div>
    )
  }
}
export default withLogin(AdminMenu)

AdminMenu.propTypes = {
  user: PropTypes.object,
  menuPanel: PropTypes.any
}
