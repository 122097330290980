import React, { Component } from 'react'
import { connect } from 'react-redux'
import ButtonTicketByConcert from '../../common/component/ButtonTicketByConcert'
import Player from '../player/Player2'
import { getAllConcertListParce } from '../../state/ConcertState'
import PropTypes from 'prop-types'
import { STATUS_CONCERT_ACTIVE, STATUS_CONCERT_ARCHIVE, STATUS_CONCERT_ACTIVETAB, STATUS_CONCERT_ACTIVE_AWAIT_SALE } from '../../common/const/Const'
class Afisha extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: null,
      musestate: null,
      allconcertlist: null
    }
    console.log()
  }

  getMusician (id) {
    var result
    if (this.state.musestate != null) {
      this.state.musestate.map((muse, i) => {
        if (muse.id === Number(id)) {
          result = muse
        }
      })
    }
    return result
  }

  componentDidMount () {
    var self = this
    var isTab = false

    var post = this.props.musestate
    post.done(function (musestate) {
      var postconcertlist = self.props.concertlist
      postconcertlist.done(function (concertlist) {
        var activeTab = null
        var allconcertlist = getAllConcertListParce(concertlist)
        allconcertlist.map((concert, key) => {
          if (concert.status_concert === STATUS_CONCERT_ACTIVETAB[0]) { isTab = true }
        }
        )
        if (!isTab) {
          activeTab = 'active'
        }
        var post1 = self.props.adminsettings
        post1.done(function (adminsettings) {
          self.setState({
            adminsettings: adminsettings,
            allconcertlist: allconcertlist,
            activeTab: activeTab,
            musestate: musestate
          })
        })
      })
    })
  }

  getTab (concert, key) {
    let activeTab = ''
    let title = concert.date
    if (concert.status_concert === STATUS_CONCERT_ACTIVETAB[0]) { activeTab = 'active' }

    if (concert.status_concert === STATUS_CONCERT_ARCHIVE[0]) { title = title + ' ' + 'и это уже история!' }

    if (concert.status_concert === STATUS_CONCERT_ARCHIVE[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVE[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVETAB[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVE_AWAIT_SALE[0]) {
      return (
        <li className={this.state.activeTab != null && key === 0 ? this.state.activeTab : activeTab}
          key={key} role="presentation">
          <a href={'#tab' + key} role="tab">
            {concert.name}<span>{title}</span>
          </a>
        </li>
      )
    }
  }

  getMusePanel (concert, key) {
    if (concert.status_concert === STATUS_CONCERT_ARCHIVE[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVE[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVETAB[0] ||
            concert.status_concert === STATUS_CONCERT_ACTIVE_AWAIT_SALE[0]) {
      var at = 'tab-pane fade in post-event-type-3'
      if (this.state.activeTab != null && (key === 0 ||
                concert.status_concert === STATUS_CONCERT_ACTIVETAB[0])) {
        at = 'tab-pane fade active in post-event-type-3'
      }
      return (
        <div key={key} className={at} id={'tab' + key} role="tabpanel">

          {concert.musicianConcertList.map((con, key1) =>
            <div className="range post-event-item" key={key1}>
              <div className="cell-md-4 text-md-right">
                <div className="post-event-inset">
                  <h3 className="post-event-time">{con.name}</h3>
                  <p className="post-event-author">{this.getMusician(con.id) ? this.getMusician(con.id).style : ''}</p>
                </div>
              </div>

              <div className="cell-md-8 offset-top-20 offset-md-top-0">
                <div className="post-event-info">
                  <div id={con.name} className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                    <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                      <li id="sh">{this.getMusician(con.id) ? this.getMusician(con.id).title : ''}</li>
                    </ul>
                    <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                      <div className="small">
                        {this.getMusician(con.id) && this.getMusician(con.id).photo
                          ? <div className="range">
                            <div className="cell-md-12 ">
                              <img className="img-responsive reveal-inline-block" src={'/images/musicians/' + this.getMusician(con.id).photo} />
                            </div>
                          </div>
                          : ''}
                        <div className="range">
                          <div className="cell-md-12" >

                            {this.getMusician(con.id)
                              ? <div dangerouslySetInnerHTML={{ __html: this.getMusician(con.id).authorsdescription }} />
                              : ''}
                          </div>
                        </div>
                        <div className="range">
                          <div className="cell-md-12">
                            {/* <div id="vk_playlist_-173622038_1"></div>
                                                <script type="text/javascript"> (function() { VK.Widgets.Playlist("vk_playlist_-173622038_1", -173622038, 1,'6596dcad3ba546d935') }()); </script>
                                                */}
                            {this.getMusician(con.id) && this.getMusician(con.id).playlist
                              ? <div>
                                <Player
                                  playList={JSON.parse(this.getMusician(con.id).playlist)}
                                />

                              </div>
                              : ''}
                          </div>
                        </div>

                        <div className="group group-xl offset-top-30 offset-md-top-30">
                          <ButtonTicketByConcert
                            class="btn btn-primary btn-xs"
                            concertid={concert.id}
                            concert={concert}
                            concertlist={this.state.allconcertlist}
                          />
                          {/*  <a type="button" className="btn btn-primary btn-xs"
                                                    data-tc-event="5c485edfa3a2df000cc494bc"
                                                    data-tc-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNWI4NmM4NWJhOThlODkwMDBjMmNiNDIxIn0.nSwwn5cCnKB9eYFlakTvmvir8rdP_XryuMdavxXR-rE">

                                                    Купить билет </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </div>
      )
    }
  }

  render () {
    // const VK=window.VK;
    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">
        {/* <div id="vk_playlist_-173622038_1"></div>
                                                     <Playlist
                                                        elementId="vk_playlist_-173622038_1"
                                                        ownerId="-173622038"
                                                        playlistId="1"
                                                        hash='6596dcad3ba546d935'

                                                     />  */}
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <div className="cell-lg-10">
                <h1 className="text-bold">
                  {this.state.adminsettings && this.state.adminsettings.title_afisha
                    ? this.state.adminsettings.title_afisha : ''}

                </h1>
                <p>Как это было. Большая сцена Glastonberry.</p>
                <div className="offset-top-50 offset-md-top-90 event">
                  {this.state.allconcertlist
                    ? <ul className="tabs nav nav-pills nav-justified nav-custom text-center" id="tabs-2" role="tablist">
                      {this.state.allconcertlist.map((concert, key) =>
                        this.getTab(concert, key)

                      )}

                    </ul> : ''}
                  {this.state.allconcertlist
                    ? <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                      {this.state.allconcertlist.map((concert, key) =>
                        this.getMusePanel(concert, key)
                      )}

                    </div> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}//

function mapStateToProps (state) {
  return {
    concertlist: state.ConcertReducer.concertlist,
    // allconcertlist:state.ConcertReducer.allconcertlist,
    musestate: state.MuseReducer.musestate,
    adminsettings: state.AdminReducer.adminsettings
  }
}

export default connect(mapStateToProps)(Afisha)

Afisha.propTypes = {

  musestate: PropTypes.any
}
