import React, { Component } from 'react'
import { withLogin } from '../provider/LoginProvider'
import PropTypes from 'prop-types'

class Login extends Component {
  onEnterKey (e) {
    if (e.charCode === 13) {
      this.props.onLogin(e)
    }
    if (e.keyCode === 13) {
      this.props.onLogin(e)
    }
  }

  render () {
    return (
      <section className="section parallax-container context-dark" data-parallax-img="/images/bg-01-1920x1280.jpg">

        <div className="parallax-content">
          <div className="shell">
            <div className="range">
              <div className="range range-xs-center range-xs-middle section-cover section-90">
                <div className="cell-xs-10 cell-lg-10">

                  <form className="rd-mailform text-left" data-form-output="form-output-global" data-form-type="order" >
                    <div className="range range-xs-center">
                      <div className="cell-md-6 ">
                        <div className="form-group">
                          <label className="form-label" htmlFor="admin-login-email">E-Mail:</label>
                          <input className="form-control" id="admin-login-email"
                            type="email" name="admin-login-email"
                            data-constraints="@Required @Email"
                            onChange={this.props.onEMail} />
                        </div>
                      </div>

                      <div className="cell-md-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="admin-login-pass">Пароль</label>
                          <input type="password" id="admin-login-pass" className="form-control"
                            name="admin-login-pass"
                            data-constraints="@Required"
                            onChange={this.props.onPass} onKeyDown={this.onEnterKey.bind(this)} />
                        </div>
                      </div>
                    </div>
                    <div className="range range-xs-center">
                      <div className="cell-md-3">
                        <a type="button" className="btn btn-primary " onClick={this.props.onLogin}>Войти</a>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default withLogin(Login)

Login.propTypes = {
  onPass: PropTypes.func,
  onLogin: PropTypes.func,
  onEMail: PropTypes.func
}
