import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Script from 'react-load-script'
class LoadScript extends Component {
  handleScriptCreate () {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError () {
    this.setState({ scriptError: true })
  }

  handleScriptLoad () {
    this.setState({ scriptLoaded: true })
  }

  render () {
    return (

      <Script
        url={this.props.url}
        onCreate={this.handleScriptCreate.bind(this)}
        onError={this.handleScriptError.bind(this)}
        onLoad={this.handleScriptLoad.bind(this)}
      />

    )
  }
}

export default LoadScript
LoadScript.propTypes = {
  url: PropTypes.any
}
