import React, { Component } from 'react'

import 'react-step-progress-bar/styles.css'
import { ProgressBar } from 'react-step-progress-bar'
import { countVoteConcertMuse } from '../state/VotingState'
import PropTypes from 'prop-types'
export default class DashVoteComp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      count: 0,
      percent: 0
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // eslint-disable-next-line react/prop-types
    const votes = nextProps.votes
    // eslint-disable-next-line react/prop-types
    const count = countVoteConcertMuse(votes, nextProps.museid)
    // eslint-disable-next-line react/prop-types
    let percent = Math.round(count / nextProps.countVote * 100)
    if (isNaN(percent)) { percent = 0 }

    if (percent !== prevState.count) {
      // nextProps.updateMap(nextProps.langcode)
      return { count: count, percent: percent }
    } else return null
  }

  render () {
    return (
      <div className="range range-condensed range-md-center">

        <div className="cell-xs-4 cell-md-4 cell-md-preffix-0 cell-xl-4">
          <div className="range range-condensed range-md-center offset-top-10 offset-md-top-30">
            <h3 className="post-event-time">{this.props.nameMuse}</h3>
          </div>
        </div>
        <div className="cell-xs-8 cell-md-8 cell-xl-8">

          <h5>{this.state.count}</h5>
          <ProgressBar
            percent={this.state.percent}
            filledBackground="linear-gradient(to right, #ff412e, #f0bb31)"

            height={20}

          />
          {/* <Line percent={this.state.count} strokeWidth="4" strokeColor="#D3D3D3" /> */}

        </div>

      </div>
    )
  }
}

DashVoteComp.propTypes = {

  nameMuse: PropTypes.string
}
