import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class Modalvideo extends Component {
  render () {
    return (

      <div className="modal fade" id="modalvideo" role="dialog">
        <div className="modal-dialog modal-dialog_custom modal-dialog_custom-details">

          <div className="modal-dialog__inner">
            <button className="close" type="button" data-dismiss="modal"></button>
            <div className="modal-dialog__content">
              <div className="modal-dialog__content-inner">
                <div className="embed-responsive embed-responsive-16by9" style={{ height: 'auto' }}>
                  <iframe className="embed-responsive-item" id="video" width="560" height="100%" src={this.props.video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="modal-dialog__content-inner">
                <h4>#МОЛОКОЗАКИПАЕТ!</h4>
                <p className="offset-top-20">
                                    Осенью встретимся!!!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
Modalvideo.propTypes = {

  video: PropTypes.string

}
