import * as types from '../constants/ConcertActionType'

import { getAllConcerts, updateConcert, getActiveConcerts, getConcertTemplate, saveConcert, initialConcert, getConcertList } from '../state/ConcertState'
import update from 'react-addons-update'

export default function ConcertReducer (state = initialConcert, action) {
  //
  const cc = state.concertconstructor
  switch (action.type) {
    case types.RELOAD:
      state.concertlist = getActiveConcerts()

      state.allconcertlist = getAllConcerts()
      return {
        ...state,
        concertlist: state.concertlist,
        allconcertlist: state.allconcertlist
      }
    case types.GET_TEMPLATE_CONCERT:
      state.concertstate = update(state.concertstate, { $push: [getConcertTemplate(state)] })
      return {
        ...state,
        concertstate: state.concertstate
      }
    case types.SAVE_CONCERT:

      return {
        ...state,
        concertstate: saveConcert()

      }
    case types.ADD_CONCERT_DATE:

      cc.step = 1
      return {
        ...state,
        concertconstructor: cc
      }
    case types.ADD_CONCERT_PLACE:

      cc.step = 2
      cc.date = action.date
      return {
        ...state,
        concertconstructor: cc
      }
    case types.CREATE_MUSICIAN_LIST:

      cc.step = 3
      cc.date = action.date
      cc.place = action.place
      return {
        ...state,
        concertconstructor: cc
      }
    case types.ADD_MUSICIAN_TO_CONCERT:

      cc.step = 4
      cc.date = action.date
      cc.place = action.place
      cc.musician = action.musician
      return {
        ...state,
        concertconstructor: cc
      }
    case types.ADD_POINT_TO_CONCERT:

      cc.step = 5
      cc.date = action.date
      cc.place = action.place
      cc.musician = action.musician
      cc.pointsConcert = action.pointsConcert
      return {
        ...state,
        concertconstructor: cc
      }
    case types.REFRESH_CONCERT_LIST:
      return {
        ...state,
        concertlist: getConcertList()
      }
    case types.ACTIVE_CONCERT_LIST:
      return {
        ...state,
        activeconcertlist: getActiveConcerts()
      }
    case types.UPDATE_CONCERT:
      var upd = updateConcert(action.state, action.id)
      // eslint-disable-next-line no-array-constructor
      var arr = new Array()
      for (var i = 0; i < upd.length; i++) {
        arr.push(upd[i])
      }
      return {
        ...state,
        concertlist: arr
      }

    default:

      return state
  }
}
