import React, { Component } from 'react'
import DashVoteComp from './DashVoteComp'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import PropTypes from 'prop-types'
import { getMusicianByConcert, getVotingByConcertId, countVoteConcert } from '../state/VotingState'
// import { withDashboard } from '../provider/DashboardProvider';
// import {DashboardProviderConsumer} from '../provider/DashboardProvider'
class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id_concert: queryString.parse(this.props.location.search).id_concert
        ? queryString.parse(this.props.location.search).id_concert
        : this.props.id_concert
    }
  }

  //
  initDashboard () {
    var self = this
    var idConcert = queryString.parse(this.props.location.search).id_concert
    if (!idConcert) { idConcert = this.props.id_concert }
    if (idConcert) {
      var concert = getMusicianByConcert(idConcert)
      if (concert) {
        var muse = JSON.parse(concert.info).musicianConcertList
        var date = JSON.parse(concert.info).date
        const post = getVotingByConcertId(idConcert)
        post.done(function (votes) {
          self.setState({
            id_concert: idConcert,
            countVote: countVoteConcert(votes),
            muse: muse,
            count: 0,
            concert: concert,
            votes: votes,
            is_vote: false,
            date: date

          })
        })
      } else {
        self.setState({
          id_concert: null

        })
      }
    }
  }

  componentWillMount () {
    this.initDashboard()
  }

  /* static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps!==prevState){
            //nextProps.updateMap(nextProps.langcode)
          return { musicianid: prevState.musicianid};
       }
       else return null;
    } */
  componentDidMount () {
    var self = this
    if (!this.state.id_concert) return
    let isVote = false
    this.t = setInterval(() => {
      const post = getVotingByConcertId(self.state.id_concert)
      post.done(function (votes) {
        if (votes && votes.length > 0) {
          for (let i = 0; i < votes.length; i++) {
            isVote = votes[i].open_vote

            if (isVote) break
          }
          // if (!is_vote)
          // clearInterval(this.t)
          self.setState((prevState) => ({
            countVote: countVoteConcert(votes), // prevState.countVote + 1,
            votes: votes,
            is_vote: isVote
          }))
        }
      })
    }, 5000)
  }

  componentWillUnmount () {
    clearInterval(this.t)
  }

  render () {
    if (!this.state.id_concert) {
      return (
        <section className="section parallax-container" data-parallax-img="/images/wall2.png">
          <div className="parallax-content">
            <div className="section-85 section-lg-124 shell context-dark">
              <div className="range range-xs-center">
                <div className="cell-lg-12 cell-md-12 cell-xl-12 cell-xs-12">
                  <h1 className="text-bold">Голосование </h1>
                  <h4>Не выбран концерт<span className="text-primary text-uppercase text-bold"></span></h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    } else {
      return (
      // <DashboardProviderConsumer>
      // {({ countVote }) => (

        <section className="section parallax-container" data-parallax-img="/images/wall2.png">
          <div className="parallax-content">
            <div className="section-85 section-lg-124 shell context-dark">
              {/* <div className="range range-xs-center range-xs-middle">
                    <div className="cell-xs-10 cell-lg-8">
                        <CountdownDB date="12 Mar 2019 23:59" dataformat="MS"/>
                    </div>
                    </div> */}
              <div className="range range-xs-center">
                <div className="cell-lg-12 cell-md-12 cell-xl-12 cell-xs-12">
                  <h1 className="text-bold">Голосование {this.state.is_vote ? ' Открыто' : ' Закрыто'}</h1>
                  <h6>{this.state.date}</h6>
                  <h4>Всего голосов&nbsp;&nbsp;<span className="text-primary text-uppercase text-bold">
                    {this.state.countVote}</span></h4>
                  {/* <!-- Circle-progress-bar --> */}

                  <div className="offset-top-50 offset-md-top-50 event">
                    {this.state.muse &&
                                            this.state.muse.length > 0
                      ? this.state.muse.map((mu, key) =>
                        <div className="shell shell-wide" key={mu.id}>
                          <div className="range range-condensed range-md-center offset-top-50 offset-md-top-90" >
                            <div className="cell-xs-12 cell-md-12 cell-xl-12">

                              <DashVoteComp
                                nameMuse={mu.name}
                                votes={this.state.votes}
                                museid={mu.id}
                                countVote={this.state.countVote}
                              />

                            </div>
                          </div>
                        </div>
                      ) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      // )}
      // </DashboardProviderConsumer>
      )
    }
  }
}
export default withRouter(Dashboard)
// export default withDashboard(Dashboard)
Dashboard.propTypes = {

  location: PropTypes.any,
  id_concert: PropTypes.number
}
