import React, { Component } from 'react'
import Countdown from './Countdown'
import ButtonTicket from '../../common/component/ButtonTicket'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { TranslatableText } from '../../provider/language/TranslatableText'
class Main extends Component {
  constructor (props) {
    super(props)

    this.state = {
      adminsettings: null

    }
  }

  componentDidMount () {
    var self = this
    var post = this.props.adminsettings
    post.done(function (adminsettings) {
      self.setState({
        adminsettings: adminsettings
      })
    })
  }

  render () {
    return (
      <section className="section parallax-container context-dark" data-parallax-img="/images/bg-01-1920x1280.jpg">
        <div className="parallax-content">
          <div className="shell">
            <div className="range">
              <div className="range range-xs-center range-md-center range-xs-middle section-cover section-90">
                <div className="cell-xs-12 cell-lg-12">

                  <h1 className="text-primary text-uppercase text-bold">
                    {this.state.adminsettings && this.state.adminsettings.title_main_page
                      ? this.state.adminsettings.title_main_page : '' }
                  </h1>

                  {/* <div>
                        <div className="reveal-inline-block text-extra-small"><span className="text-middle inset-right-10 icon icon-xxs icon-white mdi mdi-calendar-today"></span><span className="text-middle">клуб Glastonberry</span></div>
                        <hr className="divider divider-vertical reveal-inline-block"/>
                        <div className="reveal-inline-block text-extra-small"><span className="text-middle inset-right-10 icon icon-xxs icon-white mdi mdi-map-marker"></span><span className="text-middle">Москва</span></div>
                      </div> */}
                  {/* <!-- <img className="img-responsive center-block offset-top-65" src="<?php echo get_template_directory_uri() ?>/images/title.png" alt=""> --> */}
                  <p className="offset-top-36 animateItem" data-animation="fadeInUp" data-delay="0.1" data-duration="1">
                    <TranslatableText text="MAIN.HEADING"/>
                    <br /><br />
                    <span className="text-primary text-uppercase text-bold">
                      за твой ценный голос!
                    </span>
                  </p>
                  <div className="group group-xl offset-top-30 offset-md-top-30">
                    {this.state.adminsettings && this.state.adminsettings.visible_ticket_button &&
                    this.state.adminsettings.visible_ticket_button === 'true'
                      ? <ButtonTicket class="btn btn-primary" /> : ''}
                    {/* <a className="btn btn-primary animateItem"  style={style}
                        data-tc-event="5c485edfa3a2df000cc494bc"
                        data-tc-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNWI4NmM4NWJhOThlODkwMDBjMmNiNDIxIn0.nSwwn5cCnKB9eYFlakTvmvir8rdP_XryuMdavxXR-rE"
                        data-animation="fadeInUp" data-delay="0.2" data-duration="1">купить билет</a> */}
                    <a className="btn btn-primary animateItem"
                      href="/gallery"
                      data-animation="fadeInUp" data-delay="0.2" data-duration="1">Матрица</a>
                    <a className="btn btn-primary-bordered animateItem" href="#"
                      data-toggle="modal" data-target="#modalvideo" data-animation="fadeInUp" data-delay="0.2"
                      data-duration="1">Смотреть видео</a>

                  </div>

                  <span className="reveal-inline-block offset-top-30">

                    Объявляем обратный отсчет до старта...
                  </span>
                  {this.state.adminsettings
                    ? <Countdown date={this.state.adminsettings.day_start ? this.state.adminsettings.day_start : '01 sept 2019 19:30'}
                      classCss="countdown-container"
                      dataformat="DHMS" />
                    : ''}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

function mapStateToProps (state) {
  return {

    adminsettings: state.AdminReducer.adminsettings
  }
}

export default connect(mapStateToProps)(Main)

Main.propTypes = {

  adminsettings: PropTypes.any
}
