import * as types from '../constants/RuleTypes'

import { initialRules, getActiveRules } from '../state/RuleState'
// import update from 'react-addons-update';

export default function ReleReducer (state = initialRules, action) {
  switch (action.type) {
    case types.GET_ACTIVE_RULES:
      return {
        ...state,
        rules: getActiveRules()
      }
    default:

      return state
  }
}
