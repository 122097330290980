import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class YndexMoneyPanel extends Component {
  render () {
    return (
      <div>
        <h4 >
                    Купить дополнительный голос или голоса. Стоимость одного голоса {this.props.vote_price} &#8381;
        </h4>
        <div className="range range-xs-center range-md-center range-lg-center">

          <iframe src={'https://money.yandex.ru/quickpay/shop-widget?writer=seller&targets=MOLOKOMUSE%20' +
                        this.props.date + '&targets-hint=&default-sum=' +
                        this.props.vote_price + '&button-text=11&payment-type-choice=on&successURL=' +
                        this.props.successURL + '?code=' +
                        /* http://molokomuse.ru/voting?code=` + */
                        this.props.code + ':' + this.props.type +
                        '&label=' +
                        this.props.code + ':' + this.props.type +
                        '&quickpay=shop&account=410014056822903'}
          width="100%" height="216" frameBorder="0"
          allowtransparency="true" scrolling="no">

          </iframe>
        </div>
      </div>
    )
  }
}
YndexMoneyPanel.propTypes = {
  code: PropTypes.any,
  type: PropTypes.any,
  date: PropTypes.any,
  vote_price: PropTypes.any,
  successURL: PropTypes.any
}
