import { LOGINING_USER } from '../constants/LoginTypes'

export const initialLogin = {
  logining: !!localStorage.getItem(LOGINING_USER)
}

export function isLogin () {
  var l = localStorage.getItem(LOGINING_USER)
  if (l) {

  }
}
export function createUser (email, pass, role) {
  var result = false

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/createuser',
    data: {
      email: email,
      pass: pass,
      role: role
    }
  })
    .done(function (data) {
      // state.concertstate.push(data)
      if (data) {
        result = data
        // result.authdata = window.btoa(result.email + ':' + result.pass);
      }
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function login (email, pass) {
  var result = false

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/login',
    data: {
      email: email,
      pass: pass
    }
  })
    .done(function (data) {
      // state.concertstate.push(data)
      // if (data){
      result = data
      //    result.authdata = window.btoa(result.email + ':' + result.pass);
      // localStorage.setItem(LOGINING_USER, JSON.stringify(result));
      // }
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
