import React, { Component } from 'react'
import SetInputField from './SetInputField'
import PropTypes from 'prop-types'

import SetTextArea from './SetTextArea'
export default class MusicianFormBody extends Component {
  render () {
    return (
      <div className="small">

        <SetTextArea value={this.props.value.authorsdescription}
          id={this.props.value.id}
          field="authorsdescription"
          label="О себе:"
          search={this.props.search} />

        <SetTextArea value={this.props.value.style}
          id={this.props.value.id}
          field="style"
          label="Стиль" />
        <SetTextArea value={this.props.value.media}
          id={this.props.value.id}
          field="media"
          label="Ссылки на медиа" />

        <SetInputField
          label="Рейтинг:&nbsp;"
          value={this.props.value.assessment}
          id={this.props.value.id}
          field="assessment" />

        <SetTextArea value={this.props.value.comment}
          id={this.props.value.id}
          field="comment"
          label="Наш комментарий" />

        <SetTextArea value={this.props.value.playlist}
          id={this.props.value.id}
          field="playlist"
          label="Код плейлиста" />

        <SetInputField value={this.props.value.title}
          id={this.props.value.id}
          field="title"
          label="Заголовок на блок" />
        <SetInputField value={this.props.value.photo}
          id={this.props.value.id}
          field="photo"
          label="Фото по адресу /images/musicians/" />

        <SetInputField
          label="Номер концерта:&nbsp;"
          value={this.props.value.concert}
          id={this.props.value.id}
          field="concert" />

      </div>
    )
  }
}
MusicianFormBody.propTypes = {
  search: PropTypes.any,
  value: PropTypes.any
}
