import React, { Component } from 'react'

export default class IFrame extends Component {
  render () {
    return (

      <iframe src="http://localhost/voting?code=a26bd4cf-8e10-447c-a0e4-5cf6f3293f47&type=ticket" width="100%" height="100%"></iframe>

    )
  }
}
