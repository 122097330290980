import React, { Component } from 'react'
import Modalvideo from './modalform/Modalvideo'
import PropTypes from 'prop-types'
import packageJson from '../../package.json'
import { withLogin } from '../provider/LoginProvider'
class Footer extends Component {
  login () {
    if (this.props.user === null) {
      return (
        <a className="smalled" href="/login" title="Войти">войти</a>
      )
    } else return ''
  }

  logout () {
    if (this.props.user != null) {
      return (
        <a className="smalled" href="#" onClick={this.props.onLogout} title="Выйти">выйти</a>

      )
    } else return ''
  }

  admin () {
    if (this.props.user != null && this.props.user.role === 'admin') {
      return (
        <a className="smalled" href="/admin" title="Админка">админка</a>

      )
    } else return ''
  }

  version () {
    return (
      <span className="smalled" >версия: {packageJson.version} </span>

    )
  }

  render () {
    const video = 'https://www.youtube.com/embed/nFyszwVmMWU?loop=1&playlist=nFyszwVmMWU'
    return (
      <div>
        <footer className="section-relative section-top-66 section-bottom-34 page-footer bg-gray-base context-dark">
          <div className="snackbars" id="form-output-global"></div>

          <div className="shell">
            <div className="range range-sm-center text-lg-left">
              <div className="cell-sm-12">
                <div className="range range-xs-center">
                  <div className="cell-xs-10 cell-sm-3 text-left cell-sm-push-4 cell-sm-10 cell-lg-5 offset-sm-top-50 offset-lg-top-0 cell-lg-push-2 offset-lg-top-10">

                  </div>
                  <div className="cell-xs-10 cell-sm-3 offset-top-65 cell-sm-push-2 cell-sm-6 cell-lg-4 offset-sm-top-0 cell-lg-push-3 offset-lg-top-10">
                    <div className="offset-lg-top-30">

                    </div>
                  </div>
                  <div className="cell-xs-10 cell-sm-3 offset-top-65 cell-sm-push-1 offset-sm-top-0 cell-sm-6 cell-lg-3 cell-lg-push-1">

                    <div className="footer-brand"><a href="/"><img width="180" height="52" src="/images/logo-dark-187x32.png" alt=""/></a></div>
                    <address className="contact-info offset-top-20 p">

                      <div className="offset-top-10 offset-lg-top-20">
                        <dl>
                          <dt className="text-white text-bold">E-mail:&nbsp;</dt>
                          <dd><a className="text-dark" href="mailto:hello@molokomuse.ru">hello@molokomuse.ru</a></dd>
                        </dl>
                      </div>
                      <div className="offset-top-10 offset-lg-top-20">
                        <dl>

                          <dd>
                            <a className="text-extra-small" href="/persdata" >
                                Согласие на обработку своих персональных данных</a>
                          </dd>
                        </dl>
                      </div>
                      <div className="offset-top-36 text-xs-center text-md-left">
                        <ul className="list-inline">
                          <li><a className="icon fa fa-vk icon-xxs icon-circle icon-darkest-filled" rel="noreferrer noopener" target="_blank" href="https://vk.com/molokomuse"></a></li>
                          <li><a className="icon fa fa-instagram icon-xxs icon-circle icon-darkest-filled" rel="noreferrer noopener" target="_blank" href="https://www.instagram.com/molokomuse/" ></a></li>
                          <li><a className="icon fa fa-youtube icon-xxs icon-circle icon-darkest-filled" href="https://www.youtube.com/channel/UCca4U7-RIyMPhNHug3Oe8mA" rel="noreferrer noopener" target="_blank"></a></li>

                        </ul>
                      </div>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shell offset-top-50 page-footer-big">
            <p className="text-darker">МОЛОКОMUSE &copy; <span id="copyright-year"></span> . <a href="#">All Rights Reserved </a></p>
                &nbsp;&nbsp;&nbsp;
            {this.login()}
            {this.logout()}
                &nbsp;&nbsp;&nbsp;
            {this.admin()}
                &nbsp;&nbsp;&nbsp;
            {this.version()}
          </div>
        </footer>
        <Modalvideo
          video={video}
        />

      </div>
    )
  }
}

export default withLogin(Footer)
Footer.propTypes = {
  user: PropTypes.any,
  onLogout: PropTypes.func
}
