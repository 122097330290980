export function loadvideo () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/loadvideo'

  })
}
export function loadstreamvideo () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/loadstreamvideo'

  })
}
// downloadvideo(id_concert, '/api/downloadtickets', typefordownload)
export function downloadvideo (filenamefordownload) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', '/api/dowmloadvideo?filename=' + filenamefordownload, true)
  xhr.responseType = 'arraybuffer'

  xhr.onload = function () {
    if (this.status === '200') {
      var filename = filenamefordownload

      var type = 'application/octet-stream'
      // get the filename from the header.
      try {
        var disposition = this.getResposeHeader('Content-Disposition')
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches !== null && matches[1]) { filename = matches[1].replace(/['"]/g, '') }
        }
      } catch (err) {
        console.log(err)
      }
      try {
        type = this.getResponseHeader('Content-Type')
      } catch (err) {
        console.log(err)
      }
      var blob = new Blob([this.response], { type: type })
      // workaround for IE
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var URL = window.URL || window.webkitURL
        var downloadURL = URL.createObjectURL(blob)
        if (filename) {
          var aLink = document.createElement('a')
          if (typeof aLink.download === 'undefined') {
            window.location = downloadURL
          } else {
            aLink.href = downloadURL
            aLink.download = filename
            document.body.appendChild(aLink)
            aLink.click()
          }
        } else {
          window.location = downloadURL
        }
        setTimeout(function () {
          URL.revokeObjectURL(downloadURL)
        }, 10000)
      }
    } else {
      alert('error')
    }
  }
  xhr.setRequestHeader('Content-type', 'application/octet-stream')
  xhr.send()
}
