import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextAreaButtonSave from '../controls/TextAreaButtonSave'
class SetTextArea extends Component {
  render () {
    return (
      <TextAreaButtonSave
        label={this.props.label}
        value={this.props.value}
        idmusicians={this.props.id}
        field={this.props.field}
        search={this.props.search}
      />
    )
  }
}

export default SetTextArea
SetTextArea.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any,
  field: PropTypes.any,
  search: PropTypes.any
}
