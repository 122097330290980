import React, { Component } from 'react'
import { buildMuse } from '../../actions/MuseActions'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import MusicianForm from '../forms/MusicianForm'

import { withRouter } from 'react-router'
import 'rc-collapse/assets/index.css'
import 'string.prototype.repeat'
// import 'string.prototype.repeat';
import Collapse, { Panel } from 'rc-collapse'
const arrowPath = 'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88' +
  '.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.' +
  '6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5.' +
  '2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z'
// eslint-disable-next-line react/prop-types
function expandIcon ({ isActive }) {
  return (
    <i style={{ marginRight: '.5rem' }}>
      <svg
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
        fill="currentColor"
        style={{
          verticalAlign: '-.125em',
          transition: 'transform .2s',
          transform: `rotate(${isActive ? 90 : 0}deg)`
        }}
      >
        <path d={arrowPath} p-id="5827"></path>
      </svg>
    </i>
  )
}
class Admin2 extends Component {
  constructor (props) {
    super(props)

    console.log()

    this.state = {
      musestate: null,
      search: '',
      accordion: true,
      activeKey: []
    }
  };

  componentWillMount () {

  }

  componentDidMount () {
    var self = this
    var post = this.props.musestate
    post.done(function (musestate) {
      self.setState({
        musestate: musestate
      })
    })
  }

  onSearch (e) {
    this.setState({
      search: e.target.value
    })
    // this.forceUpdate()
  }

  onChangeActiveKey = (activeKey) => {
    this.setState({
      activeKey
    })
  }

  getItems () {
    const items = []
    if (this.state.musestate) {
      this.state.musestate
        .filter(muse => muse.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          muse.authorsdescription.toLowerCase().includes(this.state.search.toLowerCase()))
        .map((muse, i) =>
          items.push(
            <Panel header={muse.name} key={i}
              className="offset-top-20 offset-md-top-20 post-event-info responsive-tabs responsive-tabs-boxed">
              <MusicianForm
                key={muse.id}

                muse={muse}
                search={this.state.search}
              />
            </Panel>
          ))
    }

    return items
  }

  render () {
    const activeKey = this.state.activeKey
    const style = {
      color: 'white',
      width: '100%'
    }
    return (
      <div>
        <br />
        <div className="range range-xs-center">
          <form style={style}>
            <div className="form-group" >
              <label className="form-label" htmlFor="searchLine">Поиск: </label>
              <input className="form-control" style={style} id="searchLine" type="text"
                value={this.state.search} onChange={this.onSearch.bind(this)} />
            </div>
          </form>
        </div>
        <div className="range range-xs-center">
          <div className="cell-lg-12 text-md-left" style={{ color: 'black' }}>

            <Collapse
              accordion={false}

              onChange={this.onChangeActiveKey}
              activeKey={activeKey}
              expandIcon={expandIcon}
            >
              {this.getItems()}

            </Collapse>

          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {

    musestate: state.MuseReducer.musestate
  }
}

function mapDispatchToProps (dispatch) {
  return {
    buildMuse: () => {
      dispatch(buildMuse())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin2))
Admin2.propTypes = {
  musestate: PropTypes.any
}
