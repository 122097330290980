import React, { Component } from 'react'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { generateQR } from '../state/VotingState'
import PropTypes from 'prop-types'

class GenVote extends Component {
  constructor (props) {
    super(props)
    var idConcert = queryString.parse(this.props.location.search).id_concert
      ? queryString.parse(this.props.location.search).id_concert : this.props.id_concert
    var gencode = queryString.parse(this.props.location.search).gencode
      ? queryString.parse(this.props.location.search).gencode : this.props.gencode

    if (idConcert != null && gencode != null) {
      var addvote = generateQR(idConcert, 'SITE', true, 1, 0, 'addvote')
      this.props.history.push({
        pathname: '/voting',
        search: '?code=' + addvote.code + '&type=addvote'
      })
    }
  }

  render () {
    return (
      <div>

      </div>
    )
  }
}
export default withRouter(GenVote)

GenVote.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  gencode: PropTypes.any,
  id_concert: PropTypes.number

}
