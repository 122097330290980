import React, { Component } from 'react'

import ConstructorProvider from '../../provider/ConstructorProvider'
import AdminConcert3 from './AdminConcert3'
import { withLogin } from '../../provider/LoginProvider'
import PropTypes from 'prop-types'

class Concert extends Component {
  render () {
    if (this.props.isAccess()) {
      return (

        <ConstructorProvider>
          <main className="page-content">
            <section className="section parallax-container" data-parallax-img="images/city-1920-1276.png">

              <div className="parallax-content">
                <div className="section-85 section-lg-124 shell context-dark">
                  {this.props.menuPanel()}
                  <AdminConcert3 />
                </div>
              </div>
            </section>

          </main>

        </ConstructorProvider>
      )
    } else {
      return (
        this.props.noAccessBlock()
      )
    }
  }
}

export default withLogin(Concert)
Concert.propTypes = {
  noAccessBlock: PropTypes.func,
  isAccess: PropTypes.func,
  menuPanel: PropTypes.any
}
