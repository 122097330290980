import React, { Component } from 'react'
import { withLogin } from '../../provider/LoginProvider'
import { connect } from 'react-redux'
import { getvotesbyparam } from '../../state/VotingState'
import { getAllConcertListParce } from '../../state/ConcertState'
import PropTypes from 'prop-types'
import VoteFormForList from '../forms/VoteFormForList'
import DialogModal from '../../common/modalform/DialogModal'
import Paginator from '../../common/component/Paginator'
class ListVote extends Component {
  constructor (props) {
    super(props)
    var gvp = getvotesbyparam(0, 10)
    var arr = Object.keys(gvp)
    this.state = {
      page: 1,
      countOfPage: 20,
      votes: gvp,
      at: 'tab-pane fade in post-event-type-3',
      at1: 'tab-pane fade active in post-event-type-3',
      text: 'Голосование',
      textresult: 'Закрыто',
      openDialog: false,
      activeTab: arr[0],
      // eslint-disable-next-line no-array-constructor
      resultPanel: new Array(),

      allconcertlist: null
    }
  }

  componentDidMount () {
    var self = this
    var postconcertlist = self.props.concertlist
    postconcertlist.done(function (concertlist) {
      var allconcertlist = getAllConcertListParce(concertlist)

      self.setState({
        allconcertlist: allconcertlist
      })
    })
  }

  /*
    componentWillUnmount(){
        window.removeEventListener('scroll',this.onScrollVotePanel)
    } */
  onOpenDialog (text, textresult, openDialog) {
    this.setState({
      text: text,
      textresult: textresult,
      openDialog: openDialog
    })
  }

  onCloseDialog () {
    this.setState({

      openDialog: false
    })
  }

  onScrollVotePanel (e) {
    // console.log(e.target+" "+window.scrollY)
  }

    onLoadPage = (i, idConcert) => {
      var v = getvotesbyparam(i * 10, 10)
      var newVotes = new Map()

      for (var vo in this.state.votes) {
        if (vo === idConcert.toString()) {
          newVotes[vo] = v[idConcert]
        } else {
          newVotes[vo] = this.state.votes[vo]
        }
      }

      this.setState(
        {
          votes: newVotes
        })
    }

    panelVote (votes, idConcert) {
      // eslint-disable-next-line no-array-constructor
      const result = new Array()
      if (votes[idConcert] && votes[idConcert].length > 0) {
        votes[idConcert].map((vote, i) =>
          result.push(<div key={vote.id}>
            {vote.id_concert === idConcert
              ? <div>
                <VoteFormForList vote={vote}
                  onOpenDialog={this.onOpenDialog.bind(this)} />
                <br />
              </div>
              : ''
            }
          </div>)
        )
      }

      return result
    }

    getVotePanel (concert, key) {
      var at = 'tab-pane fade in post-event-type-3'
      var at1 = 'tab-pane fade active in post-event-type-3'

      return (
        <div className={key === this.state.activeTab ? at1 : at} id={'tab' + concert.id} role="tabpanel" key={concert.id} >
          <Paginator
            id_concert={concert.id}
            row_count={10}
            onLoadPage={this.onLoadPage.bind(this)}
          />
          <br />
          {this.panelVote(this.state.votes, concert.id)}
          <br />
          <Paginator
            id_concert={concert.id}
            row_count={10}
            onLoadPage={this.onLoadPage.bind(this)}
          />
        </div>

      )
    }

    getTab (concert, key) {
      let activeTab = ''
      if (key === this.state.activeTab) { activeTab = 'active' }
      return (
        <li className={activeTab}
          key={concert.id} role="presentation">
          <a href={'#tab' + concert.id} role="tab">
            {concert.name}<span>{concert.date}</span>
          </a>
        </li>
      )
    }

    render () {
      if (this.props.isAccess()) {
        return (
          <section className="section parallax-container" data-parallax-img="/images/wall2.png" >
            <div className="parallax-content">
              <div className="section-85 section-lg-124 shell context-dark">
                {this.props.menuPanel()}
                <div className="range range-xs-center">
                  <div className="cell-md-12">
                    <h1 className="text-bold">Голоса и QR-коды</h1>
                    <p>по концертам</p>
                    <div className="range" >
                      <div className="cell-md-5">
                                            Код
                        <br />
                                            Матрица
                      </div>
                      <div className="cell-md-2">
                                            Баркод
                      </div>
                      <div className="cell-md-2">
                                            К-во голосов
                        <br />
                                            Открыть
                      </div>
                      <div className="cell-md-1">
                                            Тип
                      </div>
                      <div className="cell-md-1">
                                            Источник
                      </div>
                    </div>

                    <div className="offset-top-50 offset-md-top-90 event">
                      {this.state.allconcertlist
                        ? <ul className="tabs nav nav-pills nav-justified nav-custom text-center"
                          id="tabs-2" role="tablist" >
                          {this.state.allconcertlist.map((concert, key) =>
                            this.getTab(concert, concert.id + '')

                          )}

                        </ul>
                        : ''}
                      {this.state.allconcertlist
                        ? <div className="tab-content tab-conten-vert offset-top-50 text-md-left">

                          {this.state.allconcertlist.map((concert, key) =>
                            this.getVotePanel(concert, concert.id + '')
                          )}

                        </div>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogModal text={this.state.text}
              textresult={this.state.textresult}
              openDialog={this.state.openDialog}
              onClose={this.onCloseDialog.bind(this)} />
          </section>
        )
      } else {
        return (
          this.props.noAccessBlock()

        )
      }
    }
}
function mapStateToProps (state) {
  return {

    // allconcertlist:state.ConcertReducer.allconcertlist
    concertlist: state.ConcertReducer.concertlist

  }
}

export default withLogin(connect(mapStateToProps)(ListVote))
ListVote.propTypes = {
  noAccessBlock: PropTypes.func,
  menuPanel: PropTypes.any,
  isAccess: PropTypes.func
}
