import React, { Component } from 'react'
import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'

export default class StepCreatePlace extends Component {
  render () {
    const style = {
      marginLeft: '10px'
    }
    const bg = {

      color: '#191919'
    }
    return (
      <ConstructorProviderConsumer>
        {({ step, place, onChangePlace, createMusicianList }) => (
          <div>
            <div className="range">

              <div className="cell-md-3">
                <h6 className="post-event-time">
                                    Место
                </h6>
              </div>
            </div>
            <div className="range">
              <div className="cell-md-7">
                <input style={bg} onChange={onChangePlace} value={place} type="text" />
              </div>
              <div className="cell-md-2">
                {step === 2
                  ? <a onClick={createMusicianList} className="btn btn-info animateItem btn-xs" style={style}
                    data-animation="fadeInUp" data-delay="0.2" data-duration="1">+ участник</a>

                  : ''}
              </div>

            </div>
          </div>
        )}
      </ConstructorProviderConsumer>
    )
  }
}
