export function getConcerImg () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/getconcertimgs'

  })
}

export function getVideoContent (usrVideo) {
  return window.$.ajax({
    url: usrVideo,
    xhrFields: {
      withCredentials: true
    }

  })
}
