import { STATUS_CONCERT_ACTIVE } from '../common/const/Const'

export const initialConcert = {
  // eslint-disable-next-line no-array-constructor
  concertstate: new Array(),
  concertlist: getConcertList(),
  concertconstructor: {
    step: 0,
    date: '',
    place: '',
    musician: new Map(),
    pointsConcert: new Map()
  },
  stepsCreate: [0, 1, 2, 3, 4, 5]
}
//
export function onDownloadTickets (idConcert, typefordownload) {
  /**
     * typeCollection:[{value:"ticket",label:"Билет"},{value:"addvote",label:"Доп голос"}]
     */

  download(idConcert, '/api/downloadtickets', typefordownload)
}
function download (id, url, typefordownload) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url + '/?id_concert=' + id + '&typefordownload=' + typefordownload, true)
  xhr.responseType = 'arraybuffer'

  xhr.onload = function () {
    if (this.status === '200') {
      var filename = 'Ticket.pdf'
      if (typefordownload === 'addvote') filename = 'AdditionalVote.pdf'
      var type = 'application/octet-stream'
      // get the filename from the header.
      try {
        var disposition = this.getResposeHeader('Content-Disposition')
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches !== null && matches[1]) { filename = matches[1].replace(/['"]/g, '') }
        }
      } catch (err) {
        console.log(err)
      }
      try {
        type = this.getResponseHeader('Content-Type')
      } catch (err) {
        console.log(err)
      }
      var blob = new Blob([this.response], { type: type })
      // workaround for IE
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        var URL = window.URL || window.webkitURL
        var downloadURL = URL.createObjectURL(blob)
        if (filename) {
          var aLink = document.createElement('a')
          if (typeof aLink.download === 'undefined') {
            window.location = downloadURL
          } else {
            aLink.href = downloadURL
            aLink.download = filename
            document.body.appendChild(aLink)
            aLink.click()
          }
        } else {
          window.location = downloadURL
        }
        setTimeout(function () {
          URL.revokeObjectURL(downloadURL)
        }, 10000)
      }
    } else {
      alert('error')
    }
  }
  xhr.setRequestHeader('Content-type', 'application/octet-stream')
  xhr.send()
}
export function getConcertById (idConcert) {
  var result = ''

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/concertbyid',
    data: {
      id_concert: idConcert
    }

  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {

    })

  return result
}
export function getConcertList () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: true,
    url: '/api/concertlist'

  })
}
export function getAllConcertListParce (allConcerts) {
  // eslint-disable-next-line no-array-constructor
  var activeConcert = new Array()

  if (allConcerts && allConcerts.length > 0) {
    allConcerts.map((concert, i) => {
      var info = JSON.parse(concert.info)
      var id = concert.id

      concert = info
      concert.id = id

      activeConcert.push(concert)
    })
  }

  return activeConcert
}
export function getConcertListAdmin () {
  var result = ''

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/concertlist'

  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function getAllConcerts (allConcerts) {
  // eslint-disable-next-line no-array-constructor
  var activeConcert = new Array()
  // var allConcerts = getConcertListAdmin();
  if (allConcerts && allConcerts.length > 0) {
    allConcerts.map((concert, i) => {
      var info = JSON.parse(concert.info)
      var id = concert.id

      concert = info
      concert.id = id

      activeConcert.push(concert)
    })
  }

  return activeConcert
}

export function getActiveConcerts (allConcerts) {
  //

  // eslint-disable-next-line no-array-constructor
  var activeConcert = new Array()

  if (allConcerts && allConcerts.length > 0) {
    allConcerts.map((concert, i) => {
      var info = JSON.parse(concert.info)
      var id = concert.id
      if (info.status_concert === STATUS_CONCERT_ACTIVE[0]) {
        concert = info
        concert.id = id

        activeConcert.push(concert)
      }
    })
  }

  return activeConcert
}

export function updateConcert (state, id) {
  var result = false
  state.m = ''
  state.point = ''
  state.selectedOption = ''
  var pc = JSON.stringify(state)
  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/updateconcert',
    data: {
      concert: pc,
      id: id
    }
  })
    .done(function (data) {
      result = data
    }).fail(function (jqXHR, textStatus) {

    })

  return result
}
export function saveConcert (state) {
  var result = false
  state.m = ''
  state.point = ''
  state.selectedOption = ''

  var pc = JSON.stringify(state)
  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/saveconcert',
    data: { concert: pc }
  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {

    })

  return result
}
export function getConcertTemplate (state) {
  var result = ''

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: false,
    url: '/api/concerttmpl'

  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function addPointToConcert (pointList, point) {
  var list = pointList
  // eslint-disable-next-line no-array-constructor
  if (!list) { list = new Array() }

  list.push(
    point)
  return list
}
export function addMusicianToConcert (musicianConcertList, idInMusiciansList, name) {
  var list = musicianConcertList
  // eslint-disable-next-line no-array-constructor
  if (!list) { list = new Array() }

  list.push(
    {
      id: idInMusiciansList,
      name: name
    })
  return list
}
export function removeMisicianFromConcert (musicianConcertList, id) {
  // alert(id)
  var list = musicianConcertList
  list.splice(id, 1)

  return list
}
