import React, { Component } from 'react'

import { withRouter } from 'react-router'
import ConcertList from '../forms/ConcertList'
import StepCreateDate from '../constructorconcert3/StepCreateDate'
import StepCreatePlace from '../constructorconcert3/StepCreatePlace'
import StepAddedMusicianToConcert from '../constructorconcert3/StepAddedMusicianToConcert'
import StepAddedPointToConcert from '../constructorconcert3/StepAddedPointToConcert'
import StepSave from '../constructorconcert3/StepSave'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'
import { withLogin } from '../../provider/LoginProvider'
class AdminConcert3 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      step: 0,
      concertlist: null
    }
  };

  componentDidMount () {
    var self = this
    var postconcertlist = this.props.allconcertlist
    postconcertlist.done(function (concertlist) {
      // var allconcertlist = getAllConcertListParce(concertlist)

      self.setState({
        concertlist: concertlist
      })
    })
  }

  createConcert (e) {
    this.setState({
      step: this.props.stepsCreate[1],
      date: ''
    })
  }

  render () {
    return (
      <div>
        {this.props.user != null && this.props.user.role === 'admin'
          ? <div className="range range-xs-center">
            <div className="cell-lg-12">

              <div className="offset-top-50 offset-md-top-90 event">
                <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                  <div className="tab-pane fade active in post-event-type-3" id="tab1" role="tabpanel">
                    <div className="range post-event-item" >
                      <ConstructorProviderConsumer>
                        {({ step, createConcert }) => (
                          <div className="cell-md-3 text-md-left" >
                            <div className="post-event-inset">

                              <p className="post-event-author small">

                                {step === 0
                                  ? <a className="btn btn-primary btn-xs"
                                    onClick={createConcert} >Создать концерт</a>
                                  : ''
                                }

                              </p>
                            </div>
                            <div className="post-event-inset">

                              {/* Добавляем дату */}
                              {step > this.props.stepsCreate[0]
                                ? <StepCreateDate /> : ''}

                              {/* Добавляем место */}
                              {step > this.props.stepsCreate[1]
                                ? <StepCreatePlace />
                                : ''}

                              {/* Добавляем артиста */}
                              {step > this.props.stepsCreate[2]

                                ? <StepAddedMusicianToConcert />

                                : ''}

                              {/* Добавляем план концерта */}
                              {step > this.props.stepsCreate[3]
                                ? <StepAddedPointToConcert />
                                : ''}

                              <StepSave />
                            </div>
                          </div>
                        )}
                      </ConstructorProviderConsumer>
                      <div className="cell-md-9 text-md-left" >
                        {this.state.concertlist ? this.state.concertlist.map((concert, i) =>
                          <ConcertList
                            key={i}
                            concert={concert}

                          />
                        ) : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : this.props.history.push('/')}
      </div>

    )
  }
}

function mapStateToProps (state) {
  return {

    // concertlist:state.ConcertReducer.allconcertlist,
    allconcertlist: state.ConcertReducer.concertlist,
    concertconstructor: state.ConcertReducer.concertconstructor,

    stepsCreate: state.ConcertReducer.stepsCreate
  }
}

/*  function mapDispatchToProps  (dispatch)  {
  return {
      getConcertTemplate:()=>{

          return dispatch(getConcertTemplate());
        },
        addConcertDate:(step)=>{

          return dispatch(addConcertDate(step));
        },

   }
}  */

export default withLogin(connect(mapStateToProps)(withRouter(AdminConcert3)))

AdminConcert3.propTypes = {
  allconcertlist: PropTypes.any,
  history: PropTypes.any,
  stepsCreate: PropTypes.any,
  user: PropTypes.any
}
