import React, { Component } from 'react'
import { STATUS_CONCERT, STATUS_CONCERT_AWAIT, STATUS_CONCERT_ACTIVETAB } from '../const/Const'
import PropTypes from 'prop-types'
import DialogModal from '../../common/modalform/DialogModal'
import { connect } from 'react-redux'

class StatusConcert extends Component {
  constructor (props) {
    super(props)
    // this.handleOptionChange.bind(this)
    this.state = {
      status_concert: this.props.status_concert ? this.props.status_concert : STATUS_CONCERT_AWAIT[0],
      openDialog: false,
      concertlist: null
    }
  }

  componentDidMount () {
    var self = this
    var post = this.props.allconcertlist
    post.done(function (concertlist) {
      self.setState({
        concertlist: concertlist
      })
    })
  }

  getActiveTabConcerts (allConcerts, validatefield, idConcert) {
    var activeTabConcert = []

    if (allConcerts && allConcerts.length > 0) {
      allConcerts.map((concert, i) => {
        var info = JSON.parse(concert.info)
        var id = concert.id
        if (info.status_concert === validatefield && id !== idConcert) {
          concert = info
          concert.id = id

          activeTabConcert.push(concert)
        }
      })
    }

    return activeTabConcert
  }

  handleOptionChange (e) {
    if (e.target.value === STATUS_CONCERT_ACTIVETAB[0]) {
      var call = this.getActiveTabConcerts(this.state.concertlist, STATUS_CONCERT_ACTIVETAB[0],
        this.props.id_concert)
      if (call && call != null && call.length > 0) {
        this.setState({
          text: 'ActiveTab-ближайший концерт уже назначен:' + call[0].date,
          textresult: 'Для переназначения, сначало надо его снять с ActiveTab ',
          openDialog: true
        })

        return false
      }
    }

    this.props.onSetStatusConcert(e.target.value)
    this.setState({
      status_concert: e.target.value,
      text: 'Голосование',
      textresult: 'Закрыто',
      openDialog: false
    })
  }

  onCloseDialog () {
    this.setState({

      openDialog: false
    })
  }

  render () {
    const style = {
      padding: '5px'
    }
    return (
      <div className="range" >
        {STATUS_CONCERT.map((sc, key) =>
          <label key={key} style={style}>
            <input type="radio" value={sc[0]}
              checked={this.state.status_concert === sc[0]}
              onChange={this.handleOptionChange.bind(this)} />
            {sc[1]}
          </label>
        )}
        <DialogModal text={this.state.text}
          textresult={this.state.textresult}
          openDialog={this.state.openDialog}
          onClose={this.onCloseDialog.bind(this)} />
      </div>
    )
  }
}
function mapStateToProps (state) {
  return {

    allconcertlist: state.ConcertReducer.concertlist

  }
}

export default connect(mapStateToProps)(StatusConcert)

StatusConcert.propTypes = {
  status_concert: PropTypes.any,
  onSetStatusConcert: PropTypes.func,
  concertlist: PropTypes.array.isRequired,
  allconcertlist: PropTypes.any,
  done: PropTypes.func,
  id_concert: PropTypes.string
}
