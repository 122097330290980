import React, { Component } from 'react'
import { getUrlVideo, gettocken } from '../state/YDiskState'
import { NO_VIDEO } from '../constants/StreamTypes'
import PropTypes from 'prop-types'

export default class YaDiscPlayer extends Component {
  constructor (props) {
    super(props)
    this.state = {

      token: document.location.hash ? /access_token=([^&]+)/.exec(document.location.hash)[1] : 'AQAAAAABED2kAAWZv96Llt9Rn04zs83Cz-opfEg',
      redirect_uri: 'http://molokomuse.ru/ydiskplayer',
      videofile: this.props.videofile,
      stream_server: this.props.stream_server
    }
  }

  componentDidMount () {
    var self = this
    if (this.state.token !== 'AQAAAAABED2kAAWZv96Llt9Rn04zs83Cz-opfEg') {
      var gettoken = gettocken(this.state.redirect_uri)
      gettoken.done(function (res) {
        console.log('>>>>ttoken take')
      })
    } else {
      if (this.state.videofile !== NO_VIDEO) {
        var get = getUrlVideo(this.state.token, this.state.stream_server + '?path=' + encodeURIComponent(this.state.videofile))
        get.done(function (map) {
          console.log(map)
          self.setState({
            videosrc: map.href

          })
        })
      }
    }
  }

  render () {
    return (
      <div className="modal-dialog__content-inner">
        <h4>{this.props.title}</h4>
        <div className="embed-responsive embed-responsive-16by9" style={{ height: 'auto' }}>
          {this.state.videosrc && this.state.videofile !== NO_VIDEO ? <video controls src={this.state.videosrc} /> : 'Нет доступа к видео'}
        </div>
      </div>
    )
  }
}
YaDiscPlayer.propTypes = {
  videofile: PropTypes.any,
  title: PropTypes.any,
  stream_server: PropTypes.any
}
