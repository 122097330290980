import MuseReducer from './MuseReducer'
import MusicianReducer from './MusicianReducer'
import ConcertReducer from './ConcertReducer'
import RulesReducer from './RulesReducer'
import AdminReducer from './AdminReducer'
// import LoaderReducer from './loaderreducer'
// import NavPanelOnMapReduver from './NavPanelOnMapReducer'
import { combineReducers } from 'redux'

const reducer = combineReducers({
  MuseReducer, MusicianReducer, ConcertReducer, RulesReducer, AdminReducer
  // LoaderReducer,NavPanelOnMapReduver
})
export default reducer
// export default TripPointReducer;
