
export const russian = {
  MAIN: {
    HEADING: 'Сразу 4 группы  сразятся в музыкальном баттле !!!!!!!!!!'
  },
  MENU: {
    NAMESITE: 'Инди Путешествие',
    MATRIX: 'Матрица',
    YOUTRIP: 'Твои путешествия',
    HOWITWORKS: 'Как это работает'
  },
  MATRIX: {
    HEADER: 'Матрица Indie Trip приключений',
    VIEWTRIP: 'Посмотреть свои путешествия'
  },
  BUTTON: {
    ADDPOINT: 'Добавить пункт',
    SAVE: 'Сохранить',
    SEARCH: 'Поиск',
    LOAD: 'Загрузить'
  },
  MATRIXFORM: {
    AUTHOR: 'Автор',
    HISTORY: 'История путешествия'
  },
  FORMS: {
    SAVEROUTE: {
      SAVETRIP: 'Сохранить созданое путешествие',
      ENTEREMAIL: 'Введите e-mail',
      ENTERNAMETRIP: 'Введите название путешествия',
      MAKEITPUBLIC: 'Сделать публичной'
    }
  },
  ERROR: {
    INCORRECTEMAILFIELD: 'Некорректно введено поле Email',
    DELETE: 'Произошла ошибка при удалении',
    SAVE: 'Произошла ошибка при сохранении',
    AUERO: 'При поиске авиамаршрута произовшла ошибка'
  },
  INFO: {
    FILLEMAIL: 'Поле <b>email</b> должно быть заполнено',
    MAPNOTCREATED: 'Карта и путешествие не созданы',
    FORMATEMAIL: 'Формат Email не верный',
    ENTERTRIPNAME: 'Введите название путешествия',
    ROUTESAVED: 'Маршурт сохранен',
    ERRORSAVEDROUTE: 'Произошла ошибка при сохранении маршрута. Попробуй еще раз.',
    PUBLICATIONROUTE: 'Tвой маршрут опубликуется в Матрице путешествий и ты сможешь рассказать о нем людям и поделиться своими впечатлениями',
    SELECTPOINT: 'Необходимо выбрать пункт назначения',
    FOOTER: 'Инди путешествие: Территория свободы. Построй своё путешествие сам ',
    WAITROUTE: 'Идет построение маршрута. \n Это может занять некоторое время.',
    WAITHOTEL: 'Идет поиск отелей',
    HISTORYDELETE: 'История твоего путешествия удалена',
    TRIPSAVE: 'История твоего путешествия сохранена',
    AERO: 'Авиасообщение не найдено'

  },
  MOBILE: {
    OPENPANEL: 'Открыть панель управления',
    CLOSEPANEL: 'Закрыть панель управления'
  },
  MAIL: {
    WRITETOUS: 'Написать нам'
  },
  NOTTRANSLATE: { NOTTRANSLATE: 'Нет перевода' }
}
