import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VOTE_ALGORITM, VOTE_ALGORITM_SIMPLE } from '../const/Const'

export default class AlgoritmVoteConcert extends Component {
  constructor (props) {
    super(props)
    this.state = {
      algoritm: this.props.algoritm ? this.props.algoritm : VOTE_ALGORITM_SIMPLE[0]
    }
  }

  handleOptionChange (e) {
    this.props.onSetAlgoritmVoteConcert(e.target.value)
    this.setState({
      algoritm: e.target.value

    })
  }

  render () {
    const style = {
      padding: '5px'
    }
    return (
      <div className="range" >
        {VOTE_ALGORITM.map((sc, key) =>
          <label key={sc[0]} style={style}>
            <input type="radio" value={sc[0]}
              checked={this.state.algoritm === sc[0]}
              onChange={this.handleOptionChange.bind(this)} />
            {sc[1]}
          </label>
        )}

      </div>
    )
  }
}
AlgoritmVoteConcert.propTypes = {

  onSetAlgoritmVoteConcert: PropTypes.func.isRequired,
  algoritm: PropTypes.string

}
