import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class RoleForm extends Component {
  render () {
    return (
      <div className="range post-event-item">
        <div className="cell-md-2 text-md-left">
          <div className="post-event-inset">
            <h4 className="post-event-time"><a href={`/mus?id=${this.props.role.id}`}>{this.props.role.role}</a></h4>
          </div>
        </div>
      </div>
    )
  }
}
RoleForm.propTypes = {
  role: PropTypes.any
}
