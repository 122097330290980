// 0-code, 1- title
export const STATUS_CONCERT_ACTIVE = ['STATUS_CONCERT_ACTIVE', 'Активный (продажа билетов)']
export const STATUS_CONCERT_ARCHIVE = ['STATUS_CONCERT_ARCHIVE', 'Архивный']
export const STATUS_CONCERT_AWAIT = ['STATUS_CONCERT_AWAIT', 'Ожидает публикации']
export const STATUS_CONCERT_ACTIVETAB = ['STATUS_CONCERT_ACTIVETAB', 'Активный и выделенный(продажа билетов)']
export const STATUS_CONCERT_ACTIVE_AWAIT_SALE = ['STATUS_CONCERT_ACTIVE_AWAIT_SALE', 'Активный скоро в продаже']

export const STATUS_CONCERT = [
  STATUS_CONCERT_AWAIT,
  STATUS_CONCERT_ACTIVE,
  STATUS_CONCERT_ARCHIVE,
  STATUS_CONCERT_ACTIVETAB,
  STATUS_CONCERT_ACTIVE_AWAIT_SALE
]

export const VOTE_ALGORITM_SIMPLE = ['VOTE_ALGORITM_SIMPLE', 'Простой алгоритм']
export const VOTE_ALGORITM_2_GROUP = ['VOTE_ALGORITM_2_GROUP', 'Голосование не менее чем за две команды']

export const VOTE_ALGORITM = [
  VOTE_ALGORITM_SIMPLE,
  VOTE_ALGORITM_2_GROUP
]
