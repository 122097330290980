import React, { Component } from 'react'
import { VOTE_ALGORITM_SIMPLE } from '../common/const/Const'
import PropTypes from 'prop-types'

export default class VoteControl extends Component {
  constructor (props) {
    super(props)
    this.state = {
      count: this.props.num
    }
  }

  onMinus (e) {
    var count = this.state.count
    if (count === 0) return
    var num = this.state.count - 1
    this.setState({
      count: num
    })
    this.props.onClick(this.props.idmus, num, false)
  }

  is2Algoritm (count) {
    if (this.props.algoritm === VOTE_ALGORITM_SIMPLE[0]) return false
    var so = this.props.selectedOption
    var algoritm = 0
    var musician = so[this.props.idmus]
    for (var i = 0; i < so.length; i++) {
      if (so[i].value === 0) { algoritm = algoritm + 1 }
    }
    if ((so.length - algoritm) === 1 && musician.value === this.props.number_of_votes - 1 && musician.value === count - 1 && this.props.number_of_votes > 1) {
      return true
    } else return false

    /*  for (var i=0; i < so.length;i++){
             if (so[i].value===0)
                 algoritm=algoritm+1

         }
         if (algoritm === this.props.number_of_votes-1 &&
             count===this.props.number_of_votes){

             return true
         }
         else return false */
  }

  onPlus (e) {
    var num = this.state.count + 1
    var is2alg = this.is2Algoritm(num)
    if (is2alg) {
      this.props.onClick(this.props.idmus, num - 1, is2alg)
      return true
    }

    if (this.state.count >= this.props.number_of_votes) return
    if (this.props.number_of_votes <= this.props.countVote) return

    if (!is2alg) {
      this.setState({
        count: num
      })
    }
    this.props.onClick(this.props.idmus, num, is2alg)
  }

  render () {
    return (
      <div className="range range-condensed range-md-center ">
        <div className="cell-xs-6 cell-md-6 cell-md-preffix-0 cell-xl-6">
          <h3 className="post-event-time">{this.props.nameMuse}</h3>
        </div>
        <div className="cell-xs-6 cell-md-6 cell-xl-6 ">
          <ul className="list-inline">
            {this.props.open_vote
              ? <li >

                <a className="btn btn-primary btn-xs animateItem fa-minus" onClick={this.onMinus.bind(this)} data-animation="fadeInUp" data-delay="0.2" data-duration="1">

                </a>

              </li>
              : ''}
            <li>
              <a className="nobtm btn btn-xs animateItem "
                data-animation="fadeInUp" data-delay="0.2" data-duration="1">
                <span className="countdown-section ">
                  <span className="text-primary text-md-center countdown-digit-vu text-uppercase text-bold text-vu">{this.state.count}</span>
                </span>
              </a>
              {/*  <span className="countdown-section mar-vu">
                            <span className="text-primary text-md-center countdown-digit-vu">{this.state.count}</span>
                        </span> */}

            </li>
            {this.props.open_vote
              ? <li>

                <a className="btn btn-primary btn-xs animateItem fa-plus" onClick={this.onPlus.bind(this)} data-animation="fadeInUp" data-delay="0.2" data-duration="1">

                </a>

              </li>
              : ''}
          </ul>
        </div>

      </div>
    )
  }
}
VoteControl.propTypes = {
  num: PropTypes.string,
  open_vote: PropTypes.any,
  value: PropTypes.string,
  number_of_votes: PropTypes.number,
  onClick: PropTypes.any,
  idmus: PropTypes.number,
  countVote: PropTypes.number,
  nameMuse: PropTypes.string,
  algoritm: PropTypes.string,
  selectedOption: PropTypes.array
}
