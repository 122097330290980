import React, { Component } from 'react'
import { connect } from 'react-redux'
import { STATUS_CONCERT_ACTIVE, STATUS_CONCERT_ACTIVETAB } from '../const/Const'
import { reload } from '../../actions/ConcertAction'
import PropTypes from 'prop-types'
import { getAllConcerts } from '../../state/ConcertState'
class ButtonTicket extends Component {
  constructor (props) {
    super(props)
    this.state = {
      concertlist: null

    }
  }

  componentDidMount () {
    var self = this
    var post = this.props.allconcertlist
    post.done(function (concertlist) {
      self.setState({
        concertlist: getAllConcerts(concertlist)

      })
    })
  }

  reload () {
    this.props.reload()
  }

  oneBtn (t, btnEvent, btnToken, pay) {
    if (t === 1 && pay === 0) {
      return (

        <a type="button" className={this.props.class + ' animateItem'} style={this.props.style}
          data-tc-event={btnEvent}
          data-tc-token={btnToken}>
                        Купить билет</a>

      )
    } else if (t > 1 || (t === 1 && pay > 0)) {
      return (

        <a type="button" className={this.props.class + ' animateItem'} style={this.props.style}
          onClick={this.reload.bind(this)}
          href="#" data-toggle="modal" data-target="#modalticket"
          data-animation="fadeInUp" data-delay="0.2" data-duration="1">
                        Купить билет</a>

      )
    } else if (t === 0 && (pay > 0)) {
      return (

        <a type="button" className={this.props.class + ' animateItem'} style={this.props.style}
          onClick={this.reload.bind(this)}
          data-toggle="modal" data-target="#modalticket" data-animation="fadeInUp" data-delay="0.2" data-duration="1">
                        Купить голос </a>

      )
    } else {
      return (

        <a type="button" className={this.props.class + ' animateItem'} style={this.props.style}
          data-animation="fadeInUp" data-delay="0.2" data-duration="1">
                        Sold out </a>

      )
    }
  }

  render () {
    var t = 0
    var btnEvent = ''
    var btnToken = ''
    var pay = 0
    if (this.state.concertlist && this.state.concertlist.length > 0) {
      for (let i = 0; i < this.state.concertlist.length; i++) {
        if (this.state.concertlist[i] && this.state.concertlist[i].buttonticket &&
                    (this.state.concertlist[i].status_concert === STATUS_CONCERT_ACTIVETAB[0] ||
                        this.state.concertlist[i].status_concert === STATUS_CONCERT_ACTIVE[0])) {
          t = t + 1
          btnEvent = this.state.concertlist[i].buttonticket.split('&')[0]
          btnToken = this.state.concertlist[i].buttonticket.split('&')[1]
        }
      }
    }
    if (this.state.concertlist && this.state.concertlist.length > 0) {
      for (let i = 0; i < this.state.concertlist.length; i++) {
        if (this.state.concertlist[i].payAddingVote) {
          pay = pay + 1
        }
      }
    }
    return (

      this.oneBtn(t, btnEvent, btnToken, pay)

    )
  }
}

function mapStateToProps (state) {
  return {

    allconcertlist: state.ConcertReducer.concertlist

  }
}
function mapDispatchToProps (dispatch) {
  return {

    reload () {
      return dispatch(reload())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonTicket)

ButtonTicket.propTypes = {
  class: PropTypes.string.isRequired,
  reload: PropTypes.func,
  concertlist: PropTypes.array,
  allconcertlist: PropTypes.any,
  done: PropTypes.func,
  style: PropTypes.string
}
