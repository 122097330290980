/* eslint-disable react/prop-types */

import React, { Component } from 'react'
import { ITLanguageConsumer } from './ITLanguageProvider'

import { dictionary } from './Dictionary'

export function getText (language, lang, text) {
  var result = 'NOTTRANSLATE.NOTTRANSLATE'
  console.log('Translate->' + text)
  console.log(language)
  if (language === 0) lang = dictionary.english
  console.log(lang)
  console.log('<<---')
  if (!text) text = result
  var at = text.split('.')
  for (var i = 0; i < at.length; i++) {
    lang = lang[at[i]]
  }

  return lang
}
export class TranslatableText extends Component {
  render () {
    var t = this.props.text
    return (
      <ITLanguageConsumer>
        {({ language, langcode }) => (
          <span dangerouslySetInnerHTML={{ __html: getText(language, dictionary[language], t) }}>

          </span>

        )}
      </ITLanguageConsumer>

    )
  }
}
export const TranslatableText1 = props => (
  <ITLanguageConsumer>
    {({ language }) =>
      getText(dictionary[language], props.text)

    }
  </ITLanguageConsumer>
)

export class TranslatableIputText extends Component {
  render () {
    var t = this.props.text
    return (
      <ITLanguageConsumer>
        {({ language }) => (
          <input type={this.props.type ? this.props.type : 'text'}
            placeholder={getText(dictionary[language], t)} id={this.props.id}
            value={this.props.value}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : () => {}}
          />

        )}
      </ITLanguageConsumer>

    )
  }
}

export class TranslatableIputSubmit extends Component {
  render () {
    var t = this.props.text
    return (
      <ITLanguageConsumer>
        {({ language }) => (
          <input
            type="submit" className="button" value={getText(dictionary[language], t)}
            onClick={this.props.onClick}
          />

        )}
      </ITLanguageConsumer>

    )
  }
}
