import React, { Component } from 'react'
import ButtonTicket from '../../common/component/ButtonTicket'
export default class Rules extends Component {
  componentWillMount () {

  }

  render () {
    return (
      <section className="section parallax-container" data-parallax-img="/images/city-1920-1276.png">
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <div className="cell-lg-10">
                <h1 className="text-bold">НАШИ ПРАВИЛА</h1>
                <p>Организаторы оставляют за собой право внесения изменения в формат мероприятия.</p>
                <div className="offset-top-50 offset-md-top-90 event">
                  <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                    <div className="tab-pane fade active in post-event-type-3" id="tabRules" role="tabpanel">
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Где купить </h3>
                            <p className="post-event-author">билет на баттл?</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="buy" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li >По кнопке&nbsp;&nbsp;  <ButtonTicket class="btn btn-primary btn-xs" />
                                  {/* <a type="button" className="btn btn-primary btn-xs"
                  data-tc-event="5c485edfa3a2df000cc494bc"
                  data-tc-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNWI4NmM4NWJhOThlODkwMDBjMmNiNDIxIn0.nSwwn5cCnKB9eYFlakTvmvir8rdP_XryuMdavxXR-rE">

                    Купить билет </a> */}&nbsp;&nbsp; и...
                                </li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">Купить билеты на баттл-концерт МОЛОКОMUSE можно:
                                  <ul>
                                    <li>на нашем официальном сайте
                                      <a className="text-dark" href="http://www.molokomuse.ru" target="_blank" rel="noreferrer noopener">МОЛОКОMUSE</a></li>
                                    <li>на сайте <a className="text-dark" href="https://ponominalu.ru/event/molokomuse" target="_blank" rel="noreferrer noopener">
                                      Ponominalu
                                    </a>
                                    </li>
                                    {/* <!--  <li>на сайте <a className="text-dark" href="https://afisha.yandex.ru/moscow" target="_blank" rel="noreferrer noopener">Яндекс Афиша</a></li>
                                      <li>на сайте <a className="text-dark" href="https://msk.kassir.ru" target="_blank" rel="noreferrer noopener">KASSIR.ru</a></li>
                                      --> */}
                                    <li>на сайте клуба <a className="text-dark" href="http://glastonberry.ru/events/molokomuse/" target="_blank" rel="noreferrer noopener">Glastonberry</a></li>
                                  </ul>(список может пополнятся)
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Какой формат </h3>
                            <p className="post-event-author">баттл-концерта?</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="format" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li>1 концерт - 4 группы...</li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">МОЛОКОMUSE проходит 2 раза в год: весной и осенью. В рамках весенней серии пройдут 3 баттл-концерта.
                                  На каждом концерте 4 группы по очереди выступают вживую перед гостями.
                                  Длительность выступления одной группы - 30 минут.
                                  Музыканты сами выбирают репертуар.
                                  После выступления всех 4х групп открывается зрительское голосование, которое длится 30 минут.
                                  Победитель баттла определяется по наибольшему количеству зрительских голосов.
                                    Все голоса, отданные гостями за группы, конвертируются в деньги, которые передаются музыкантам в конце вечера. 1 голос - 50 рублей. В время голосования музыканты играют зажигательный ДЖЭМ! На каждом концерте будут присутствовать эксперты из мира музыки, которые прокомментируют каждое выступление участников. Прямая трансляция концерта. Фото и видеосъемка.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Правила </h3>
                            <p className="post-event-author">голосования</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="rules" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li>1 билет - 3 голоса...</li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">
                                  Голосование за музыкантов открывается после окончания выступления последней группы-участника и завершается по сигналу ведущего через полчаса. Голосование осуществляется через сайт www.molokomuse.ru. При покупке билета гость получает 3 голоса, которые может распределить между группами на свой вкус. Ссылка на страницу для голосования придет на почту или по СМС в ДЕНЬ КОНЦЕРТА. Во время концерта также можно приобрести дополнительные голоса в поддержку музыкантов. Если гость по окончании вечера не проголосовал ни за одну из групп, его голоса, полученные с покупки билета, а также купленные дополнительные голоса, будут распределены между группами в пропорциональном соотношении.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Когда и где?</h3>
                            <p className="post-event-author">КАК ДОБРАТЬСЯ?</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="whenwhere" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li>Глуб Glastonberry...</li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">Все концерты в рамках весенней серии концертов МОЛОКОMUSE проходят в клубе Glastonberry, Который находится по адресу:
                                  <br className="veil reveal-md-inline-block" />Москва, м. Дубровка, ул. 1-я Дубровская 13А, стр. 1
                                  <br className="veil reveal-md-inline-block" />Телефон: <a href="tel:+7-495-642-45-43"></a> +7-495-642-45-43
                                  <br className="veil reveal-md-inline-block" />
                                  <a className="text-dark" href="http://glastonberry.ru/contacts/" target="_blank" rel="noreferrer noopener">Glastonberry</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Кто участвует </h3>
                            <p className="post-event-author">в баттле?</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="who" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li>Группы разных жанров</li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">В баттле участвуют музыкальные группы любого жанра после успешного прохождения отборочного тура.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="range post-event-item">
                        <div className="cell-md-4 text-md-right">
                          <div className="post-event-inset">
                            <h3 className="post-event-time">Как принять</h3>
                            <p className="post-event-author">УЧАСТЬЕ В БАТТЛЕ?</p>
                          </div>
                        </div>
                        <div className="cell-md-8 offset-top-20 offset-md-top-0">
                          <div className="post-event-info">

                            <div id="applications" className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
                              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                                <li>Прием заявок открыт!</li>
                              </ul>
                              <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">
                                <div className="small">Для участия в музыкальном баттле необходимо заполнить и отправить заявку через сайт&nbsp;&nbsp;&nbsp;
                                  <a href="http://molokomuse.ru/regmusic">http://molokomuse.ru</a>&nbsp;&nbsp;&nbsp;
                                  В заявке необходимо указать название группы, контактное лицо, ссылки на соц.сети, ссылки на аудио и видеоматериалы и электронную почту и номер телефона для связи.
                                  <br className="veil reveal-md-inline-block" /><span className="text-primary">Внимание!
                                    <br className="veil reveal-md-inline-block" />Прием заявок на участие в концертах осенней серии 2019 года открыт.</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
