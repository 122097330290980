import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class VideoPlayer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      video: this.props.videos ? this.props.videos[0] : null,
      videos: this.props.videos,
      i: 0,
      autoplay: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.videos !== prevState.videos) {
      return {
        video: nextProps.videos[0],
        videos: nextProps.videos
      }
    } else return null
  }

  setVideo (video, i, e) {
    this.setState({
      video: video,
      i: i
    })
  }

  onEnded (e) {
    if (this.state.videos) {
      this.setState({
        video: (this.state.i + 1) < this.state.videos.length ? this.state.videos[this.state.i + 1] : this.state.videos[0],
        i: (this.state.i + 1) < this.state.videos.length ? this.state.i + 1 : 0,
        autoplay: 'autoplay'
      })
    }
  }

  render () {
    const style = {
      width: 'auto'
    }
    const stylePaginator = {
      padding: '5px',
      dispaly: 'inline block'
    }
    const list = []
    if (this.props.paginator && this.state.videos) {
      for (var i = 0; i < this.state.videos.length; i++) {
        list.push(
          <span key={i} >
            <a style={stylePaginator} className="btn btn-info animateItem btn-xs" href="#"
              onClick={this.setVideo.bind(this, this.state.videos[i], i)} >{i}</a>

                        &nbsp;
          </span>)
      }
    }
    return (
      <div>
        <div className="range range-xs-center offset-top-30 offset-md-top-30">

          <div className="cell-md-12" style={{ padding: '10px' }}>
            <video style={style} src={this.state.video
              ? decodeURI(this.props.src + this.state.video)
              : decodeURI(this.props.src)} controls onEnded={this.onEnded.bind(this)}
            autoPlay={this.state.autoplay}
            />
          </div>

        </div>
        <div className="range range-xs-center">
          <div className="cell-md-12" style={{ padding: '10px' }}>

            {list}

          </div>
        </div>
      </div>
    )
  }
}
VideoPlayer.propTypes = {

  videos: PropTypes.any,
  paginator: PropTypes.any,
  src: PropTypes.string

}
