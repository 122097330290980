import * as types from '../constants/ActionTypes'

import { initialMusician, setMuseField } from '../state/MusicianState'
// import update from 'react-addons-update';

export default function MusicianReducer (state = initialMusician, action) {
  switch (action.type) {
    case types.SET_MUSE_FIELD:
      return {
        ...state,
        musicianstate: setMuseField(action.field, action.value, action.id)
      }
    default:

      return state
  }
}
