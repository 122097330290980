import React, { Component } from 'react'
export default class Counters extends Component {
  render () {
    return (

      <section className="section parallax-container context-dark" data-parallax-img="/images/bg-02-1920x1281.jpg">
        <div className="parallax-content">
          <section className="section-98">
            <div className="shell-wide">
              <div className="range">
                <div className="cell-sm-8 cell-sm-preffix-2 cell-md-12 cell-md-preffix-0">
                  <div className="range range-xs-center">
                    <div className="cell-lg-10">
                      {/* <!-- <h1 className="text-bold center-block">Ты определяешь, какую музыку, мы будем слушать завтра!</h1> --> */}
                      <p>
                        Ты определяешь, какую музыку, мы будем слушать завтра!
                      </p>
                    </div>
                  </div>
                  {/*  <!-- <img className="img-responsive center-block offset-top-65" src="<?php echo get_template_directory_uri() ?>/images/title.png" alt=""> -->

<!--                       <div className="cell-md-3 cell-sm-6 counter-plus"><span className="text-extra-big text-primary counter text-uppercase text-bold" data-step="1500" data-from="0" data-to="20"></span>
                        <p className="small text-bold text-spacing-120">КОМАНД</p>
                      </div>
                      <div className="cell-md-3 cell-sm-6 offset-top-60 offset-sm-top-0"><span className="text-extra-big text-primary counter text-uppercase text-bold" data-step="1500" data-from="0" data-to="5"></span>
                        <p className="small text-bold text-spacing-120">КОНЦЕРТОВ</p>
                      </div>
                      <div className="cell-md-3 cell-sm-6 offset-top-60 offset-md-top-0"><span className="text-extra-big text-primary counter text-uppercase text-bold" data-step="1500" data-from="0" data-to="2"></span>
                        <p className="small text-bold text-spacing-120">СТАДИИ</p>
                      </div>
                      <div className="cell-md-3 cell-sm-6 offset-top-60 offset-md-top-0 counter-plus"><span className="text-extra-big text-primary counter text-uppercase text-bold" data-step="1500" data-from="0" data-to="1800"></span>
                        <p className="small text-bold text-spacing-120">БИЛЕТОВ</p>
                      </div>
 -->                     */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

    )
  }
}
