export function getVotingByBarCode (code, type) {
  // var result = null;

  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: true,
    url: '/api/startvote',
    data: {
      code: code,
      type: type
    }
  })
}
export function pagination () {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: false,
    url: '/api/pagination'

  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function getvotesbyparam (offset, rowCount) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/getvotesbyparam',
    data: {
      offset: offset,
      row_count: rowCount
    }

  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function getAllVotes () {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/allvotes'

  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}

export function getVotingByConcertId (idConcert) {
  // var result = null;

  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: true,
    url: '/api/votesbyconcert',
    data: {
      id_concert: idConcert
    }
  })
  /*  .done(function( data ) {
       result = data;
       // result
       //var route = JSON.stringify(data.route)
     }).fail(function( jqXHR, textStatus ) {
        result =jqXHR
     });

    return result; */
}
export function getMusicianByConcert (idConcert) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/concertbyid',
    data: {
      id_concert: idConcert
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}

export function generateQR (idConcert, source, openVote, countGenerate, numberOfVotes, type, path) {
  var result = null
  /**
     * typeCollection:[{value:"ticket",label:"Билет"},{value:"addvote",label:"Доп голос"}]
     */

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: false,
    url: '/api/generatecode',
    data: {
      id_concert: idConcert,
      source: source,
      open_vote: openVote,
      countGenerate: countGenerate,
      number_of_votes: numberOfVotes,
      type: type,
      path: path
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function isopenvote (idConcert) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/isopenvote',
    data: {
      id_concert: idConcert
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function getNumText (num) {
  var t = ['', 'раз', 'раза', 'раза', 'раза', 'раз', 'раз', 'раз']

  return t[num]
}
export function onSendCodeToMail (idConcert) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: false,
    url: '/api/sendcodetomail',
    data: {
      id_concert: idConcert
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function onOpenVoteByCode (code, openVote) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/onstartvotebycode',
    data: {
      code: code,

      open_vote: openVote
    }
  })
    .done(function (data) {
      // if (data>0)
      result = { data: data, open_vote: openVote }
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = { error: textStatus, open_vote: openVote }
    })

  return result
}
export function onOpenVote (idConcert, openVote) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/onstartvote',
    data: {
      id_concert: idConcert,

      open_vote: openVote
    }
  })
    .done(function (data) {
      // if (data>0)
      result = { data: data, open_vote: openVote }
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = { data: textStatus, open_vote: openVote }
    })

  return result
}
export function vote (code, selectedOption) {
  var result = null

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/vote',
    data: {
      code: code,
      selectedOption: JSON.stringify(selectedOption)
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
export function arrayBufferToBase64 (buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
export function countVoteConcert (votes) {
  let count = 0
  votes.map((vt, i) => {
    const matrix = JSON.parse(vt.matrix_of_votes)
    matrix.map((mt, i) => {
      count = count + mt.value
    })
  })
  return count
}
export function countVoteConcertMuse (votes, idMuse) {
  let count = 0
  votes.map((vt, i) => {
    const matrix = JSON.parse(vt.matrix_of_votes)
    matrix.map((mt, i) => {
      if (idMuse === mt.museid) { count = count + mt.value }
    })
  })
  return count
}
