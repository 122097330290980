import React, { Component } from 'react'
import YaDiscPlayer from '../yandex/YaDiscPlayer'
import Dashboard from '../dashboard/Dashboard'
import Voting from '../voting/Voting2'
import { CODE_FOR_STREAM } from '../constants/StreamTypes'
import { generateQR } from '../state/VotingState'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { TYPE_STREAM, PATH_STREAM } from '../constants/VoteTypes'
import PropTypes from 'prop-types'

/**
 * два варианта как компонент с params и по ссылке с query String
 * и куки
 */

class StreamMainPanel extends Component {
  constructor (props) {
    super(props)
    /**
         * у нас есть код если мы генерим новый или берем из куков
         *
         */
    // находим источник захода как компонент (через props) или по ссылке(редирект с продажи)
    var idConcert = null
    var videofile = null
    var code = null
    var streamServer = null
    if (this.props.id_concert) {
      idConcert = this.props.id_concert
    } else if (queryString.parse(this.props.location.search).id_concert) {
      idConcert = queryString.parse(this.props.location.search).id_concert
    }
    if (this.props.videofile) {
      videofile = this.props.videofile
    } else if (queryString.parse(this.props.location.search).videofile) {
      videofile = queryString.parse(this.props.location.search).videofile
    }
    if (queryString.parse(this.props.location.search).stream_server) {
      streamServer = queryString.parse(this.props.location.search).stream_server
    }
    if (queryString.parse(this.props.location.search).code) {
      code = queryString.parse(this.props.location.search).code.split(':')[0]
    }

    var cookiecode = localStorage.getItem(CODE_FOR_STREAM) ? JSON.parse(localStorage.getItem(CODE_FOR_STREAM)) : null

    if (cookiecode != null && idConcert === cookiecode.id_concert) { // значит уже заходили с кодом
      idConcert = cookiecode.id_concert
      if (code != null && code !== cookiecode.code && idConcert) {
        // генерим новый код
        cookiecode = generateQR(idConcert, 'SITE', true, 1, 0, TYPE_STREAM, PATH_STREAM)
      }
    } else { // первый заход
      if (idConcert) { cookiecode = generateQR(idConcert, 'SITE', true, 1, 0, TYPE_STREAM, PATH_STREAM) }
    }

    this.state = {
      code: cookiecode,
      successURL: 'http://molokomuse.ru/stream',
      videofile: videofile,
      stream_server: streamServer,
      id_concert: idConcert
    }
  }

  render () {
    localStorage.setItem(CODE_FOR_STREAM, JSON.stringify(this.state.code))
    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">

        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <h5>Трансляция</h5>
            </div>
            <div className="range range-xs-center">
              <div className="cell-lg-6">
                <YaDiscPlayer
                  title="Полная видео версия первого концерта"
                  videofile={this.state.videofile}
                  stream_server={this.state.stream_server}
                />

                <Dashboard id_concert={this.state.code.id_concert} />
              </div>
              <div className="cell-lg-6">
                <Voting id_concert={this.state.code.id_concert} type={TYPE_STREAM}
                  code={this.state.code.code}
                  successURL={this.state.successURL} />

              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(StreamMainPanel)

StreamMainPanel.propTypes = {
  id_concert: PropTypes.number,
  location: PropTypes.any,
  videofile: PropTypes.any

}
