import React, { Component } from 'react'
import { withRouter } from 'react-router'
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import { getConcerImg } from '../state/GalleryState'
import PropTypes from 'prop-types'
class NoMatch extends Component {
  constructor (props) {
    super(props)
    this.state = {
      images: null
    }
  }

  componentDidMount () {
    const post = getConcerImg()
    var self = this
    post.done(function (concert) {
      var images = []
      for (var i = 1; i < concert.length; i++) {
        images.push({
          original: '/media/photo/' + concert[i],
          thumbnail: '/media/photo/' + concert[i]
        })
      }
      self.setState({
        images: images

      })
    })
  }

  render () {
    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <div className="cell-lg-10">
                <h1 className="text-bold">Такой страницы не существует</h1>
                <p><code>{this.props.location.pathname}</code></p>
                <h1 className="text-bold">Но вы можете посмотреть фотки с концертов :)</h1>
                <div className="offset-top-50 offset-md-top-90 event">
                  {this.state.images === null ? ''
                    : <ImageGallery items={this.state.images} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(NoMatch)

NoMatch.propTypes = {
  location: PropTypes.any
}
