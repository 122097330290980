import React, { Component } from 'react'

import Highlight from 'react-highlighter'
import PropTypes from 'prop-types'
import MusicianFormBody from './MusicianFormBody'
export default class MusicianForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  render () {
    return (

      <div className="range post-event-item" >

        <div className="cell-md-2 text-md-left">
          <div className="post-event-inset">
            <h4 className="post-event-time">
              <a className="btn btn-info btn-xs animateItem"
                href={`/mus?id=${this.props.muse.id}`}>
                <Highlight search={this.state.search}>{this.props.muse.name}</Highlight>

              </a></h4>

          </div>
        </div>
        <div className="cell-md-10 offset-top-20 offset-md-top-0">
          <div className="post-event-info">

            <div id={this.props.muse.name} >

              <div className="resp-tabs-container tabs-group-default offset-md-top-20" data-group="tabs-group-default">
                <MusicianFormBody
                  value={this.props.muse}
                  id={this.props.muse.id}

                  search={this.state.search}
                />

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
MusicianForm.propTypes = {
  muse: PropTypes.any
}
