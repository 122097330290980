export const initAdminSettings = {
  adminsettings: adminSettings()
}
export function adminSettings () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/adminsettings'

  })/*
     .done(function( data ) {
       result = data
     }).fail(function( jqXHR, textStatus ) {
        result = jqXHR;
     });

   return result     */
}
export function setSettings (adminsettings) {
  var result = false
  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/setsettings',
    data: {
      adminsettings: JSON.stringify(adminsettings)
    }

  })
    .done(function (data) {
      result = data
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
