import React, { Component } from 'react'
import { withRouter } from 'react-router'

class PersData extends Component {
  render () {
    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">

        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">

            <div className="range range-xs-center">
              <h4>Согласие на обработку своих персональных данных</h4>
              <div className="range">
                <div className="cell-lg-12">
                  <p>Я, Пользователь Сайта <a href="/">http://molokomuse.ru/</a>, даю согласие на&nbsp;обработку своих персональных данных свободно, своей волей и&nbsp;в&nbsp;своем интересе. Настоящее Согласие дано Администрации сайта&nbsp;&mdash; МОЛОКОMUSE.</p>
                  <p>Я, Пользователь Сайта, выражаю согласие на&nbsp;получение информации по&nbsp;электронной почте или телефону от&nbsp;Администрации Сайта.</p>
                  <b>Целью обработки персональных данных являются:</b>
                  <p>консультирование, направление информации по&nbsp;электронной почте или СМС об&nbsp;услугах и/или мероприятиях, заключение гражданско-правового договора, исполнения заключенного договора.</p>
                  <b>Объем обработки персональных данных:</b>
                  <p>фамилия, имя, отчество, псевдоним, адрес электронной почты.</p>
                  <p>Настоящее согласие предоставляется мной на&nbsp;осуществление действий в&nbsp;отношении моих персональных данных, которые необходимы для достижения указанных выше целей, включая (без ограничения) сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование персональных данных, а&nbsp;также осуществление любых иных действий, предусмотренных действующим законодательством Российской Федерации.</p>
                  <p>Персональные данные не&nbsp;передаются третьим лицам кроме случаев, предусмотренных действующим законодательством Российской Федерации.</p>
                  <p>Настоящее согласие на&nbsp;обработку персональных данных действует в&nbsp;течение неопределенного срока.</p>
                  <p>Настоящее согласие на&nbsp;обработку персональных данных может быть отозвано мною путем направления заявления в&nbsp;&laquo;Службу поддержки&raquo;.</p>
                  {/* <!-- <p>Политика обработки персональных данных размещена в&nbsp;открытом публичном доступе по&nbsp;ссылке <a href="/personal">http://molokomuse.ru/personal</a></p> --> */}
                  <p>Персональные данные Пользователей хранятся исключительно на&nbsp;электронных носителях и&nbsp;обрабатываются с&nbsp;использованием автоматизированных систем, за&nbsp;исключением случаев, когда неавтоматизированная обработка персональных данных необходима в&nbsp;связи с&nbsp;исполнением требований законодательства.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

    )
  }
}

export default withRouter(PersData)
