import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class DialogModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openDialog: false

    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps !== prevState) { return nextProps } else { return null }
  }

  onClose () {
    this.setState({
      openDialog: false

    })
    this.props.onClose()
  }

  render () {
    let style = {
      display: 'none'
    }
    const styleBlack = {
      color: 'black'
    }
    if (this.state.openDialog) {
      style = {
        display: 'block'
      }
    }
    return (
      <div className={this.state.openDialog ? 'modal fade   in' : 'modal fade'} style={style} id="dialogmodal" role="dialog">
        <div className="modal-dialog modal-dialog_custom">

          <div className="modal-dialog__inner">
            <button className="close" onClick={this.onClose.bind(this)} type="button" data-dismiss="modal"></button>
            <div className="modal-dialog__content">
              <div className="corner-1">
                <div className="range">
                  <div className="cell-lg-12 text-center ">
                    {this.props.icon ? <i className={this.props.icon} ></i> : ''}
                    <h4><span style={styleBlack}>{this.props.text}</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="corner-2">
              <div className="text-center button-container">
                <h4>
                  <span style={styleBlack}>
                    {this.props.textresult}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

DialogModal.propTypes = {

  onClose: PropTypes.func,
  textresult: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string

}
