import React, { Component } from 'react'
import { loadstreamvideo, downloadvideo } from '../state/BBTState'
import { adminSettings } from '../state/AdminState'
import { withLogin } from '../provider/LoginProvider'
import VideoPlayer from './VideoPlayer'
import PropTypes from 'prop-types'

class BBT extends Component {
  constructor (props) {
    super(props)
    this.state = {
      adminsettings: null,
      streamfiles: null,
      videos: null,
      streamvideo: '/VU/work/Molokomuse/front/front/musicianmanager/public/media/bbt',
      video_dir: '/var/vu/molokomuse/video/bbt'
    }
  }

  componentDidMount () {
    var self = this
    // var post = loadvideo()
    // post.done(function (videos) {
    // console.log('videos')
    // console.log(videos)
    var poststream = loadstreamvideo()
    poststream.done(function (streamfiles) {
      console.log('streamfiles')
      console.log(streamfiles)
      var post1 = adminSettings()
      post1.done(function (adminsettings) {
        console.log('adminsettings')
        console.log(adminsettings)
        self.setState({
          adminsettings: adminsettings,

          video_dir: adminsettings.video_dir,
          // videos: videos,
          streamfiles: streamfiles
        })
      })
    })
    // })
  }

  downloadvideo (e) {
    downloadvideo(e.target.id)
  }

  render () {
    if (this.props.isAccess()) {
      return (
        <section className="section parallax-container" data-parallax-img="/images/wall2.png">

          <div className="parallax-content">
            <div className="section-85 section-lg-124 shell context-dark">
              <div className="range range-xs-center">
                {/* <div className="cell-lg-10">
                                    {this.state.videos ? this.state.videos.map((video, i) => <div key={i}>
                                        <a className="btn btn-primary btn-xs" id={video} onClick={this.downloadvideo.bind(this)}
                                            data-animation="fadeInUp" data-delay="0.2" data-duration="1" >{video}</a>
                                    </div>) : ""}
                                </div> */}
                <div>
                  <VideoPlayer videos={this.state.streamfiles} paginator={true} src={'/media/bbt/'}/>
                </div>

              </div>
              <hr/>

            </div>
          </div>
        </section>
      )
    } else {
      return (
        this.props.noAccessBlock()
      )
    }
  }
}
export default withLogin(BBT)

BBT.propTypes = {

  isAccess: PropTypes.func,
  noAccessBlock: PropTypes.func

}
