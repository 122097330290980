import React, { Component } from 'react'
import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'
export default class StepCreateDate extends Component {
  render () {
    const style = {
      marginLeft: '10px'
    }
    const bg = {

      color: '#191919'
    }
    return (
      <ConstructorProviderConsumer>
        {({ step, date, onChangeDate, createPlace, onChangeName, name, onChangeDesc, desc }) => (
          <div className="range">
            <div className="cell-md-12">
              <div className="range">
                <div className="cell-md-4">
                  <h6 className="post-event-time">
                                        Дата
                  </h6>
                </div>
                <div className="cell-md-7">
                  <input style={bg} onChange={onChangeDate} value={date} type="text" />
                </div>

              </div>
              <div className="range">
                <div className="cell-md-4">
                                    Название
                </div>
                <div className="cell-md-7">
                  <input style={bg} onChange={onChangeName} value={name} type="text" />
                </div>
              </div>
              <div className="range">
                <div className="cell-md-4">
                                    Описание
                </div>
                <div>
                  <textarea style={bg} onChange={onChangeDesc} value={desc} cols="35" rows="5" />
                </div>
              </div>
              <div className="range">

                <div className="cell-md-12">
                  {step === 1
                    ? <a onClick={createPlace} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">+ </a>
                    : ''
                  }

                </div>
              </div>
            </div>
          </div>
        )}
      </ConstructorProviderConsumer>
    )
  }
}
