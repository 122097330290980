import React, { Component } from 'react'

// import {Helmet} from "react-helmet";
// import $ from 'jquery';
import LoadScript from './LoadScript'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import DevTools from './devtools/DevTools'
import Main from './Main'

// import { CookiesProvider } from 'react-cookie';
import reducer from './reducer'
import Header from './common/Header'
import Footer from './common/Footer'

import LoginProvider from './provider/LoginProvider'
import { ITLanguageProvider } from './provider/language/ITLanguageProvider'
const store = createStore(reducer,
  {},
  compose(
    applyMiddleware(thunk),
    DevTools.instrument()
  ))

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <ITLanguageProvider>
          <LoginProvider >
            <div className="page text-center">

              {/* <LoadScript url="https://code.jquery.com/jquery-3.3.1.min.js"/>
                <LoadScript url="js/core.min.js" /> */}
              {/* <LoadScript url="css/style.css" /> */}
              <LoadScript url="/js/script.js" />
              {/* <LoadScript url="https://vk.com/js/api/share.js?95"/> */}

              {/*  <Helmet>
                  <script src="./js/core.min.js"></script>
                  <script src="./js/script.js"></script>
                </Helmet> */}
              <Header />

              <Main />

              <Footer />
              <DevTools />

            </div>
          </LoginProvider>
        </ITLanguageProvider>
      </Provider>
    )
  }
}

export default App
