import React, { Component } from 'react'

import Highlight from 'react-highlighter'
import PropTypes from 'prop-types'
import MusicianFormBody from './MusicianFormBody'
export default class MusicianFormAccord extends Component {
  constructor (props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  componentDidMount (prevState) {
    this.loadScript()
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps !== prevState) {
      return nextProps
    } else { return null }
  }

  loadScript () {
    if (this.props.search === '') {
      if (window.plugins && window.plugins.responsiveTabs && window.plugins.responsiveTabs.length) {
        var i = 0
        for (i = 0; i < window.plugins.responsiveTabs.length; i++) {
          var $this = window.$(window.plugins.responsiveTabs[i])
          $this.easyResponsiveTabs({
            type: $this.attr('data-type'),
            closed: true,
            tabidentify: $this.find('.resp-tabs-list').attr('data-group') || 'tab'
          })
        }
      }
    }
  }

  render () {
    return (

      <div className="range post-event-item" >

        <div className="cell-md-2 text-md-left">
          <div className="post-event-inset">
            <h4 className="post-event-time"><a href={`/mus?id=${this.props.muse.id}`}>
              <Highlight search={this.state.search}>{this.props.muse.name}</Highlight>

            </a></h4>

          </div>
        </div>
        <div className="cell-md-10 offset-top-20 offset-md-top-0">
          <div className="post-event-info">

            <div id={this.props.muse.name} className="responsive-tabs responsive-tabs-boxed" data-type="accordion">
              <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
                <li>
                  <Highlight search={this.state.search}>{this.props.muse.name}</Highlight>
                </li>
              </ul>
              <div className="resp-tabs-container tabs-group-default offset-md-top-20" data-group="tabs-group-default">
                <MusicianFormBody
                  value={this.props.muse}
                  id={this.props.muse.id}

                  search={this.state.search}
                />

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
MusicianFormAccord.propTypes = {
  search: PropTypes.any,
  muse: PropTypes.any
}
