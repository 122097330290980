import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { STATUS_CONCERT_ACTIVETAB, STATUS_CONCERT_ACTIVE, STATUS_CONCERT_ACTIVE_AWAIT_SALE } from '../../common/const/Const'

class ButtonTicketByConcert extends Component {
  constructor (props) {
    super(props)
    this.state = {

      concert: this.props.concert

    }
  }

  render () {
    return (
      <div>
        {this.props.concertlist ? this.props.concertlist.map((concert, i) =>

          <div key={i}>

            {Number(concert.id) === Number(this.props.concertid) && concert.buttonticket &&
                            (concert.status_concert === STATUS_CONCERT_ACTIVE[0] ||
                                concert.status_concert === STATUS_CONCERT_ACTIVETAB[0])
              ? <a type="button" className="btn btn-primary btn-xs"
                data-tc-event={concert.buttonticket.split('&')[0]}
                data-tc-token={concert.buttonticket.split('&')[1]}>

                                Купить билет </a> : ''}
            {Number(concert.id) === Number(this.props.concertid) &&
                            concert.status_concert === STATUS_CONCERT_ACTIVE_AWAIT_SALE[0]
              ? <a type="button" className="btn btn-primary btn-xs" >
                                Скоро в продаже </a> : ''}

            {Number(concert.id) === Number(this.props.concertid) && this.props.concert && this.props.concert.payAddingVote

              ? <a type="button" className="btn btn-primary btn-xs"
                href={'/genvote?id_concert=' + this.props.concert.id + '&gencode=y'} >
                                Купить Голос</a> : ''

            }

          </div>

        ) : ''}
      </div>

    )
  }
}

export default ButtonTicketByConcert

ButtonTicketByConcert.propTypes = {
  concert: PropTypes.any,
  concertid: PropTypes.number,
  concertlist: PropTypes.array.isRequired
}
