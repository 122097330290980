import React, { Component } from 'react'
import { withLogin } from '../../provider/LoginProvider'
import { adminSettings, setSettings } from '../../state/AdminState'
import DialogModal from '../../common/modalform/DialogModal'
import PropTypes from 'prop-types'
class AdminSettings extends Component {
  constructor (props) {
    super(props)

    this.state = {
      adminsettings: null,
      text: 'Свойства',
      textresult: 'Свойства сохранены',
      openDialog: false,
      newSet: null,
      newkeyset: '',
      newvalset: ''

    }
  }

  componentDidMount () {
    var self = this
    var post = adminSettings()
    post.done(function (adminsettings) {
      self.setState({
        adminsettings: adminsettings
      })
    })
  }

  bodySetting () {
    Object.entries(this.state.adminsettings).map(([key, value]) => {
      return (
        <div key={key}>{key}:{value}</div>
      )
    })
  }

  onSetVal (key, e) {
    var adminsettings = this.state.adminsettings
    adminsettings[key] = e.target.value
    this.setState({
      adminsettings: adminsettings
    })
  }

  onSetSettings (e) {
    var adminsettings = setSettings(this.state.adminsettings)
    this.setState({
      adminsettings: adminsettings,
      openDialog: true
    })
  }

  onAddSettings (e) {
    this.setState({

    })
  }

  onCloseDialog (e) {
    this.setState({ openDialog: false })
  }

  render () {
    if (this.state.adminsettings === null) return ''
    const adminsettingskeys = Object.keys(this.state.adminsettings)
    const style = {
      color: 'black',
      width: '100%'
    }
    if (this.props.isAccess()) {
      return (
        <section className="section parallax-container" data-parallax-img="images/city-1920-1276.png">
          <div className="parallax-content">

            <div className="section-85 section-lg-124 shell context-dark">
              {this.props.menuPanel()}
              <div className="range range-xs-center">
                <div className="cell-lg-12">
                  <h5>Настройки серверных переменных</h5>
                  <div className="offset-top-50 offset-md-top-90 event">
                    <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                      <div className="tab-pane fade active in post-event-type-3" id="tab1" role="tabpanel">

                        <main className="page-content">

                          {adminsettingskeys.map((k) =>
                            <div className="range" key={k}>
                              <div className="cell-md-4">
                                {k}
                                {Array.isArray(this.state.adminsettings[k]) ? ' -> это массив, разделитель запятая' : ''}
                              </div>
                              <div className="cell-md-8">
                                {this.state.adminsettings[k].length > 100
                                  ? <textarea style={style} value={this.state.adminsettings[k]}
                                    onChange={this.onSetVal.bind(this, k)}></textarea>
                                  : <input type="text" style={style} value={this.state.adminsettings[k]}
                                    onChange={this.onSetVal.bind(this, k)} />
                                }
                              </div>
                            </div>
                          )}

                        </main>

                      </div>
                      <div className="range">
                        {/* <div className="cell-md-4">
                                            <a onClick={this.onAddSettings.bind(this)}
                                            className="btn btn-primary animateItem btn-xs"
                                               data-animation="fadeInUp" data-delay="0.2" data-duration="1">Добавить</a>

                                        </div> */}
                        <div className="cell-md-4">
                          <a onClick={this.onSetSettings.bind(this)} className="btn btn-info animateItem btn-xs"
                            data-animation="fadeInUp" data-delay="0.2" data-duration="1">Сохранить</a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <DialogModal text={''}
            textresult={this.state.textresult}
            openDialog={this.state.openDialog}
            onClose={this.onCloseDialog.bind(this)}
            icon="icon text-middle fa fa-cog fa-spin icon-xxs"
          />
        </section>
      )
    } else {
      return (
        this.props.noAccessBlock()
      )
    }
  }
}

export default withLogin(AdminSettings)

AdminSettings.propTypes = {
  noAccessBlock: PropTypes.func,
  isAccess: PropTypes.func,
  menuPanel: PropTypes.any
}
