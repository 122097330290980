import React, { Component } from 'react'
import AudioPlayer from 'react-modular-audio-player'
import PropTypes from 'prop-types'
const rearrangedPlayer = [
  {
    className: 'top',
    style: { marginBottom: '0.2rem' },
    innerComponents: [
      {
        type: 'name',
        style: { width: '70%' }
      },
      {
        type: 'time',
        style: { justifyContent: 'flex-end' }
      }
    ]
  },
  {
    className: 'bottom',
    innerComponents: [
      {
        type: 'play',
        style: { width: '10%', justifyContent: 'flex-end' }
      },
      /* {
        type: "rewind",
        style: { width: "12.5%", justifyContent: "flex-end" }
      },
      {
        type: "forward",
        style: { width: "12.5%" }
      },
      {
        type: "loop",
        style: { width: "12.5%" }
      }, */
      {
        type: 'volume',
        style: { width: '70%', padding: '5%' }
      }
    ]
  }, {
    className: 'tier-bottom',
    style: { margin: '0rem 0.3rem 0.3rem 0.3rem' },
    innerComponents: [
      {
        type: 'time',
        style: { width: 'fit-content' }
      },
      {
        type: 'seek'
      }
    ]
  }
]
export default class Player2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      playlist:
        [
          [{
            title: 'Song for the Peace',
            artist: 'Strange Melizma',

            src: '/media/audio/melizma/Song for the Peace.mp3'

          }],
          [{
            title: 'Craft Lamp',
            artist: 'Strange Melizma',

            src: '/media/audio/melizma/Craft Lamp.mp3'

          }],
          [{
            title: 'Underground Mess',
            artist: 'Strange Melizma',

            src: '/media/audio/melizma/Underground Mess.mp3'

          }]
        ]

    }
  }

  render () {
    return (
      <div>
        {this.props.playList.map((val, i) =>
          <div className="range" key={i}>
            <AudioPlayer

              audioFiles={val}
              rearrange={rearrangedPlayer}
              iconSize="1.5rem"
              fontSize="1.5rem"
              playerWidth="15rem"
              sliderClass="invert-blue-grey"
            />

          </div>
        )}
      </div>

    )
  }
}
Player2.propTypes = {

  playList: PropTypes.any
}
