import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputTextButtonSave from '../controls/InputTextButtonSave'
class SetInputField extends Component {
  render () {
    return (
      <InputTextButtonSave
        label={this.props.label}
        value={this.props.value}
        idmusicians={this.props.id}
        field={this.props.field}
      />
    )
  }
}

export default SetInputField
SetInputField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number,
  value: PropTypes.any,
  field: PropTypes.any
}
