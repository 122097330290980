import * as types from '../constants/AdminTypes'

import { initAdminSettings, adminSettings } from '../state/AdminState'
// import update from 'react-addons-update';

export default function AdminReducer (state = initAdminSettings, action) {
  switch (action.type) {
    case types.ADMIN_SETTING_LOAD:
      return {
        ...state,
        adminsettings: adminSettings()
      }
    default:

      return state
  }
}
