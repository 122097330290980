import React, { Component } from 'react'
import { withLogin } from '../../provider/LoginProvider'
import { getallroles } from '../../state/RoleState'
import RoleForm from '../../admin/forms/RoleForm'
import PropTypes from 'prop-types'

class Roles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      roles: getallroles(),
      role_name: ''
    }
  }

    onRoleName = e => {
      this.setState({
        role_name: e.target.value
      })
    }

    newRole (e) {

    }

    render () {
      return (
        <main className="page-content">
          {this.props.user != null && this.props.user.role === 'admin'
            ? <section className="section parallax-container" data-parallax-img="images/city-1920-1276.png">
              <div className="parallax-content">
                <div className="section-85 section-lg-124 shell context-dark">
                  {this.props.menuPanel()}
                  <div className="range range-xs-center">

                    <div className="cell-lg-12">
                      <div className="offset-top-50 offset-md-top-90 event">
                        <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                          <div className="tab-pane fade active in post-event-type-3" id="tab1" role="tabpanel">
                            {this.state.roles
                              ? this.state.roles
                                .map((role, i) =>
                                  <RoleForm
                                    key={role.id}
                                    role={role}
                                  />
                                ) : ''
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="range range-xs-center">
                    <input type="text" value={this.state.role_name} onClick={this.onRoleName.bind(this)} />
                    <div className="cell-lg-12">
                      <a className="btn btn-primary btn-xs" href="#" onClick={this.newRole.bind(this)} >Добавить Роль</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            : this.props.noAccessBlock()
          }</main>
      )
    }
}

export default withLogin(Roles)
Roles.propTypes = {
  menuPanel: PropTypes.any,
  noAccessBlock: PropTypes.any,
  user: PropTypes.any

}
