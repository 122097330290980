import React, { Component } from 'react'

export default class About extends Component {
  render () {
    return (
      <section className="section-85 section-lg-124">
        <div className="shell">
          <h1 className="text-bold"> ЧТО ТАКОЕ МОЛОКОMUSE?</h1>
          <div className="range offset-top-50 offset-md-top-90 text-md-left">
            <div className="cell-sm-4"><img className="img-responsive reveal-inline-block" src="/images/icon-01.png" alt="Независимые музыканты" />
              <div className="offset-top-36">
                <p className="text-extra-small">Музыкальный баттл</p>
                <h4 className="offset-top-0">Независимые музыканты</h4>
                <p className="offset-top-20 small">
                  На каждом концерте 4 группы исполняют свои лучшие композиции.
                  Музыканты сами выбирают репертуар.
                  У каждой группы есть только 30 минут, чтобы покорить сердца гостей.
                  Победитель баттла определяется в конце вечера путем зрительского голосования.

                </p>
              </div>
            </div>
            <div className="cell-sm-4 offset-top-50 offset-sm-top-0"><img className="img-responsive reveal-inline-block" src="/images/icon-02.png" alt="Твой Выбор" />
              <div className="offset-top-36">
                <p className="text-extra-small">Живые концерты</p>
                <h4 className="offset-top-0">Ты - выбираешь</h4>
                <p className="offset-top-20 small">
                  Покупая билет, ты получаешь 3 голоса, которые можешь распределить между группами на свой вкус во время голосования.
                  На концерте также можно приобрести дополнительные голоса в поддержку музыкантов.

                </p>
              </div>
            </div>
            <div className="cell-sm-4 offset-top-50 offset-sm-top-0"><img className="img-responsive reveal-inline-block" src="/images/icon-03.png" alt="Голоса" />
              <div className="offset-top-36">
                <p className="text-extra-small">Музыканты и ты </p>
                <h4 className="offset-top-0">Ценные голоса</h4>
                <p className="offset-top-20 small">
                  Все голоса, отданные гостями за группы - это деньги, которые передаются музыкантам в конце вечера.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}
