import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMuseField } from '../../actions/MuseActions'
import PropTypes from 'prop-types'

class InputTextButtonSave extends Component {
  constructor (props) {
    super(props)

    this.state = {
      inputValue: this.props.value
    }
  }

  onChangeVal (event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  onSet () {
    this.props.setMuseField(this.props.field, this.state.inputValue, this.props.idmusicians)
  }

  render () {
    const style = {
      marginLeft: '10px'
    }
    return (
      <div className="range">
        <div className="cell-md-2 cell-sm-2">
          <span className="text-primary text-uppercase small">
            {this.props.label}
          </span>
        </div>
        <div className="cell-md-8 cell-sm-8">
          <input onChange={this.onChangeVal.bind(this)} value={this.state.inputValue} id="number-concert" type="text" name="number-concert" />
        </div>
        <div className="cell-md-2 cell-sm-2">
          <a onClick={this.onSet.bind(this)} className="btn btn-info animateItem btn-xs"
            style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">Сохранить</a>
        </div>
      </div>

    )
  }
}
function mapStateToProps (state) {
  return {

    musicianstate: state.MusicianReducer.musicianstate
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setMuseField (field, value, id) {
      return dispatch(setMuseField(field, value, id))
    }

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InputTextButtonSave)

InputTextButtonSave.propTypes = {
  value: PropTypes.string,
  setMuseField: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.any,
  idmusicians: PropTypes.number
}
