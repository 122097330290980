import React, { Component } from 'react'
import { withRouter } from 'react-router'
import queryString from 'query-string'
import { getVotingByBarCode, arrayBufferToBase64, getMusicianByConcert, vote } from '../state/VotingState'
import { connect } from 'react-redux'
import { VOTE_ALGORITM_SIMPLE, VOTE_ALGORITM_2_GROUP } from '../common/const/Const'
import YndexMoneyPanel from '../yandex/YndexMoneyPanel'
import DialogModal from '../common/modalform/DialogModal'
import VoteControl from '../controls/VoteControl'
import { TYPE_STREAM, TYPE_TICKET, TYPE_ADD_VOTE } from '../constants/VoteTypes'
import PropTypes from 'prop-types'
/**
 * два варианта как компонент с params и по ссылке с query String
 */

class Voting2 extends Component {
  constructor (props) {
    super(props)
    // var t = true;
    const c = queryString.parse(this.props.location.search).code
      ? queryString.parse(this.props.location.search).code.split(':')[0] : this.props.code
    var tp = 'ticket'

    if (queryString.parse(this.props.location.search).code && queryString.parse(this.props.location.search).code.split(':')[1]) {
      tp = queryString.parse(this.props.location.search).code.split(':')[1] ? queryString.parse(this.props.location.search).code.split(':')[1]
        : this.props.type
    } else {
      tp = queryString.parse(this.props.location.search).type ? queryString.parse(this.props.location.search).type
        : this.props.type
    }
    var share = false
    if (queryString.parse(this.props.location.search).share) { share = queryString.parse(this.props.location.search).share }
    this.state = {
      code: c,
      type: tp,
      vote: null,
      muse: null,
      number_of_votes: 0,
      countVote: 0,
      selectedOption: [{ value: 0, label: 0 }, { value: 0, label: 0 }, { value: 0, label: 0 }, { value: 0, label: 0 }],
      text: '',
      url: 'http://molokomuse.ru/voting?code=' + c + '&type=' + tp + '&share=true',
      share: share,
      openDialog: false,
      algoritm: VOTE_ALGORITM_SIMPLE[0],
      successURL: this.props.successURL ? this.props.successURL : 'http://molokomuse.ru/voting'
    }
  }

  vote (e) {
    var result = vote(this.state.code,
      this.state.selectedOption)
    if (result) {
      this.setState({
        text: 'Вы проголосовали. Ура!!!',
        textresult: 'Отданные голоса: ' + this.state.countVote + ' из ' + this.state.vote.number_of_votes,
        openDialog: true
      })
      // window.$("#dialogmodal").addClass("in")
      // window.$("#dialogmodal").attr("style","display:block")
    }
  }

  initVote (vote) {
    if (vote != null) {
      // console.log(vote)
      var concert = getMusicianByConcert(vote.id_concert)
      var muse = JSON.parse(concert.info).musicianConcertList
      var matrix = JSON.parse(vote.matrix_of_votes)
      // eslint-disable-next-line no-array-constructor
      var so = new Array()
      // if(this.state.selectedOption.length > muse.length){
      for (var j = 0; j < muse.length; j++) {
        so.push({ value: 0, label: 0 })
      }
      // }
      var date = JSON.parse(concert.info).date
      if (matrix.length > 0) {
        so = matrix
      } else {
        for (var i = 0; i < so.length; i++) {
          var s = so[i]
          s.museid = muse[i].id
          s.musename = muse[i].name
        }
      }
      // console.log(muse)

      this.setState({
        vote: vote,
        muse: muse,
        number_of_votes: vote.number_of_votes,
        selectedOption: so,
        matrix: matrix,
        countVote: this.getCount(so),
        openDialog: false,
        date: date,
        algoritm: JSON.parse(concert.info).algoritm ? JSON.parse(concert.info).algoritm : VOTE_ALGORITM_SIMPLE[0]
      })
    }
  }

  onCloseDialog () {
    this.setState({

      openDialog: false
    })
  }

  componentWillMount () {

  }

  componentDidMount () {
    var self = this
    if (!this.state.code) return
    var post = getVotingByBarCode(this.state.code, this.state.type)

    this.t = setInterval(() => {
      post = getVotingByBarCode(this.state.code, this.state.type)
      post.done(function (p) {
        self.setState((prevState) => ({
          vote: p
        }))
      })
    }, 5000)

    post.done(function (p) {
      self.initVote(p)
    })

    /*  var d = document.getElementById("scriptvk")

         d.innerHTML = window.VK.Share.button({url:this.state.url,
         title :"МОЛОКОMUSE Первый концерт. Как я проголосовал",
         image:"http://molokomuse.ru/images/crossroads1.jpg"},
         {type: "round_nocount", text: "Поделиться"});
          */
  }

  componentWillUnmount () {
    clearInterval(this.t)
  }

  getCount (so, num, idmus) {
    var count = 0
    if (num) { so[idmus].value = num }
    for (var i = 0; i < so.length; i++) {
      count = count + so[i].value
    }

    return count
  }

    onVoteHandler = (idmus, num, algoritm2, e) => {
      var so = this.state.selectedOption.slice(0)
      var count = this.state.countVote
      // alert(num+"=>"+idmus)
      if (count <= this.state.vote.number_of_votes) {
        so[idmus].value = num
        count = this.getCount(so, num, idmus)
        if (algoritm2) {
          this.setState({
            text: 'Необходимо проголосовать',
            textresult: 'не менее чем за две команды',
            openDialog: true
          })
          return false
        }
        if (count <= this.state.vote.number_of_votes) {
          so[idmus].value = num
          so[idmus].museid = this.state.muse[idmus].id
          so[idmus].musename = this.state.muse[idmus].name
          this.setState({
            selectedOption: so,
            countVote: count
          })
        }
      }
    }

    isVote () {
      var result = false
      var matrix = this.state.matrix
      var count = 0
      if (matrix.length === 0) return true
      for (var i = 0; i < matrix.length; i++) {
        count = count + matrix[i].value
      }
      if (count <= this.state.vote.number_of_votes) {
        result = true
      }
      return result
    }

    render () {
      var type = 'не действителен'
      if (this.state.type === TYPE_ADD_VOTE) { type = 'доп голос' } else if (this.state.type === TYPE_TICKET) { type = 'билет' } else if (this.state.type === TYPE_STREAM) { type = 'трансляция' }

      return (
        <section className="section parallax-container" data-parallax-img="/images/wall2.png">
          <div className="parallax-content">
            <div className="section-85 section-lg-124 shell context-dark">
              {this.state.vote
                ? <div>
                  <div className="range range-xs-center">
                    <div className="cell-lg-12 cell-md-12 cell-xl-12 cell-xs-12">
                      {this.state.vote.open_vote && this.isVote()
                        ? <div>
                          <h1 className="text-bold">Голосование</h1>
                          <h6 className="text-bold">{this.state.date}</h6>
                        </div>
                        : <div>
                          <h1 className="text-bold">Голосование закрыто</h1>
                          <h6 className="text-bold">{this.state.date}</h6>
                        </div>
                      }
                      <p>У вас есть {this.state.vote.number_of_votes} {this.state.vote.number_of_votes === 1 ? 'голос' : 'голоса'} ({type})
                        {this.state.algoritm === VOTE_ALGORITM_2_GROUP[0]
                          ? <span>&nbsp;Голосовать не менее, чем за две команды</span> : ''}
                      </p>

                      <h4 >Отданные голоса:&nbsp;
                        <span className="text-primary text-uppercase text-bold">{this.state.countVote}</span></h4>

                      <div className="offset-top-50 offset-md-top-90 event">
                        {this.state.muse &&
                                                this.state.muse.length > 0
                          ? this.state.muse.map((mu, key) =>
                            <div className="shell shell-wide" key={key}>
                              <div className="range range-condensed range-md-center offset-top-50 offset-md-top-90" >

                                <div className="cell-xs-12 cell-md-12 cell-xl-12">
                                  {/* <div className="post-event-info" style={bg}> */}
                                  <VoteControl
                                    selectedOption={this.state.selectedOption}
                                    number_of_votes={this.state.vote.number_of_votes}
                                    onClick={this.onVoteHandler.bind(this)}
                                    countVote={this.state.countVote}
                                    num={this.state.selectedOption &&
                                                                        this.state.selectedOption[key] &&
                                                                        this.state.selectedOption[key].value ? this.state.selectedOption[key].value : 0}
                                    idmus={key}
                                    nameMuse={mu.name}
                                    open_vote={this.state.vote.open_vote}
                                    algoritm={this.state.algoritm}
                                  />

                                </div>
                              </div>
                            </div>

                          )
                          : <div className="cell-lg-10">
                            <h1 className="text-bold">Код не действителен</h1>

                          </div>
                        }

                      </div>

                    </div>

                  </div>
                  {this.state.vote.open_vote && this.isVote() && !this.state.share
                    ? <div className="range range-xs-center">
                      <div className="cell-lg-10">
                        <a className="btn btn-primary animateItem" onClick={this.vote.bind(this)}
                          href="javascript:void(0)" data-target="#dialogmodal"
                          data-animation="fadeInUp" data-delay="0.2" data-duration="1">
                                                Проголосовать</a>

                      </div>
                    </div> : ''}

                </div>
                : this.state.vote == null ? <div className="cell-lg-10">
                  <h1 className="text-bold">Код не действителен</h1>

                </div> : ''}

              <br />
              <br />
              <hr />
              {this.state.vote && this.state.vote.open_vote && this.isVote() && !this.state.share
                ? <YndexMoneyPanel
                  date={this.state.date}
                  code={this.state.code}
                  type={this.state.type}
                  vote_price={this.state.vote.vote_price}
                  successURL={this.state.successURL}
                />
                : ''}
              <br />
              <div className="range range-xs-center range-md-center range-lg-center">
                <div id="scriptvk" >
                </div>
                {/* <div>
                                <a className="btn btn-info btn-xs animateItem" href={this.state.url} target="_blank">
                                    Поделиться в ВК
                                </a>
                             </div> */}
              </div>
              <div className="range range-xs-center">
                <div className="cell-lg-10">
                  {this.state.vote != null
                    ? <img src={'data:image/png;base64,' + arrayBufferToBase64(this.state.vote.qrcode)} />
                    : ''}
                </div>
              </div>
            </div>

          </div>

          <DialogModal text={this.state.text}
            textresult={this.state.textresult}
            openDialog={this.state.openDialog}
            onClose={this.onCloseDialog.bind(this)} />
        </section>
      )
    }
}
function mapStateToProps (state) {
  return {

    concertlist: state.ConcertReducer.concertlist

  }
}
export default connect(mapStateToProps)(withRouter(Voting2))
Voting2.propTypes = {
  location: PropTypes.any,
  successURL: PropTypes.any,
  code: PropTypes.any,
  type: PropTypes.any
}
