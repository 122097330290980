
export function gettocken (redirectUri) {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/gettocken',
    data: {
      redirect_uri: redirectUri
    }
  })
}
export function getUrlVideo (token, fileName) {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'GET',
    async: true,
    url: '/api/geturlvideo',
    data: {
      token: token,
      file_name: fileName
    }
  })
}
