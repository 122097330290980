import React, { Component } from 'react'
import { withLogin } from '../../provider/LoginProvider'
import MusicianFormAccord from '../forms/MusicianFormAccord'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { musicianById } from '../../state/MuseState'
import PropTypes from 'prop-types'
class MuseDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {

      muse: queryString.parse(this.props.location.search).id
        ? musicianById(queryString.parse(this.props.location.search).id) : null
    }
  }

  render () {
    return (
      <div>
        {this.props.user != null && this.props.user.role === 'admin'
          ? <section className="section parallax-container" data-parallax-img="images/city-1920-1276.png">
            <div className="parallax-content">
              <div className="section-85 section-lg-124 shell context-dark">
                {this.props.menuPanel()}
                <div className="range range-xs-center">
                  <div className="cell-lg-12">

                    <div className="offset-top-50 offset-md-top-90 event">
                      <div className="tab-content tab-conten-vert offset-top-50 text-md-left">
                        <div className="tab-pane fade active in post-event-type-3" id="tab1" role="tabpanel">
                          {this.state.muse != null
                            ? <MusicianFormAccord

                              muse={this.state.muse}
                            />
                            : <h1>Исполнитель не выбран</h1>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          : this.props.history.push('/')
        }
      </div>)
  }
}

export default withLogin(withRouter(MuseDetail))
MuseDetail.propTypes = {
  menuPanel: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.any,
  location: PropTypes.any
}
