export function getallroles () {
  var result = null
  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/getallroles'
  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
    })

  return result
}
