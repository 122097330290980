import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { withLanguage } from '../provider/language/ITLanguageProvider'
import { withLogin } from '../provider/LoginProvider'
import TicketModal from './modalform/TicketModal'
import { ITLanguageConsumer } from '../provider/language/ITLanguageProvider'
// import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
// import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from '@material-ui/core/FormControl'
// import InputLabel from '@material-ui/core/InputLabel'
// import ReactFlagsSelect from 'react-flags-select'
// import Button from '@material-ui/core/Button'
// import Menu from '@material-ui/core/Menu'
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
// import Fade from '@material-ui/core/Fade'
class Header extends Component {
  logout () {
    if (this.props.user != null) {
      return (
        <li><a className="icon fa-sign-out" href="#" onClick={this.props.onLogout} title="Выйти"></a></li>

      )
    } else return ''
  }

  profile () {
    if (this.props.user != null) {
      return (
        <li><a className="icon fa-user" href="/profile" title="Данные пользователя">&nbsp;Роль: {this.props.user.role}</a></li>

      )
    } else return ''
  }

  register () {
    if (this.props.user === null) {
      return (
        <li><a className="icon fa-user-plus" href="/register" title="Зарегистрироваться"></a></li>
      )
    } else return ''
  }

  admin () {
    if (this.props.user != null && this.props.user.role === 'admin') {
      return (
        <li><a className="icon fa-cog" href="/admin" title="админ панель"></a></li>
      )
    } else return ''
  }

  login () {
    if (this.props.user === null) {
      return (
        <li><a className="icon fa-sign-in" href="/login" title="Войти"></a></li>
      )
    } else return ''
  }

  componentDidMount () {
    window.$('#languagepanel').on('change', function (e) {
      // var data = e.params.data
      console.log(e)
    })
  }

  render () {
    return (
      <header className="page-head slider-menu-position">
        {/* <!-- RD Navbar Transparent--> */}
        <div className="rd-navbar-wrap">
          <nav className="rd-navbar rd-navbar-default" data-sm-layout="rd-navbar-static" data-sm-device-layout="rd-navbar-static" data-sm-auto-height="true" data-sm-stick-up="true">
            <div className="rd-navbar-inner">
              {/* <!-- RD Navbar Panel--> */}
              <div className="rd-navbar-panel">
                {/* <!-- RD Navbar Toggle--> */}
                <div className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar, .rd-navbar-list"><span></span></div>
                {/* <!-- Navbar Brand--> */}
                <div className="rd-navbar-brand">
                  <a className="rd-navbar-brand-static" href='/'>
                    <img width="180" height="52" src="/images/logo-dark-187x32.png" alt="" />
                  </a>
                  <a className="rd-navbar-brand-fixed" href="/">
                    <img width="180" height="52" src="/images/logo-dark-187x32.png" alt=""/>
                  </a>
                </div>
                <div className="rd-navbar-list">

                  <ul className="list-inline list-inline-md">
                    {/*                       <!-- <li><a className="icon fa-facebook" href="#"></a></li>
                      <li><a className="icon fa-twitter" href="#" data-toggle="modal" data-target="#appointment"></a></li>
                       -->
 */}
                    <li><a className="icon fa-vk" href="https://vk.com/molokomuse" target="_blanck"></a></li>
                    <li><a className="icon fa-instagram" href="https://www.instagram.com/molokomuse/" target="_blanck"></a></li>
                    <li><a className="icon fa-youtube" href="https://www.youtube.com/channel/UCca4U7-RIyMPhNHug3Oe8mA" target="_blanck"></a></li>
                    {this.admin()}
                    {this.logout()}
                    {this.login()}
                    {this.register()}
                    <li>

                      <ITLanguageConsumer>
                        {({ language, updateLanguage, classes, anchorEl, handleClick }) => (
                          <h6></h6>
                          /* <React.Fragment>
                            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                              {language}
                            </Button>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorEl}
                              getContentAnchorEl={null}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                              open={Boolean(anchorEl)}

                            >
                              <MenuItem style={classes.formControl} ></MenuItem>
                              <MenuItem style={classes.formControl} onClick={updateLanguage} >ru</MenuItem>
                              <MenuItem style={classes.formControl} onClick={updateLanguage} >eng</MenuItem>
                            </Menu>
                          </React.Fragment>
                              */
                          /* <FormControl variant="outlined" style={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                            <Select
                              labelId="language-label"
                              id="language"
                              value={language}
                              onChange={updateLanguage}
                            >
                              <MenuItem value={'russian'}>rus</MenuItem>
                              <MenuItem value={'english'}>eng</MenuItem>
                            </Select>
                          </FormControl> */

                          /* <RadioGroup row aria-label="language" name="language" value={language} onChange={updateLanguage}>
                            <FormControlLabel value="russian" control={<Radio />} label="rus" />
                            <FormControlLabel value="english" control={<Radio />} label="eng" />

                          </RadioGroup> */

                          /* <div>
                            <label htmlFor="languageRu">rus</label>
                            <input type='radio' value='russian' id='languageRu' name='language' onClick={updateLanguage} />
                            <label htmlFor="languageEng">eng</label>
                            <input type='radio' value='english' id='languageEng' name='language' onClick={updateLanguage}/>
                          </div> */

                        )}
                      </ITLanguageConsumer>

                    </li>

                  </ul>

                </div>

                <div className="rd-navbar-button">

                  {/* <ButtonTicket class="btn btn-primary btn-xs" /> */}

                  {/* <ButtonRegMus class="btn btn-primary btn-xs" /> */}
                  <a className="btn btn-primary btn-xs" href="/regmusic"
                    data-animation="fadeInUp"
                    data-delay="0.2"
                    data-duration="1">Стать участником</a>
                  {/*  <a type="button" className="btn btn-primary btn-xs"
                                                          data-tc-event="5c485edfa3a2df000cc494bc"
                                                          data-tc-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNWI4NmM4NWJhOThlODkwMDBjMmNiNDIxIn0.nSwwn5cCnKB9eYFlakTvmvir8rdP_XryuMdavxXR-rE">

                                                          Купить билет </a> */}

                </div>
              </div>
            </div>
          </nav>
        </div>
        <TicketModal />
      </header>

    )
  }
}

export default withLogin(Header)

Header.propTypes = {
  user: PropTypes.any,
  onLogout: PropTypes.func

}
