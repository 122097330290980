export const initialRules = {
  rules: ''

}
// getActiveRules()
export function getActiveRules () {
  var result = false

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/getactiverule'
  })
    .done(function (data) {
      // state.concertstate.push(data)
      result = data
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {

    })

  return result
}
