import React, { Component } from 'react'

export default class OurTeam extends Component {
  render () {
    return (
      <section className="section-85 section-lg-124">
        <div className="shell">
          <h1 className="text-bold">Наша команда</h1>
          {/* <!-- <p>Learn more about people behind Event Festival</p> --> */}
          <p>&nbsp;</p>
          <div className="range offset-top-50 offset-md-top-90">
            <div className="cell-sm-6">
              <img className="img-responsive reveal-inline-block img-circle imgourteam" width="199px" height="199px"
                src="/images/VladimirUstyan465.jpg" alt="" />
              <div className="offset-top-20 offset-md-top-36">
                <div>
                  <h4>Владимир Устян</h4>
                </div>
                {/* <p className="offset-top-0 text-extra-small text-primary">Festival Director</p> */}
                <p className="offset-top-20 small">
                  Владимир - автор идеи. Считает себя музыкантом, организатор различных музыкальных фестивалей.
                  Играет на
                  нескольких музыкальных инструментах. В прошлом участник группы &ldquo;Провинциальный Шаффл&raquo;.
                  Помимо музыки, много успешно занимался различными IT-проектами.

                </p>
                {/*   <!-- <ul className="list-inline list-inline-md offset-md-top-36">
                    <li><a className="icon icon-xxs fa-facebook text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-twitter text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-google-plus text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-vk text-gray" href="#"></a></li>
                  </ul> --> */}
              </div>
            </div>
            <div className="cell-sm-6 offset-top-50 offset-sm-top-0">
              <img className="img-responsive reveal-inline-block img-circle imgourteam" width="199px" height="199px"
                src="/images/marina.jpg" alt="" />
              <div className="offset-top-20 offset-md-top-36">
                <div>
                  <h4>Марина Устян</h4>
                </div>
                {/* <p className="offset-top-0 text-extra-small text-primary">Наш амбасадор</p> */}
                <p className="offset-top-20 small">
                  Наш амбасадор.
                  Саксофон, скейт и сноуборд - её среда обитания.
                  Яркий представитель своего поколения, а молодежь у нас хорошая.
                  {/* Все время проводит на репетициях и гастролях, тренировках и соревнованиях
                  и съемках на гастролях и соревнованиях
                */}

                </p>
                <ul className="list-inline list-inline-md offset-md-top-36">
                  <li><span className="offset-top-20 small">Следуй за Мариной <a className="icon fa icon-xxs fa-hand-o-right" href="#" style={{ cursor: 'default' }}></a></span> </li>
                  <li><a className="icon icon-xxs fa-instagram text-gray" href="https://instagram.com/marina.vasilyevaaa/"></a></li>
                  {/* <li><a className="icon icon-xxs fa-twitter text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-youtube text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-vk text-gray" href="#"></a></li> */}
                </ul>
              </div>
            </div>
            {/* <div className="cell-sm-4 offset-top-50 offset-sm-top-0">
              <img className="img-responsive reveal-inline-block img-circle imgourteam" width="99px" height="99px"
                src="/images/anna.jpg" alt="" />
              <div className="offset-top-20 offset-md-top-36">
                <div>
                  <h4>Анна Васильева</h4>
                </div>
                 <p className="offset-top-0 text-extra-small text-primary">Ambassador</p>
                <p className="offset-top-20 small">
                  Ответсвенна за красоту и атмосферу.

                </p>
                <ul className="list-inline list-inline-md offset-md-top-36">
                    <li><a className="icon icon-xxs fa-facebook text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-twitter text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-youtube text-gray" href="#"></a></li>
                    <li><a className="icon icon-xxs fa-vk text-gray" href="#"></a></li>
                  </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    )
  }
}
