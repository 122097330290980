import React from 'react'
import { buildMuse } from '../state/MuseState'
import { addPointToConcert, saveConcert, getConcertTemplate, addMusicianToConcert, removeMisicianFromConcert } from '../state/ConcertState'
import { refreshConcertList } from '../actions/ConcertAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export const ConstructorProviderContext = React.createContext({
  step: 0,
  date: '',
  place: '',
  m: '',
  selectedOption: '',
  point: '',
  pointsConcert: new Map(),
  musicianConcertList: new Map(),

  onChangeDate: () => { },
  createPlace: () => { },
  onChangePlace: () => { },
  onChangeMusician: () => { },
  onChangePoint: () => { },
  createConcert: () => { },

  addMusicianToConcert: () => { },
  removeMusicianFromConcert: () => { },
  removePointFromConcert: () => { },
  addPointToConcert: () => { },

  onSave: () => { },
  onChangeName: () => { },
  name: '',
  onChangeDesc: () => { },
  desc: ''

})

// Promise Interface can ensure load the script only once.

class ConstructorProvider extends React.Component {
  constructor (props) {
    super(props)
    // eslint-disable-next-line no-array-constructor
    var m = new Array()

    var pc = getConcertTemplate()
    // eslint-disable-next-line no-array-constructor
    var pcMap = new Array()
    pc.schedule.map((sh, i) => {
      pcMap.push(sh.timeStart + '-' + sh.timeFinish + ' ' + sh.descript)
    })
    this.state = {
      step: 0,
      date: '',
      place: '',
      m: m,
      name: '',
      desc: '',

      // eslint-disable-next-line no-array-constructor
      musicianConcertList: new Array(),
      point: '',
      pointsConcert: pcMap
    }
  }

  componentDidMount () {
    var self = this
    var post = buildMuse()
    post.done(function (musicianlist) {
      var m = self.state.m
      musicianlist.map((muse, i) => {
        m.push({ value: muse.id, label: muse.name })
      })
      self.setState({
        selectedOption: {
          value: m[0].value,
          label: m[0].label
        }

      })
    })
    console.log()
  }

  createPlace = event => {
    this.setState({
      step: 2
    })
  }

  onChangeMusician = e => {
    console.log('Provider ' + e)
    this.setState({
      selectedOption: e
    })
  }

  addMusicianToConcert = e => {
    var s = 4
    if (this.state.selectedOption.value) {
      this.setState({
        musicianConcertList: addMusicianToConcert(this.state.musicianConcertList, this.state.selectedOption.value,
          this.state.selectedOption.label)
      })
      /// /
      if (this.state.pointsConcert.length > 0) {
        s = 5
      }
      this.setState({
        step: s

      })
    }
  }

  removeMusicianFromConcert = e => {
    this.setState({

      musicianConcertList: removeMisicianFromConcert(this.state.musicianConcertList, e.target.id)
    })
  }

  onChangeDate = event => {
    this.setState({

      date: event.target.value

    })
  }

  createMusicianList = event => {
    this.setState({
      step: 3
    })
  }

  onChangePlace = event => {
    this.setState({

      place: event.target.value

    })
  }

  createConcert = e => {
    this.setState({
      step: 1,
      date: ''
    })
  }

  onChangePoint = event => {
    this.setState({

      point: event.target.value

    })
  }

  onChangeName = e => {
    this.setState({

      name: e.target.value

    })
  }

  onChangeDesc = e => {
    this.setState({

      desc: e.target.value

    })
  }

  addPointToConcert = e => {
    if (this.state.point) {
      this.setState({
        step: 5,
        pointsConcert: addPointToConcert(this.state.pointsConcert, this.state.point),
        point: ''
      })
    }
  }

  removePointFromConcert = e => {
    this.setState({
      step: 5,
      pointsConcert: removeMisicianFromConcert(this.state.pointsConcert, e.target.id)
    })
  }

  onSave = e => {
    var result = saveConcert(this.state)
    if (result) {
      this.defaultState()
    }
  }

  defaultState () {
    this.setState({
      step: 0,
      date: '',
      place: '',

      name: '',
      desc: '',

      musicianConcertList: new Map(),
      point: '',
      pointsConcert: new Map()
    })
    this.props.refreshConcertList()
  }

  render () {
    var self = this
    return (
      <ConstructorProviderContext.Provider
        value={{
          step: this.state.step,
          date: this.state.date,
          place: this.state.place,
          musicianConcertList: this.state.musicianConcertList,
          pointsConcert: this.state.pointsConcert,
          musicianlist: this.state.musicianlist,
          m: this.state.m,
          selectedOption: this.state.selectedOption,
          onChangeMusician: this.onChangeMusician,
          addMusicianToConcert: this.addMusicianToConcert,
          onChangePlace: this.onChangePlace,
          onChangeDate: this.onChangeDate,
          createPlace: this.createPlace,
          createMusicianList: this.createMusicianList,
          createConcert: this.createConcert,
          removeMusicianFromConcert: this.removeMusicianFromConcert,

          point: this.state.point,
          removePointFromConcert: this.removePointFromConcert,
          addPointToConcert: this.addPointToConcert,
          onChangePoint: this.onChangePoint,
          onSave: this.onSave,
          onChangeName: this.onChangeName,
          name: this.state.name,
          onChangeDesc: this.onChangeDesc,
          desc: this.state.desc
        }}
      >
        {self.state.step >= 0 && this.props.children

        }

      </ConstructorProviderContext.Provider>
    )
  }
}
function mapStateToProps (state) {
  return {

    concertlist: state.ConcertReducer.concertlist

  }
}
function mapDispatchToProps (dispatch) {
  return {
    refreshConcertList: () => {
      return dispatch(refreshConcertList())
    }

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConstructorProvider)
export const ConstructorProviderConsumer = ConstructorProviderContext.Consumer

export function withConstructor (Component) {
  return function ConstructorComponent (props) {
    return (
      <ConstructorProviderContext.Consumer>
        {user => <Component {...props} user={user} />}
      </ConstructorProviderContext.Consumer>
    )
  }
}

ConstructorProvider.propTypes = {
  refreshConcertList: PropTypes.func,
  children: PropTypes.any
}
