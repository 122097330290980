import React from 'react'
import { login, createUser } from '../state/LoginState'
import { LOGINING_USER } from '../constants/LoginTypes'
import DialogModal from '../common/modalform/DialogModal'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
export const LoginProviderContext = React.createContext({
  email: '',
  pass: '',
  logining: false,
  onEMail: () => {

  },
  onPass: () => {

  },
  onLogin: () => {

  },
  onRegister: () => {

  },
  onLogout: () => {

  },
  isAccess: () => {

  },
  noAccessBlock: () => {

  },
  menuPanel: () => {

  },
  user: null
})

class LoginProvider extends React.Component {
  constructor (props) {
    super(props)

    this.state = {

      email: '',
      pass: '',
      logining: false,
      user: localStorage.getItem(LOGINING_USER) ? JSON.parse(localStorage.getItem(LOGINING_USER)) : null,
      text: '',
      textresult: '',
      openDialog: false

    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // eslint-disable-next-line react/prop-types
    if (nextProps.logining !== prevState.logining) {
      return { logining: prevState.logining }
    } else return null
  }

    onEMail = event => {
      this.setState({
        email: event.target.value
      })
    }

    onPass = event => {
      this.setState({
        pass: event.target.value
      })
    }

    onLogin = e => {
      if (!this.validate()) return
      var user = login(this.state.email, this.state.pass)
      if (user) {
        localStorage.setItem(LOGINING_USER, JSON.stringify(user))
        this.setState({
          user: user
        })
        if (user.role === 'admin') { this.props.history.push('/admin') } else { this.props.history.push('/') }
      } else {
        this.setState({
          text: 'Неверный логин или пароль',
          textresult: '',
          openDialog: true
        })
      }
    }

    validate () {
      if (window.$('.form-validation').text() && window.$('.form-validation').text() !== '') {
        this.setState({
          text: window.$('.form-validation').text(),
          textresult: '',
          openDialog: true
        })
        // alert(window.$(".form-validation").text())
        return false
      }
      if (this.state.email && (this.state.email === '' || !this.state.email)) {
        this.setState({
          text: 'Поле e-mail должно быть заполнено',
          textresult: '',
          openDialog: true
        })

        return false
      }
      if (this.state.pass && (this.state.pass === '' || !this.state.pass)) {
        this.setState({
          text: 'Поле Пароль должно быть заполнено',
          textresult: '',
          openDialog: true
        })
        return false
      }
      return true
    }

    onRegister = e => {
      // var user = createUser(this.state.email,this.state.pass)
      if (!this.validate()) return
      var user = createUser(this.state.email, this.state.pass, 'user')
      if (user) {
        localStorage.setItem(LOGINING_USER, JSON.stringify(user))
        this.setState({
          user: user
        })
        this.props.history.push('/')
      } else {
        this.setState({
          text: 'Ошибка регистрации',
          textresult: '',
          openDialog: true
        })
      }
    }

    onCloseDialog () {
      this.setState({

        openDialog: false
      })
    }

    onLogout = e => {
      localStorage.removeItem(LOGINING_USER)
      this.setState({
        email: '',
        logining: false,
        user: null
      })
      this.props.history.push('/')
    }

    isAccess () {
      return this.user && this.user.role === 'admin'
    }

    menuPanel () {
      if (this.isAccess()) {
        return <div className="range range-xs-center range-lg-center  range-md-center offset-top-50 offset-md-top-90">
          <div className="cell-lg-2 cell-md-2 cell-xs-2">
            <a className="btn btn-primary btn-xs" href="/adminmusicians" >Артисты</a>
          </div>
          <div className="cell-lg-2 cell-md-2 cell-xs-2">
            <a className="btn btn-primary btn-xs" href="/adminconcert" >Концерты</a>
          </div>
          <div className="cell-lg-2 cell-md-2 cell-xs-2">
            <a className="btn btn-primary btn-xs" href="/adminsettings" >Настройки</a>
          </div>
          <div className="cell-lg-2 cell-md-2 cell-xs-2">
            <a className="btn btn-primary btn-xs" href="/listvote" >Голоса</a>
          </div>
          <div className="cell-lg-2 cell-md-2 cell-xs-2">
            <a className="btn btn-primary btn-xs" href="/roles" >Роли</a>
          </div>
        </div>
      } else return ''
    }

    noAccessBlock = () => {
      return <section className="section parallax-container" data-parallax-img="/images/wall2.png">
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center range-lg-center">
              <div className="cell-lg-12">

                <h4>Нет доступа</h4>

              </div>
            </div>
          </div>
        </div>
      </section>
    }

    render () {
      return (
        <LoginProviderContext.Provider
          value={{
            email: this.state.email,
            pass: this.state.pass,
            onEMail: this.onEMail,
            onPass: this.onPass,
            onLogin: this.onLogin,
            logining: this.state.logining,
            onLogout: this.onLogout,
            user: this.state.user,
            onRegister: this.onRegister,
            isAccess: this.isAccess,
            noAccessBlock: this.noAccessBlock,
            menuPanel: this.menuPanel
          }}>
          {this.props.children}
          <DialogModal
            text={this.state.text}
            textresult={this.state.textresult}
            openDialog={this.state.openDialog}
            onClose={this.onCloseDialog.bind(this)}
          />
        </LoginProviderContext.Provider>
      )
    }
}

export default withRouter(LoginProvider)

export const LoginProviderConsumer = LoginProviderContext.Consumer

export function withLogin (Component) {
  return function LoginProvider (props) {
    return (

      <LoginProviderContext.Consumer>
        {({ onRegister, onEMail, onPass, onLogin, logining, onLogout, user, isAccess, noAccessBlock, menuPanel }) => (
          <Component onRegister={onRegister} onEMail={onEMail} onPass={onPass} onLogin={onLogin}
            logining={logining} onLogout={onLogout} user={user} isAccess={isAccess} menuPanel={menuPanel}
            noAccessBlock={noAccessBlock} />

        )}
      </LoginProviderContext.Consumer>

    )
  }
}
LoginProvider.propTypes = {
  history: PropTypes.any,
  children: PropTypes.any
}
