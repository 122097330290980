export const ADD_CONCERT_DATE = 'ADD_CONCERT_DATE'
export const SAVE_CONCERT = 'SAVE_CONCERT'
export const GET_TEMPLATE_CONCERT = 'GET_TEMPLATE_CONCERT'
export const ADD_CONCERT_PLACE = 'ADD_CONCERT_PLACE'
export const ADD_MUSICIAN_TO_CONCERT = 'ADD_MUSICIAN_TO_CONCERT'
export const CREATE_MUSICIAN_LIST = 'CREATE_MUSICIAN_LIST'
export const ADD_POINT_TO_CONCERT = 'ADD_POINT_TO_CONCERT'
export const REFRESH_CONCERT_LIST = 'REFRESH_CONCERT_LIST'
export const ACTIVE_CONCERT_LIST = 'ACTIVE_CONCERT_LIST'
export const UPDATE_CONCERT = 'UPDATE_CONCERT'
export const RELOAD = 'RELOAD'
