import React, { Component } from 'react'
import { connect } from 'react-redux'
import TicketLine from './TicketLine'
import { getActiveConcerts, getAllConcerts } from '../../state/ConcertState'
import PropTypes from 'prop-types'
import { STATUS_CONCERT_ACTIVETAB, STATUS_CONCERT_ACTIVE, STATUS_CONCERT_AWAIT, STATUS_CONCERT_ACTIVE_AWAIT_SALE } from '../../common/const/Const'

class TicketModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      concertlist: null,
      allconcertlist: null,
      conc: null
    }
  }

  componentDidMount () {
    var self = this
    var post = this.props.concertlist
    post.done(function (concertlist) {
      // eslint-disable-next-line no-array-constructor
      var conc = new Array()
      var allconcertlist = getAllConcerts(concertlist)
      if (allconcertlist) {
        allconcertlist.map((concert, i) => {
          if (concert.status_concert) {
            if (concert.status_concert === STATUS_CONCERT_AWAIT[0]) {

            } else if (concert.status_concert ===
                            STATUS_CONCERT_ACTIVETAB[0] ||
                            concert.status_concert === STATUS_CONCERT_ACTIVE[0] ||
                            concert.status_concert === STATUS_CONCERT_ACTIVE_AWAIT_SALE[0] || concert.payAddingVote) {
              conc.push(concert)
            }
          }
        })
      }
      self.setState({
        concertlist: concertlist,
        allconcertlist: getActiveConcerts(concertlist),
        conc: conc

      })
    })
  }

  render () {
    return (

      <div className="modal fade" id="modalticket" role="dialog">
        <div className="modal-dialog modal-dialog_custom">

          <div className="modal-dialog__inner">
            <button className="close" type="button" data-dismiss="modal"></button>
            <div className="modal-dialog__content">
              <div className="corner-1">
                <div className="range">
                  <div className="cell-lg-12">
                    <h4>Выбери концерт и купи билет:</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="corner-2">
              <div className="text-center button-container">
                {this.state.conc ? this.state.conc.map((concert, i) =>
                  <div className="range" key={i}>

                    <TicketLine concert={concert} />

                  </div>
                ) : 'В ожидании'}
              </div>
            </div>
          </div>
        </div>

      </div>

    )
  }
}

function mapStateToProps (state) {
  return {

    concertlist: state.ConcertReducer.concertlist

  }
}
export default connect(mapStateToProps)(TicketModal)

TicketModal.propTypes = {

  concertlist: PropTypes.any

}
