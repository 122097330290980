
export const english = {
  MAIN: {
    HEADING: '4 groups will fight in a musical battle at once'
  },
  MENU: {
    NAMESITE: 'Indie Trip',
    MATRIX: 'Matrix',
    YOUTRIP: 'You trip',
    HOWITWORKS: 'How it works'
  },
  MATRIX: {
    HEADER: 'Indie Trip Adventure Matrix',
    VIEWTRIP: 'View your travels'
  },
  BUTTON: {
    ADDPOINT: 'Add point',
    SAVE: 'Save',
    SEARCH: 'Search',
    LOAD: 'Load'
  },

  MATRIXFORM: {
    AUTHOR: 'Author',
    HISTORY: 'History trip'
  },
  FORMS: {
    SAVEROUTE: {
      SAVETRIP: 'Save created trip',
      ENTEREMAIL: 'Enter e-mail',
      ENTERNAMETRIP: 'Enter your trip name',
      MAKEITPUBLIC: 'Make it public'
    }
  },
  ERROR: {
    INCORRECTEMAILFIELD: 'Email field is incorrect',
    DELETE: 'An error occurred while deleting',
    SAVE: 'An error occurred while saving',
    AUERO: 'An error occurred while searching for an air route'
  },
  INFO: {
    FILLEMAIL: 'Поле <b>email</b> должно быть заполнено',
    MAPNOTCREATED: 'Map and travel are not created',
    FORMATEMAIL: 'Email format is not correct',
    ENTERTRIPNAME: 'Enter your trip name',
    ROUTESAVED: 'Route Saved',
    ERRORSAVEDROUTE: 'An error occurred while saving the route. Try once more.',
    PUBLICATIONROUTE: 'Your route will be published in the Travel Matrix and you can tell people about it and share your impressions.',
    SELECTPOINT: 'You must select a destination',
    FOOTER: 'Indie Trip: Territory of Freedom. Build your journey yourself',
    WAITROUTE: 'The route is being built. \n This may take some time.',
    WAITHOTEL: 'Searching hotels',
    HISTORYDELETE: 'Your travel history has been deleted.',
    TRIPSAVE: 'Your travel history has been saved.',
    AERO: 'Air traffic not found'

  },
  MOBILE: {
    OPENPANEL: 'Open control panel',
    CLOSEPANEL: 'Close the control panel'
  },
  MAIL: {
    WRITETOUS: 'Write to us'
  },
  NOTTRANSLATE: { NOTTRANSLATE: 'Not translate' }

}
