import * as types from '../constants/ActionTypes'

export function buildMuse () {
  return {
    type: types.BUILD_MUSE
  }
}
export function setMuseField (field, value, id) {
  return {
    type: types.SET_MUSE_FIELD,
    field: field,
    value: value,
    id: id
  }
}
