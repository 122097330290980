import React, { Component } from 'react'
import ImageUploading from 'react-images-uploading'
import './styles.css'
// import { uploadGif } from '../state/converter'
import axios from 'axios'
import VideoPlayer from '../bbt/VideoPlayer'
// import { ProgressBar } from 'react-step-progress-bar'
import BeatLoader from 'react-spinners/BeatLoader'
import DialogModal from '../common/modalform/DialogModal'
const sizeLoader = 15
const colorLoader = '#ff412e'
export default class Converter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      imageList: null,

      maxNumber: 69,
      progressData: 0,
      progressData1: 0,
      loaderUpload: false,
      loaderAddedTime: false,
      addedsec: 0,
      message: '',
      openDialog: false
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange = (imageList, addUpdateIndex) => {
    this.setState({
      imageList: imageList,
      gifname: imageList[0].file.name
    })
  };

  handleUploadFile =e => {
    this.setState({
      progressData: 0,
      progressData1: 0,
      converterVideoURL: undefined,
      fileName: undefined,
      loaderUpload: true
    })
    const formData = new FormData()
    formData.append('file', this.state.imageList[0].file)
    axios({
      method: 'put',
      url: '/api/uploadgif',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
      /* onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength !== null) {
          this.setState({
            progressData: Math.round((progressEvent.loaded * 100) / totalLength)
          })
        }
      },
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
        if (totalLength !== null) {
          this.setState({
            progressData: Math.round((progressEvent.loaded * 100) / totalLength)
          })
        }
      } */
    }).then(r => {
      this.setState({
        converterVideoURL: r.data.converterVideoURL,
        fileName: r.data.fileName,
        loaderUpload: false
      })
    }).catch(err => {
      console.log(err)
      this.setState({
        loaderUpload: false,
        loaderAddedTime: false

      })
    })
  }

  onSetSecond=e => {
    this.setState({
      addedsec: e.target.value
    })
  }

  handleClickAddTime=e => {
    if (this.state.addedsec === 0 /* || this.state.addedsec > 20 */) {
      this.setState({
        message: 'Количество добавленных кадров должно быть не менее 1', // и не более 20',
        openDialog: true
      })
      return false
    }
    this.setState({
      progressData: 0,
      progressData1: 0,
      fileNameAdded: undefined,
      converterVideoURLAdded: undefined,
      loaderAddedTime: true
    })
    const formData = new FormData()
    formData.append('fileName', this.state.fileName)
    formData.append('addedSec', this.state.addedsec)
    axios({
      method: 'put',
      url: '/api/addedsec',
      data: formData,
      headers: { 'Content-Type': 'application/json' }
      /* onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength !== null) {
          this.setState({
            progressData1: Math.round((progressEvent.loaded * 100) / totalLength)
          })
        }
      },
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength !== null) {
          this.setState({
            progressData1: Math.round((progressEvent.loaded * 100) / totalLength)
          })
        }
      } */
    }).then(r => {
      this.setState({
        fileNameAdded: r.data.fileNameAdded,
        converterVideoURLAdded: r.data.converterVideoURLAdded,
        loaderAddedTime: false,

        addedsec: this.state.addedsec
      })
    }).catch(err => {
      console.log(err)
      this.setState({
        loaderUpload: false,
        loaderAddedTime: false

      })
    })
  }

  setupBeforeUnloadListener = () => {
    console.log('setupBeforeUnloadListener')
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()

      return this.onImageRemoveAll(ev)
    })
  }

  componentDidMount () {
    // Activate the event listener
    this.setupBeforeUnloadListener()
  }

  onImageRemoveAll=(e) => {
    const formData = new FormData()

    formData.append('fileName', decodeURI(this.state.fileName))
    formData.append('fileNameAdded', decodeURI(this.state.fileNameAdded))
    formData.append('gifname', this.state.gifname)
    axios({
      method: 'DELETE',
      url: '/api/clear',
      data: formData,
      headers: { 'Content-Type': 'application/json' }
    }).then(r => {
      console.log(r.data)

      this.setState({
        imageList: null,
        fileNameAdded: null,
        converterVideoURL: null,
        fileName: null,
        converterVideoURLAdded: null,
        progressData: 0,
        progressData1: 0,
        gifname: undefined,
        addedsec: 0
      })
    }).catch(err => {
      console.log(err)
    })
  }

  onCloseDialog=e => {
    this.setState({
      openDialog: false,
      message: ''
    })
  }

  onKeyPress=e => {
    var keycode = (e.keyCode ? e.keyCode : e.which)

    if (keycode === 13) {
      this.handleClickAddTime(e)
    }
  }

  render () {
    return (
      <section className="section parallax-container" >
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <div className="range range-xs-center">
                <div className="cell-lg-2">
                  <ImageUploading

                    value={this.state.imageList}
                    onChange={this.onChange}
                    maxNumber={this.state.maxNumber}
                    dataURLKey="data_url"
                    acceptType={['gif']}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                    }) => (
                    // write your building UI
                      <div className="upload__image-wrapper">
                        <a className="btn btn-primary btn-xs" href="#"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                        GIF в MP4
                        </a>
                        &nbsp;

                      </div>
                    )}
                  </ImageUploading>
                </div >
                <div className="cell-lg-2">
                  <a className="btn btn-primary btn-xs" href="#"
                    onClick={this.onImageRemoveAll}>Очистить</a>
                </div>
              </div>

              {this.state.imageList && this.state.imageList.length > 0 &&
                        this.state.imageList[0].data_url && (
                <div className="range range-xs-center">
                  <div className="cell-lg-10">
                    <img src={this.state.imageList[0].data_url} alt="" width="50%" />

                  </div></div>)}

              {this.state.imageList && this.state.imageList.length > 0 &&
                        this.state.imageList[0].data_url &&
                        (<div className="range range-xs-center">
                          <div className="cell-lg-10">
                            <button className="btn btn-primary btn-xs"
                              onClick={this.handleUploadFile.bind(this)}
                              data-animation="fadeInUp"
                              data-delay="0.2"
                              data-duration="1">Конвертировать в mp4</button>

                          </div>
                        </div>
                        )}

              {this.state.loaderUpload && (<div className="range range-xs-center">
                <div className="cell-lg-10">
                  <BeatLoader

                    size={sizeLoader}
                    color={colorLoader}
                    loading={this.state.loaderUpload}
                  />
                </div>
              </div>)}
              {this.state.converterVideoURL &&
                        (
                          <div className="range range-xs-center">
                            <div className="cell-lg-10">

                              <VideoPlayer videos={undefined} paginator={false} src={'/media/giftomp4/' + this.state.fileName}/>
                            </div>
                          </div>
                        )}

              {this.state.converterVideoURL &&
                        (
                          <div className="range range-xs-center">
                            <div className="cell-lg-4">

                              <label >Удлинить видео последним кадром, секунд</label>
                            </div>
                            <div className="cell-lg-2">
                              <input className="form-control" id="added-sec"
                                type="number" name="added-sec"
                                data-constraints="@Numeric"
                                value={this.state.addedsec}
                                onKeyPress={this.onKeyPress.bind(this)}
                                onChange={this.onSetSecond} />
                            </div>
                            <div className="cell-lg-3">
                              <button className="btn btn-primary btn-xs"
                                onClick={this.handleClickAddTime.bind(this)}

                                data-animation="fadeInUp"
                                data-delay="0.2"
                                data-duration="1">Добавить</button>
                            </div>
                          </div>)}
              {this.state.loaderAddedTime && (<div className="range range-xs-center">
                <div className="cell-lg-10">
                  <BeatLoader

                    size={sizeLoader}
                    color={colorLoader}
                    loading={this.state.loaderAddedTime}
                  />
                </div>
              </div>)}
              {this.state.fileNameAdded &&
                        (
                          <div className="range range-xs-center">
                            <div className="cell-lg-10">
                              <VideoPlayer videos={undefined} paginator={false} src={'/media/giftomp4/' + this.state.fileNameAdded}/>
                            </div>
                          </div>
                        )}

              <div className="range range-xs-center">
                <div className="cell-lg-10">
                  <a className="btn btn-primary btn-xs" href="#"
                    onClick={this.onImageRemoveAll}>Очистить</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogModal text={''}
          textresult={this.state.message}
          openDialog={this.state.openDialog}
          onClose={this.onCloseDialog.bind(this)}
          icon="icon text-middle fa fa-cog fa-spin icon-xxs"
        />
      </section>

    )
  }
}
