/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { arrayBufferToBase64, onOpenVoteByCode } from '../../state/VotingState'

export default class VoteFormForList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      vote: this.props.vote,
      open_vote: this.props.vote.open_vote,

      text: 'Голосование',
      textresult: 'Закрыто',
      openDialog: false
    }
  }

  onOpenVote (event) {
    var t = onOpenVoteByCode(this.state.vote.code, event.target.checked)
    let text
    let textresult
    let openDialog
    if (!t.error) {
      text = event.target.checked ? 'Открыто голосование' : 'Закрыто голосование'
      textresult = 'http://' + document.domain + '/voting?code=' + this.state.vote.code + '&type=' + this.state.vote.type
      openDialog = true
    } else {
      text = this.state.vote.code
      textresult = t.error
      openDialog = true
    }

    this.props.onOpenDialog(text, textresult, openDialog)
    this.setState({
      open_vote: event.target.checked,
      text: text,
      textresult: textresult,
      openDialog: openDialog
    })
  }

  render () {
    // eslint-disable-next-line camelcase
    const { vote, open_vote } = this.state
    return (
      <div className="cell-md-12 offset-top-20 offset-md-top-0" >
        <div className="post-event-info">
          <div className="range" id={vote.code}>
            <div className="cell-md-5 cell-xs-12 cell-xl-5"> {vote.id}&nbsp;&nbsp;
              <a target="_blank" href={`/voting?code=${vote.code}&type=${vote.type}`} >{vote.code}</a>
              <br />
              <span>
                {vote.matrix_of_votes}
              </span>
            </div>
            <div className="cell-md-3">
              <img src={'data:imag/png;base64,' + arrayBufferToBase64(vote.qrcode)} />
            </div>
            <div className="cell-md-2">
              {vote.number_of_votes}
              <br />
              <input type="checkbox"
                // eslint-disable-next-line camelcase
                onChange={this.onOpenVote.bind(this)} checked={!!open_vote} />
            </div>
            <div className="cell-md-1">
              {vote.type}
            </div>
            <div className="cell-md-1">
              {vote.source}
            </div>
          </div>
        </div>

      </div>

    )
  }
}

VoteFormForList.propTypes = {
  vote: PropTypes.object.isRequired,
  onOpenDialog: PropTypes.func
}
