import React, { Component } from 'react'
import Main from './section/Main'

import Afisha from './section/Afisha'
import About from './section/About'
import Counters from './section/Counters'
import Rules from './section/Rules'
import OurTeam from './section/OurTeam'

export default class MainPage extends Component {
  constructor (props) {
    super(props)
    console.log(props)
  };

  render () {
    return (
      <div className="page text-center">
        <Main />
        {/* <TicketPlan/> */}
        <Afisha />
        <About />
        <Counters />
        <OurTeam />
        <Rules />
      </div>
    )
  }
}
