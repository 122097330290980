export function RegMusician (state) {
  var musician = JSON.stringify(state)
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: true,
    url: '/api/regmusician',
    data: {
      musician: musician
    }
  })
}
