import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class Countdown extends Component {
  render () {
    return (
      <div className={this.props.classCss} >
        <div className="countdown" data-type="until"
          data-time={this.props.date}
          data-format={this.props.dataformat}
          data-layout="&lt;span class=&quot;countdown-row&quot;&gt;&lt;span class=&quot;countdown-section&quot;&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{d100}&lt;/span&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{d10}&lt;/span&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{d1}&lt;/span&gt;&lt;span class=&quot;countdown-period&quot;&gt;Дней&lt;/span&gt;&lt;/span&gt;&lt;span class=&quot;countdown-section&quot;&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{h10}&lt;/span&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{h1}&lt;/span&gt;&lt;span class=&quot;countdown-period&quot;&gt;часов&lt;/span&gt;&lt;/span&gt;&lt;span class=&quot;countdown-section&quot;&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{m10}&lt;/span&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{m1}&lt;/span&gt;&lt;span class=&quot;countdown-period&quot;&gt;минут&lt;/span&gt;&lt;/span&gt;&lt;span class=&quot;countdown-section&quot;&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{s10}&lt;/span&gt;&lt;span class=&quot;countdown-digit&quot;&gt;{s1}&lt;/span&gt;&lt;span class=&quot;countdown-period&quot;&gt;секунд&lt;/span&gt;&lt;/span&gt;&lt;/span&gt;">
        </div>
      </div>
    )
  }
}
Countdown.propTypes = {

  classCss: PropTypes.any,
  date: PropTypes.any,
  dataformat: PropTypes.any
}
