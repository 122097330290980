import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NO_VIDEO } from '../../constants/StreamTypes'
// import {updateConcert} from '../../actions/ConcertAction'
import DialogModal from '../../common/modalform/DialogModal'
import StatusConcert from '../../common/component/StatusConcert'
import AlgoritmVoteConcert from '../../common/component/AlgoritmVoteConcert'
// import MusicianControl from '../controls/MusicianControl'
import { buildMuseAdm } from '../../state/MuseState'
import Select from 'react-select'
import { onDownloadTickets, updateConcert, addMusicianToConcert, removeMisicianFromConcert, getConcertById } from '../../state/ConcertState'
import { generateQR, isopenvote, onOpenVote } from '../../state/VotingState'
import { VOTE_ALGORITM_SIMPLE } from '../../common/const/Const'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux';
class ConcertList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text: 'Голосование',
      textresult: 'Закрыто',
      openDialog: false,
      videofile: encodeURIComponent('Video/готовый концерт!.mp4'),
      stream_server: 'https://cloud-api.yandex.net/v1/disk/resources/download',
      selectedOption: {
        value: '',
        label: ''
      },
      adminsettings: null
    }
  }

  componentDidMount () {
    var self = this
    var post = this.props.adminsettings
    post.done(function (adminsettings) {
      self.setState({
        adminsettings: adminsettings,
        stream_server: adminsettings.stream_server
      })
    })
  }

  init () {
    var musicianlist = buildMuseAdm()
    console.log()
    // eslint-disable-next-line no-array-constructor
    var m = new Array()
    musicianlist.map((muse, i) => {
      m.push({ value: muse.id, label: muse.name })
    }
    )
    console.log()

    var info = JSON.parse(getConcertById(this.props.concert.id).info)

    this.setState({
      text: 'Голосование',
      textresult: 'Закрыто',
      openDialog: false,
      selectedOption: {
        value: m[0].value,
        label: m[0].label
      },
      m: m,
      lastCode: '',
      inputValue: 10,
      open_vote: isopenvote(this.props.concert.id),
      date: info.date,
      desc: info.desc,
      name: info.name,
      place: info.place,
      // active:info.active,
      activetab: info.activetab,
      // archive:info.archive,
      musicianConcertList: info.musicianConcertList,
      pointsConcert: info.pointsConcert,
      buttonticket: info.buttonticket,
      number_of_votes: 3,
      price_pers: info.price_pers ? info.price_pers : 'от &#8381;700',
      price_vip: info.price_vip ? info.price_vip : '&#8381;1000',
      price_supervip: info.price_supervip ? info.price_supervip : '&#8381;1200',
      type: { value: 'ticket', label: 'Билет' },
      typefordownload: { value: 'ticket', label: 'Билет' },
      typeCollection: [{ value: 'ticket', label: 'Билет' }, { value: 'addvote', label: 'Доп голос' }],
      payAddingVote: info.payAddingVote,
      status_concert: info.status_concert,
      algoritm: info.algoritm ? info.algoritm : VOTE_ALGORITM_SIMPLE[0],
      videofile: info.videofile ? info.videofile : NO_VIDEO
    })
  }

  componentWillMount () {
    this.init()
  }

  /* onAddToArchive=event=>{
       this.setState({
        archive: event.target.checked,
        activetab: event.target.checked ? !event.target.checked : this.state.activetab,
        active: event.target.checked ? !event.target.checked : this.state.active
       })
     }
       onChangeActive=event=>{

        this.setState({

            active:event.target.checked,
            archive: event.target.checked ? !event.target.checked : this.state.archive

        })
      }  */
  /* onChangeActiveTab=event=>{
    var call = this.props.callback(event.target.checked,this.props.concert.id,event)
    if (call!=null){
      this.setState({text:"ActiveTab-ближайший концерт уже назначен:"+call,
      textresult:"Для переназначения, сначало надо его снять с ActiveTab ",
      openDialog:true})

      return;
    }

    this.setState({

        activetab:event.target.checked,

    })
  }  */
  onChangeDate=event => {
    this.setState({

      date: event.target.value

    })
  }

  onChangeDesc=event => {
    this.setState({

      desc: event.target.value

    })
  }

  onChangeName=event => {
    this.setState({

      name: event.target.value

    })
  }

  onChangePlace=event => {
    this.setState({

      place: event.target.value

    })
  }

  onChangeMusician = e => {
    console.log('Provider ' + e)
    this.setState({
      selectedOption: e
    })
  }

  onChangeType=e => {
    console.log('Provider ' + e)
    this.setState({
      type: e
    })
  }

  onChangeTypeForDownload=e => {
    this.setState({
      typefordownload: e
    })
  }

  onChangeButtonTicket=e => {
    this.setState({

      buttonticket: e.target.value

    })
  }

  addMusicianToConcert () {
    this.setState({
      musicianConcertList: addMusicianToConcert(this.state.musicianConcertList, this.state.selectedOption.value,
        this.state.selectedOption.label)
    })
  }

  removeMisicianFromConcert (id, e) {
    this.setState({
      musicianConcertList: removeMisicianFromConcert(this.state.musicianConcertList, id)
    })
  }

  onGenerateQR (e) {
    var vote = generateQR(this.props.concert.id, 'TEST', false, this.state.inputValue, this.state.number_of_votes, this.state.type.value)
    this.setState({
      lastCode: vote.code,
      textresult: 'Генерация кодов =>' + vote,
      openDialog: true
    })
  }

  onChangeCountCode (event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  onChangeOpenVote (event) {
    this.setState({
      open_vote: event.target.checked
    })
  }

  onPayAddingVote (event) {
    this.setState({
      payAddingVote: event.target.checked
    })
  }

  onChangeNumberOfVote (event) {
    this.setState({
      number_of_votes: event.target.value
    })
  }

  // eslint-disable-next-line camelcase
  onChangePrice_SuperVip (e) {
    this.setState({
      price_supervip: e.target.value
    })
  }

  // eslint-disable-next-line camelcase
  onChangePrice_Vip (e) {
    this.setState({
      price_vip: e.target.value
    })
  }

  // eslint-disable-next-line camelcase
  onChangePrice_Pers (e) {
    this.setState({
      price_pers: e.target.value
    })
  }

  onOpenVote (event) {
    const result = onOpenVote(this.props.concert.id, this.state.open_vote)

    this.setState({

      textresult: this.state.open_vote ? 'Открыто upd=>' + result.data : 'Закрыто upd=>' + result.data,
      openDialog: true
    })
  }

  onDownloadTickets (e) {
    onDownloadTickets(this.props.concert.id, this.state.typefordownload.value)
  }

  onCloseDialog () {
    this.setState({

      openDialog: false
    })
  }

  onSendCodeToMail (e) {

  }

  onSave (e) {
    updateConcert(this.state, this.props.concert.id)
    this.init()
  }

  onSetAlgoritmVoteConcert= e => {
    this.setState({
      algoritm: e
    })
  }

  onSetStatusConcert= e => {
    // var call = this.props.callback(e.target.checked,this.props.concert.id,e)
    // if (call!=null){
    this.setState({
      status_concert: e
    })
    // }
  }

  onSetVideoFile (e) {
    this.setState({
      videofile: e.target.value
    })
  }

  render () {
    var style = {
      width: '100%'
    }

    const bg = {

      color: '#191919'
    }

    return (
      <div className="cell-md-9 offset-top-20 offset-md-top-0">
        <div className="post-event-info">

          <div className="responsive-tabs responsive-tabs-boxed resp-easy-accordion tabs-group-default" data-type="accordion" style={style}>
            <ul className="resp-tabs-list tabs-group-default" data-group="tabs-group-default">
              <li className="resp-tab-item tabs-group-default resp-tab-active" aria-controls="tabs-group-default_tab_item-0" role="tab">
                {this.state.date}
              </li>

            </ul>
            <div className="resp-tabs-container tabs-group-default" data-group="tabs-group-default">

              <div className="small">
                <div className="range offset-top-20">
                  <div className="cell-md-2">
                                Дата
                  </div>
                  <div className="cell-md-10">
                    <input onChange={this.onChangeDate.bind(this)} value={this.state.date} type="text" style={style} />
                  </div>
                </div>
                <div className="range">
                  <div className="cell-md-2">
                                Название
                  </div>
                  <div className="cell-md-10">
                    <input onChange={this.onChangeName.bind(this)} value={this.state.name} style={style} />
                  </div>
                </div>
                <div className="range">
                  <div className="cell-md-2">
                                Описание
                  </div>
                  <div className="cell-md-10">
                    <textarea onChange={this.onChangeDesc.bind(this)} value={this.state.desc} style={style} />
                  </div>
                </div>
                <div className="range">
                  <div className="cell-md-2">
                                Место
                  </div>
                  <div className="cell-md-10">
                    <input onChange={this.onChangePlace.bind(this)} value={this.state.place} style={style} />
                  </div>
                </div>
                <div className="range">
                  <div className="cell-md-2">
                                Кнопка
                  </div>
                  <div className="cell-md-10">
                    <input onChange={this.onChangeButtonTicket.bind(this)} value={this.state.buttonticket} style={style} />
                  </div>
                </div>
                <div className="range">
                  <div className="cell-md-2">
                                Участники
                  </div>
                  <div className="cell-md-10">

                    <div className="range">
                      <div className="cell-md-8">
                        <ul className="list-inline list-inline-md">
                          {this.state.musicianConcertList &&
                                    this.state.musicianConcertList.length > 0
                            ? this.state.musicianConcertList.map((mu, key) =>

                              <li key={key} >
                                <div className="modal-dialog__content">
                                  <div className="modal-dialog__inner">
                                    <button className="close" type="button" onClick={this.removeMisicianFromConcert.bind(this, key)}>
                                      <span aria-hidden="true">&times;</span></button>
                                    <span>{mu.name}</span>
                                  </div>
                                </div>
                              </li>
                            ) : ''}

                        </ul>
                      </div>
                      <div className="cell-md-4">
                        <div style={bg}>
                          <Select
                            value={this.state.selectedOption}
                            onChange={this.onChangeMusician}
                            options={this.state.m}
                          />

                          <a onClick={this.addMusicianToConcert.bind(this)} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">+ участник</a>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="range">
                    <StatusConcert
                      id_concert={this.props.concert.id}
                      status_concert={this.state.status_concert}
                      onSetStatusConcert={this.onSetStatusConcert}
                    />

                  </div>
                  <div className="range">
                    <AlgoritmVoteConcert
                      id_concert={this.props.concert.id}
                      algoritm={this.state.algoritm}
                      onSetAlgoritmVoteConcert={this.onSetAlgoritmVoteConcert}
                    />

                  </div>
                  <div className="range">
                    {/* <div className="cell-md-3">
                                Активный Таб - он же ближайший концерт
                              </div>
                              <div className="cell-md-2">
                              <input  onChange={this.onChangeActiveTab.bind(this)} checked={!!this.state.activetab}
                              style={style} type="checkbox"  />
                              </div> */}
                    <div className="cell-md-12">
                              Цена (символ рубля {'&#8381;'}):
                              Персональный:<input onChange={this.onChangePrice_Pers.bind(this)} value={this.state.price_pers} style={style} />
                              VIP:<input onChange={this.onChangePrice_Vip.bind(this)} value={this.state.price_vip} style={style} />
                              SuperVip:<input onChange={this.onChangePrice_SuperVip.bind(this)} value={this.state.price_supervip} style={style} />

                    </div>
                  </div>
                  <div className="range">
                    <div className="cell-md-3">
                                Возможность купить голоса
                    </div>
                    <div className="cell-md-2">
                      <input onChange={this.onPayAddingVote.bind(this)} checked={!!this.state.payAddingVote}
                        style={style} type="checkbox" />
                    </div>
                  </div>
                  <div className="range">
                    <div className="cell-md-3">
                                Трансляция
                    </div>
                    <div className="cell-md-7">
                                Файл:<input type="text" onChange={this.onSetVideoFile.bind(this)} value={this.state.videofile} style={style}/>
                    </div>
                    <div className="cell-md-2">
                      <a className="btn btn-info animateItem btn-xs"
                        href={`/stream?stream_server=${this.state.stream_server}&videofile=${this.state.videofile}&id_concert=${this.props.concert.id}`}>Стриминг</a>
                    </div>
                  </div>
                  <div className="range">

                    <div className="cell-md-12">
                      <a onClick={this.onSave.bind(this)} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">сохранить</a>

                    </div>

                  </div>
                  <div className="range">
                    <div>
                      {this.state.lastCode}
                    </div>

                  </div>
                  <hr className="hr-gradient"/>
                  <div className="shell">
                    <div className="range post-event-item">
                      <span className="text-bold">
                                        Генерируем коды
                      </span>
                    </div>
                    <div className="range post-event-item">
                      <div className="cell-md-6">
                                    Количество кодов:<input type="text" onChange={this.onChangeCountCode.bind(this)} value={this.state.inputValue}/>
                      </div>
                      <div className="cell-md-6">
                                    голосов на код:<input type="text" onChange={this.onChangeNumberOfVote.bind(this)} value={this.state.number_of_votes}/>
                      </div>
                    </div>

                    <div className="range post-event-item">
                      <div className="cell-md-1">Тип</div>
                      <div className="cell-md-6">

                        <Select
                          value={this.state.type}
                          onChange={this.onChangeType}
                          options={this.state.typeCollection}
                        />
                      </div>
                      <div className="cell-md-5">
                        <a onClick={this.onGenerateQR.bind(this)}
                          className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp"
                          data-delay="0.2" data-duration="1">Генерировать коды</a>

                      </div>
                    </div>
                  </div>
                  <hr className="hr-gradient"/>
                  <div className="range">
                    <div className="cell-md-4">
                                        Голосование

                    </div>
                    <div className="cell-md-1">
                      <input onChange={this.onChangeOpenVote.bind(this)} checked={!!this.state.open_vote}
                        style={style} type="checkbox" />
                    </div>
                    <div className="cell-md-6">
                      <a onClick={this.onOpenVote.bind(this)} className="btn btn-info animateItem btn-xs" style={style}
                        data-animation="fadeInUp" data-delay="0.2" data-duration="1">Открыть/Закрыть голосование</a>
                    </div>
                  </div>
                  <hr className="hr-gradient"/>
                  <div className="range">
                    <div className="cell-md-4">
                                          Рассылка кодов

                    </div>
                    <div className="cell-md-4">
                      <a onClick={this.onSendCodeToMail.bind(this)} className="btn btn-info animateItem btn-xs" style={style}
                        data-animation="fadeInUp" data-delay="0.2" data-duration="1">Отправить</a>
                    </div>
                  </div>
                  <hr className="hr-gradient"/>
                  <div className="range">
                    <div className="cell-md-4">
                                          Скачать билеты. Тип:

                    </div>
                    <div className="cell-md-4">
                      <Select
                        value={this.state.typefordownload}
                        onChange={this.onChangeTypeForDownload}
                        options={this.state.typeCollection}
                      />
                    </div>
                    <div className="cell-md-4">
                      <a onClick={this.onDownloadTickets.bind(this)} className="btn btn-info animateItem btn-xs" style={style}
                        data-animation="fadeInUp" data-delay="0.2" data-duration="1">Скачать</a>
                    </div>
                  </div>
                  <hr className="hr-gradient"/>
                  <div className="range">
                    <div className="cell-md-12">
                      <a className="btn btn-info animateItem" href={`/dashboard?id_concert=${this.props.concert.id}`} >Dashboard: Ход голосования</a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogModal text={this.state.text}
            textresult={this.state.textresult}
            openDialog={this.state.openDialog}
            onClose={this.onCloseDialog.bind(this)}/>
        </div>

      </div>
    )
  }
}
function mapStateToProps (state) {
  return {

    adminsettings: state.AdminReducer.adminsettings
  }
}

export default connect(mapStateToProps)(ConcertList)
ConcertList.propTypes = {
  concert: PropTypes.any,
  adminsettings: PropTypes.any
}
/* function mapStateToProps (state) {
  return {

      concertlist:state.ConcertReducer.concertlist,
      concertconstructor:state.ConcertReducer.concertconstructor,

      stepsCreate:state.ConcertReducer.stepsCreate
  }
}

 function mapDispatchToProps  (dispatch)  {
  return {
    updateConcert:(state,id)=>{

          return dispatch(updateConcert(state,id));
        }

   }
}

  export default connect(mapStateToProps,mapDispatchToProps)(ConcertList)

 */
