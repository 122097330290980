import React, { Component } from 'react'
import { STATUS_CONCERT_ACTIVETAB, STATUS_CONCERT_ACTIVE, STATUS_CONCERT_ACTIVE_AWAIT_SALE } from '../../common/const/Const'
import PropTypes from 'prop-types'

export default class TicketLine extends Component {
  constructor (props) {
    super(props)
    var t = this.props.concert.date
    /*  if (this.props.concert.archive)
             t = null;
         if (this.props.concert && this.props.concert.payAddingVote)
             t = this.props.concert.date */

    this.state = {
      title: t
    }
  }

  returnLine () {
    if (this.props.concert && this.props.concert.buttonticket &&
            (this.props.concert.status_concert === STATUS_CONCERT_ACTIVE[0] ||
                this.props.concert.status_concert === STATUS_CONCERT_ACTIVETAB[0])) {
      return (
        <a type="button" className="btn btn-primary btn-xs"
          data-tc-event={this.props.concert.buttonticket.split('&')[0]}
          data-tc-token={this.props.concert.buttonticket.split('&')[1]}>
                    Купить билет
        </a>
      )
    } else if (this.props.concert.status_concert === STATUS_CONCERT_ACTIVE_AWAIT_SALE[0]) {
      return (
        <a type="button" className="btn btn-primary btn-xs" >
                    Скоро в продаже </a>
      )
    }/* else if(this.props.concert.archive ){

            return(
                <div></div>
            )
        } */
  }

  returnPayVote () {
    if (this.props.concert && this.props.concert.payAddingVote) {
      return (
        <a type="button" className="btn btn-primary btn-xs"
          href={'/genvote?id_concert=' + this.props.concert.id + '&gencode=y'} >
                    Купить Голос</a>
      )
    }/* else{

            return(<div></div>)
        } */
  }

  render () {
    if (this.state.title != null) {
      return (
        <div className="cell-lg-12">
          <div className="range">
            <div className="cell-lg-4">
              {this.state.title}
            </div>
            <div className="cell-lg-4">
              {this.returnLine()}
            </div>
            <div className="cell-lg-4">
              {this.returnPayVote()}
            </div>
          </div>
        </div>
      )
    } else return ''
  }
}

TicketLine.propTypes = {

  concert: PropTypes.any

}
