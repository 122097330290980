export function buildMuse () {
  return window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: true,
    url: '/api/buildmuse'

  })
}
export function musicianById (museId) {
  // eslint-disable-next-line no-array-constructor
  var result = new Array()

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/musician',
    data: {
      muse_id: museId
    }

  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
      if (result.status === 0 && result.statusText.indexOf('NetworkError') !== -1) {
        alert('Нет соединения с сервером. сообщите нам hello@molokomuse.ru')
        window.stop()
      }
    })

  return result
}
export function buildMuseAdm () {
  // eslint-disable-next-line no-array-constructor
  var result = new Array()

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/buildmuse'

  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {
      result = jqXHR
      if (result.status === 0 && result.statusText.indexOf('NetworkError') !== -1) {
        alert('Нет соединения с сервером. сообщите нам hello@molokomuse.ru')
        window.stop()
      }
    })

  return result
}
export const initialMuse = {
  musestate: buildMuse()
}
