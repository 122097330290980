import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import { getConcerImg } from '../state/GalleryState'
export default class Gallery extends Component {
  constructor (props) {
    super(props)
    this.state = {
      images: null,
      titles: null
    }
  }

  componentDidMount () {
    const post = getConcerImg()
    var self = this
    post.done(function (concert) {
      var keys = Object.keys(concert)

      var images = new Array(keys.length)
      var titles = new Array(keys.length)
      for (var i = 0; i < keys.length; i++) {
        var imgs = concert[keys[i]]
        var ai = []
        images[i] = []
        titles[i] = []
        var title = null
        for (var j = 0; j < imgs.length; j++) {
          title = imgs[j].split(':')[1] ? imgs[j].split(':')[1] : null
          ai.push({
            original: '/media/photo/' + imgs[j].split(':')[0],
            thumbnail: '/media/photo/' + imgs[j].split(':')[0]
          })
        }
        titles[i].push(title)

        images[i].push(ai)
      }

      self.setState({
        images: images,
        titles: titles

      })
    })
  }

  render () {
    //
    // const yd = "https://yadi.sk/i/RIgUTVX78rLigg"
    // result={"href":"https://downloader.disk.yandex.ru/disk/f2eace503b9ed8c7a3786c4e9903f6b7e14e08af2324ca56a83e8204e3aa15f5/5d012367/baZ2XKH2rUS0_HnQL_iFUGTuhd-2Q-3GGhHHVfEci8w1pUjot2vNIHHPGaPoXkBHkr1QKdV1vPQN8xxh9t7l4Q%3D%3D?uid=17841572&filename=%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BA%D0%BE%D0%BD%D1%86%D0%B5%D1%80%D1%82%21.mp4&disposition=attachment&hash=&limit=0&content_type=video%2Fmp4&fsize=28789444742&hid=ffe2204047aac8a8ffbf210e35aff67b&media_type=video&tknv=v2&etag=e5f3cd079415232234f7350e2a3f8575","method":"GET","templated":false}

    // const videosrc = 'https://downloader.disk.yandex.ru/disk/f2eace503b9ed8c7a3786c4e9903f6b7e14e08af2324ca56a83e8204e3aa15f5/5d012367/baZ2XKH2rUS0_HnQL_iFUGTuhd-2Q-3GGhHHVfEci8w1pUjot2vNIHHPGaPoXkBHkr1QKdV1vPQN8xxh9t7l4Q%3D%3D?uid=17841572&filename=%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BA%D0%BE%D0%BD%D1%86%D0%B5%D1%80%D1%82%21.mp4&disposition=attachment&hash=&limit=0&content_type=video%2Fmp4&fsize=28789444742&hid=ffe2204047aac8a8ffbf210e35aff67b&media_type=video&tknv=v2&etag=e5f3cd079415232234f7350e2a3f8575'
    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">
        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">

              <div className="cell-lg-12">
                <div className="modal-dialog__content-inner">
                  <div className="embed-responsive embed-responsive-16by9" style={{ height: 'auto' }}>
                    <iframe className="embed-responsive-item" id="video"
                      width="560" height="100%"
                      src="https://www.youtube.com/embed/3m2tuCSy2sI?loop=1&playlist=3m2tuCSy2sI"
                      frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <hr /><br />
                <div className="modal-dialog__content-inner">
                  <div className="embed-responsive embed-responsive-16by9" style={{ height: 'auto' }}>
                    <iframe className="embed-responsive-item" id="video"
                      width="560" height="100%"
                      src="https://www.youtube.com/embed/42E5ULHPYco?loop=1&playlist=42E5ULHPYco"
                      frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <hr /><br />
                <div className="modal-dialog__content-inner">
                  <div className="embed-responsive embed-responsive-16by9" style={{ height: 'auto' }}>
                    <iframe className="embed-responsive-item" id="video"
                      width="560" height="100%"
                      src="https://www.youtube.com/embed/7qQSHRbf1Ug?loop=1&playlist=7qQSHRbf1Ug"
                      frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen
                    ></iframe>
                  </div>
                </div>

                <div className="offset-top-50 offset-md-top-90 event">
                  {this.state.images === null ? ''
                    : this.state.images.map((img, i) =>
                      img[0] && img[0].length > 0
                        ? <div key={i}>
                          <h3>{this.state.titles[i] ? this.state.titles[i] : ''}</h3>
                          <ImageGallery items={img[0]} />
                          <hr /><br />
                        </div>
                        : ''
                    )
                  }
                </div>

              </div>
              <hr /><br />
              {/* <div className="cell-lg-12">
                                <div className="modal-dialog__content-inner">
                                    <div className="embed-responsive embed-responsive-16by9" style={{ height: "auto" }}>
                                       <YaDiscPlayer
                                        title="Полная видео версия первого концерта"
                                        file_name={"https://cloud-api.yandex.net/v1/disk/resources/download?path="+encodeURIComponent("Video/готовый концерт!.mp4")}/>

                                    </div>
                                </div>
                                <hr /><br />
                            </div>  */}
            </div>

          </div>
        </div>

      </section>
    )
  }
}
