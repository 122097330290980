import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import Player from '../player/Player2'
export default class NoDate extends Component {
  render () {
    var ai = []

    ai.push({
      original: '/media/photo/concert2/PHX01721.jpg',
      thumbnail: '/media/photo/concert2/PHX01721.jpg'
    })
    /* ai.push({
            original: '/media/photo/concert2/PHX01374.jpg',
            thumbnail: '/media/photo/concert2/PHX01374.jpg'
        }) */
    /*  ai.push({
            original: '/media/photo/concert2/PHX00121.jpg',
            thumbnail: '/media/photo/concert2/PHX00121.jpg'
        })
        ai.push({
            original: '/media/photo/concert2/PHX00121.jpg',
            thumbnail: '/media/photo/concert2/PHX00121.jpg'
        })
         */
    const style = {
      backgroundColor: 'white'
    }

    return (
      <section className="section parallax-container" data-parallax-img="/images/wall2.png">

        <div className="parallax-content">
          <div className="section-85 section-lg-124 shell context-dark">
            <div className="range range-xs-center">
              <div className="cell-lg-6" style={style}>
                <Player
                  playList={[
                    [{
                      title: 'Анафема',
                      artist: 'Без Даты',

                      src: '/media/audio/Без Даты/Анафема.mp3'

                    }],
                    [{
                      title: 'Что-то должен',
                      artist: 'Без Даты',

                      src: '/media/audio/Без Даты/Что-то должен.mp3'

                    }],
                    [{
                      title: 'Пьян',
                      artist: 'Без Даты',

                      src: '/media/audio/Без Даты/Пьян.mp3'

                    }]
                  ]}
                />
              </div>
              <div className="cell-lg-6">
                <ImageGallery items={ai} />

              </div>

            </div>
          </div>
        </div>
      </section>
    )
  }
}
