import * as types from '../constants/ActionTypes'

import { buildMuse, initialMuse } from '../state/MuseState'
// import update from 'react-addons-update';

export default function MuseReducer (state = initialMuse, action) {
  switch (action.type) {
    case types.BUILD_MUSE:

      return {
        ...state,
        musestate: buildMuse()

      }

    default:

      return state
  }
}
