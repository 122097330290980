import React, { Component } from 'react'
import { pagination } from '../../state/VotingState'
import PropTypes from 'prop-types'

export default class Paginator extends Component {
  constructor (props) {
    super(props)
    var paginator = pagination()
    this.state = {
      paginator: paginator[this.props.id_concert],
      page_count: this.props.row_count > 0 ? Math.round(paginator[this.props.id_concert] / this.props.row_count) : 0

    }
  }

  onLoadPage (i, e) {
    this.props.onLoadPage(i, this.props.id_concert)
  }

  render () {
    const style = {
      padding: '5px',
      dispaly: 'inline block'
    }
    const list = []
    for (var i = 0; i < this.state.page_count; i++) {
      list.push(
        <span key={i} >
          <a style={style} className="btn btn-info animateItem btn-xs" onClick={this.onLoadPage.bind(this, i)}>{i + 1}</a>
                    &nbsp;
        </span>)
    }
    return (
      <div className="range range-xs-center">
        <div className="cell-md-12">

          {list}
        </div>
      </div>
    )
  }
}

Paginator.propTypes = {
  id_concert: PropTypes.number,
  row_count: PropTypes.number,
  onLoadPage: PropTypes.func
}
