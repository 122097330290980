export const initialMusician = {
  // eslint-disable-next-line no-array-constructor
  musicianstate: new Array()
}
export function getAudioByMus (folder) {
  // eslint-disable-next-line no-array-constructor
  var audio = new Array()
  window.$.ajax({
    url: folder,
    async: false
  }).done(function (data) {
    this.audio.push(folder + data)

    return audio
  }).fail(function (jqXHR, textStatus) {

  })
  return audio
}
export function setMuseField (field, value, id) {
  // eslint-disable-next-line no-array-constructor
  var result = new Array()

  window.$.ajax({
    mode: 'cors',
    dataType: 'json',
    method: 'POST',
    async: false,
    url: '/api/setfieldmuse',
    data: {
      field: field,
      value: value,
      id: id
    }
  })
    .done(function (data) {
      result = data
      // result
      // var route = JSON.stringify(data.route)
    }).fail(function (jqXHR, textStatus) {

    })

  return result
}
