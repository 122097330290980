import * as types from '../constants/ConcertActionType'

export function addConcertDate (step) {
  return {
    type: types.ADD_CONCERT_DATE,
    step: step
  }
}
export function getConcertTemplate () {
  return { type: types.GET_TEMPLATE_CONCERT }
}
export function addConcertPlace (step, date) {
  return {
    type: types.ADD_CONCERT_PLACE,
    step: step,
    date: date
  }
}
export function createMusicianList (step, date, place) {
  return {
    type: types.CREATE_MUSICIAN_LIST,
    step: step,
    date: date,
    place: place
  }
}
export function addMusicianToConcert (step, date, place, musician) {
  return {
    type: types.ADD_MUSICIAN_TO_CONCERT,
    step: step,
    date: date,
    place: place,
    musician: musician
  }
}
export function addPointToConcert (step, date, place, musician, pointsConcert) {
  return {
    type: types.ADD_POINT_TO_CONCERT,
    step: step,
    date: date,
    place: place,
    musician: musician,
    pointsConcert: pointsConcert
  }
}
export function refreshConcertList () {
  return {
    type: types.REFRESH_CONCERT_LIST
  }
}

export function getActiveConcerts () {
  return {
    type: types.ACTIVE_CONCERT_LIST
  }
}

export function updateConcert (state, id) {
  return {
    type: types.UPDATE_CONCERT,
    state: state,
    id: id
  }
}
export function reload () {
  return {
    type: types.RELOAD
  }
}
