import React, { Component } from 'react'
import { ConstructorProviderConsumer } from '../../provider/ConstructorProvider'
export default class StepSave extends Component {
  render () {
    const style = {
      marginLeft: '10px'
    }

    return (
      <ConstructorProviderConsumer>
        {({ step, onSave }) => (
          <div className="range">
            {step > 4 ? <div>

              <div className="cell-md-12">

                <a onClick={onSave} className="btn btn-info animateItem btn-xs" style={style} data-animation="fadeInUp" data-delay="0.2" data-duration="1">сохранить</a>

              </div>
              <div className="cell-md-3">
                                &nbsp;
              </div>

            </div> : ''
            }
          </div>
        )}
      </ConstructorProviderConsumer>
    )
  }
}
